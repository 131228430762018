import classNames from 'classnames';
import { repoTypeToSpeaking } from 'common/dist/types/repository';
import { contentArrayToPath } from 'common/dist/utils/workbench/content';
import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import styles from './styles.module.scss';
import { useContent } from '../../../core/api/workbench/content';
import {
  getActiveProjectPath,
  setActiveProjectPath,
} from '../../../store/workbench/activeProject.slice';
import { JupyterContentElement } from '../../../store/workbench/state.types';
import { WORKBENCH_FILENAMES } from '../../workbench/part-right/config';
import { getSelectedDirPathQuery } from '../../workbench/utils';
import ModuleIconFallback from '../module-card/module-icon-fallback/ModuleIconFallback';

export type ProjectCardProps = JupyterContentElement & {};

function getRepoType(path: string) {
  const { data } = useContent(
    contentArrayToPath([path, WORKBENCH_FILENAMES.REPOSITORY_META])
  );
  const content = data?.content as string;
  const repoMeta = data?.content ? JSON.parse(content) : undefined;
  return repoMeta?.repoType;
}

const ProjectCard: FC<ProjectCardProps> = (props) => {
  const { name, path } = props;

  const history = useHistory();
  const dispatch = useDispatch();
  const activeProjectPath = useSelector(getActiveProjectPath);
  const isActive = activeProjectPath === path;
  const displayName = name.replace(/\.asr$/, '');
  const repoType = getRepoType(path);

  return (
    <div
      className={classNames(styles.projectCard, {
        [styles.isActive]: isActive,
      })}
      onClick={() => {
        dispatch(setActiveProjectPath(isActive ? '' : path));
        history.replace(
          `${history.location.pathname}${getSelectedDirPathQuery([
            isActive ? '' : path,
          ])}`
        );
      }}
      title={isActive ? 'Close Project' : 'Open Project'}
    >
      <div className={styles.body}>
        <div className={styles.iconContainer}>
          <ModuleIconFallback name={displayName} />
        </div>
        <div className={styles.textContainer}>
          <span className={styles.title} title={name}>
            {displayName}
          </span>
          <span className={styles.description}>
            {repoTypeToSpeaking[repoType]}
          </span>
        </div>
      </div>

      <div className={styles.footer}>
        {isActive && <span className={styles.active}>Active</span>}
      </div>
    </div>
  );
};

export default ProjectCard;
