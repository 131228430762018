import classNames from 'classnames';
import React, { FC } from 'react';

import styles from './styles.module.scss';
import { RejectedFeaturesCassandraAugurSettings } from './type';
import RejectAutoForceToggle from '../../../../../atoms/reject-auto-force-toggle/RejectAutoForceToggle';

export type Props = {
  colName: string;
  value: RejectedFeaturesCassandraAugurSettings;
  onChange?: (updatedFeatures: RejectedFeaturesCassandraAugurSettings) => void;
  readOnly?: boolean;
};

const InfluencerBar: FC<Props> = (props: Props) => {
  const { colName, value, onChange, readOnly } = props;

  const update = (newValue: 'rejected' | 'auto' | 'forced') => {
    if (readOnly) return;

    const updatedValue: RejectedFeaturesCassandraAugurSettings = {
      rejected: [...value.rejected],
      enforced: [...value.enforced],
    };

    if (newValue === 'rejected') {
      if (!updatedValue.rejected.includes(colName)) {
        updatedValue.rejected.push(colName);
      }
      //Remove from enforced it if is contained
      updatedValue.enforced = updatedValue.enforced.filter(
        (name) => name !== colName
      );
    } else if (newValue === 'forced') {
      if (!updatedValue.enforced.includes(colName)) {
        updatedValue.enforced.push(colName);
      }
      //Remove from rejected it if is contained
      updatedValue.rejected = updatedValue.rejected.filter(
        (name) => name !== colName
      );
    } else {
      //newValue is 'auto'
      updatedValue.enforced = updatedValue.enforced.filter(
        (name) => name !== colName
      );
      updatedValue.rejected = updatedValue.rejected.filter(
        (name) => name !== colName
      );
    }

    onChange?.(updatedValue);
  };

  return (
    <>
      <div
        className={classNames(styles.item, styles.rejected, {
          [styles.readOnly]: readOnly,
        })}
      >
        <RejectAutoForceToggle
          value={
            value.rejected.includes(colName)
              ? 'rejected'
              : value.enforced.includes(colName)
              ? 'forced'
              : 'auto'
          }
          onChange={update}
        />
      </div>

      <div
        className={classNames(styles.item, styles.feature, {
          [styles.readOnly]: readOnly,
        })}
        title={colName}
      >
        <span>{colName}</span>
      </div>
    </>
  );
};

export default InfluencerBar;
