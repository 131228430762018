import './style.css';
import { useBooleanFlagValue } from '@openfeature/react-sdk';
import { ReactFlowProvider } from '@xyflow/react';
import React, { FC, useEffect, useRef, useState } from 'react';
import { HotKeys } from 'react-hotkeys';
import { useSelector } from 'react-redux';

import ButtonBar from './ButtonBar';
import { codegen } from './codegen';
import { useWebSocketConnection } from './hooks';
import {
  enrichWithData,
  FlowConfig,
  FlowData,
  PersistedEdge,
  PersistedNode,
} from './nodes';
import Prototype from './Prototype';
import { saveNotebook } from '../../../../../redux/workbench/modules/notebook.module';
import { changeCodeContent } from '../../../../../redux/workbench/modules/notebook.source.module';
import { RootState, useAppDispatch } from '../../../../../store/store';
import {
  connectionCompleted,
  edgesChanged,
  flowOpened,
  nodesChanged,
  pathSelectionCleared,
  selectFlow,
  selectSelectedFlow,
  selectSelectedFlowPath,
  subflowDeselected,
} from '../../../../../store/workbench/flowDesigner.slice';
import {
  JUPYTER_CONTENT_TYPE,
  Session,
} from '../../../../../store/workbench/state.types';
import Busy from '../../../../atoms/busy/Busy';
import CloseConfirm from '../../../part-right/editor/close-confirm/CloseConfirm.container';

export type Props = {
  path: string;
  content: string;
  unsavedChanges: boolean;
  showCloseConfirm: boolean;
  paneId: string;
  session?: Session;
};

const flowDataToFlowConfig = (flow: FlowData): FlowConfig => ({
  ...flow,
  nodes: flow.nodes.map(
    (node) =>
      ({
        id: node.id,
        type: node.type,
        position: node.position,
        config: node.data.config,
      } satisfies PersistedNode)
  ),
  edges: flow.edges.map(
    (edge) =>
      ({
        id: edge.id,
        type: edge.type,
        target: edge.target,
        source: edge.source,
        targetHandle: edge.targetHandle,
        sourceHandle: edge.sourceHandle,
      } satisfies PersistedEdge)
  ),
  subflows: flow.subflows.map((flow) => flowDataToFlowConfig(flow)),
});

function flowConfigToFlowData(
  flowConfig: FlowConfig,
  filePath: string
): FlowData {
  return {
    ...flowConfig,
    nodes: flowConfig.nodes.map((node) => enrichWithData(filePath, node)),
    subflows: flowConfig.subflows.map((subflow) =>
      flowConfigToFlowData(subflow, filePath)
    ),
  };
}

const FlowDesigner: FC<Props> = ({
  path,
  content,
  unsavedChanges,
  showCloseConfirm,
  paneId,
  session,
}) => {
  const flowDesignerEnabled = useBooleanFlagValue('flow-designer', false);
  const dispatch = useAppDispatch();

  const flow = useSelector((state: RootState) => selectFlow(state, path));
  const selectedFlow = useSelector((state: RootState) =>
    selectSelectedFlow(state, path)
  );
  const selectedFlowPath = useSelector((state: RootState) =>
    selectSelectedFlowPath(state, path)
  );

  useEffect(() => {
    // FIXME this initializes the redux state, but this is not a good way to do it
    //   This MUST only happen once as otherwise
    if (!flow) {
      let initialFlowData: FlowData;
      try {
        const parsedContent: FlowConfig = JSON.parse(content);
        initialFlowData = flowConfigToFlowData(parsedContent, path);
      } catch (e) {
        console.warn(e);
      }

      dispatch(flowOpened({ filePath: path, flow: initialFlowData }));
    }
  }, [dispatch, path, content, flow]);

  // FIXME this is just to save, remove later
  useEffect(() => {
    if (!flow) return;

    const cleanedFlow: FlowConfig = flowDataToFlowConfig(flow);

    dispatch(
      changeCodeContent({
        path,
        content: JSON.stringify(cleanedFlow),
      })
    );
  }, [dispatch, path, flow]);

  const codeWrapper = useRef<HTMLDivElement>(null);
  const [showLog, setShowLog] = useState(false);
  const outputWrapper = useRef<HTMLDivElement>(null);
  const [showSource, setShowSource] = useState(false);

  const {
    log,
    sendExecuteRequest,
    sendInspectVariablesRequest,
    sendRichInspectVariablesRequest,
  } = useWebSocketConnection(session?.id, session?.kernel?.id);

  // Filled in a callback of the <AceEditor/>
  const keyMap = {
    save: 'command+s',
  };
  const keyHandlers = {
    save: (e) => {
      // @ts-ignore
      dispatch(saveNotebook(path, content, 'file'));
      e.preventDefault();
    },
  };

  // Is the close confirm supposed to be shown?
  if (showCloseConfirm) {
    return (
      <CloseConfirm
        path={path}
        content={content}
        paneId={paneId}
        type={JUPYTER_CONTENT_TYPE.FILE}
      />
    );
  }

  if (!flowDesignerEnabled) {
    return <div>Flow Designer disabled</div>;
  }

  if (!selectedFlow) {
    return <Busy isBusy />;
  }

  const source = codegen(selectedFlow).join('\n');

  return (
    <div className={'code-content'}>
      <HotKeys className={'hotkeys'} keyMap={keyMap} handlers={keyHandlers}>
        <ButtonBar
          path={path}
          content={content}
          unsavedChanges={unsavedChanges}
          toggleSource={() => setShowSource((prev) => !prev)}
          toggleLog={() => setShowLog((prev) => !prev)}
          sendExecuteRequest={() =>
            sendExecuteRequest(codegen(flow).join('\n'))
          }
          sendInspectVariablesRequest={sendInspectVariablesRequest}
          sendRichInspectVariablesRequest={sendRichInspectVariablesRequest}
          onGoBackFlowPath={() =>
            dispatch(
              subflowDeselected({
                filePath: path,
              })
            )
          }
          onClearFlowPath={() =>
            dispatch(
              pathSelectionCleared({
                filePath: path,
              })
            )
          }
        />

        <ReactFlowProvider>
          <div style={{ height: '100%', display: 'flex' }}>
            <Prototype
              key={selectedFlow.id}
              filePath={path}
              nodes={selectedFlow.nodes}
              edges={selectedFlow.edges}
              onNodesChanged={(changes) => {
                dispatch(
                  nodesChanged({
                    filePath: path,
                    changes: changes,
                    selectedFlowPath,
                  })
                );
              }}
              onEdgesChanged={(changes) =>
                dispatch(
                  edgesChanged({
                    filePath: path,
                    changes,
                    selectedFlowPath,
                  })
                )
              }
              onConnect={(connection) =>
                dispatch(
                  connectionCompleted({
                    filePath: path,
                    selectedFlowPath,
                    connection,
                  })
                )
              }
            />
            {showSource && (
              <div
                ref={codeWrapper}
                style={{
                  height: '100%',
                  color: 'white',
                  backgroundColor: 'rgb(47, 47, 47)',
                  display: 'flex',
                  width: 0,
                  flexGrow: 1,
                  padding: '8px',
                  borderLeft: 'solid grey 1px',
                }}
              >
                <pre>{source}</pre>
              </div>
            )}
            {showLog && (
              <div
                ref={outputWrapper}
                style={{
                  height: '100%',
                  color: 'white',
                  backgroundColor: 'rgb(47, 47, 47)',
                  display: 'flex',
                  width: 0,
                  flexGrow: 1,
                  padding: '8px',
                  borderLeft: 'solid grey 1px',
                }}
              >
                <pre>{log}</pre>
              </div>
            )}
          </div>
        </ReactFlowProvider>
      </HotKeys>
    </div>
  );
};

export default FlowDesigner;
