import classNames from 'classnames';
import React, { FC, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import Expand from './Expand';
import LoginHistoryItems from './LoginHistoryItems';
import './styles.scss';
import { useLoginHistory } from '../../../../core/api/accounts';
import Busy from '../../../atoms/busy/Busy';

const LoginHistory: FC = () => {
  const { data: history, isLoading } = useLoginHistory();

  const [expanded, setExpanded] = useState(false);

  if (isLoading) return <Busy isBusy={true} />;

  const expandLinkShown = history.length > 5;

  if (history && history.length === 0) {
    // History is empty - don't render the headline or anything
    return <div className='LoginHistory--wrap' />;
  }

  return (
    <div className={'LoginHistory'}>
      <div className='LoginHistory--title'>
        <FormattedMessage
          id='account.loginHistory.title'
          defaultMessage='Login History'
        />
      </div>
      <div className='LoginHistory--wrap'>
        <div
          className={classNames({
            'LoginHistory--preview': !expanded,
          })}
        >
          <LoginHistoryItems history={history} />
        </div>
        {expandLinkShown && (
          <Expand
            expanded={expanded}
            onExpand={() => setExpanded(true)}
            onShrink={() => setExpanded(false)}
          />
        )}
      </div>
    </div>
  );
};

export default LoginHistory;
