import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  addEdge,
  applyEdgeChanges,
  applyNodeChanges,
  Connection,
  EdgeChange,
  NodeChange,
  NodeRemoveChange,
} from '@xyflow/react';
import { v4 as uuidv4 } from 'uuid';

import { FlowData } from '../../components/workbench/fileTypes/flowDesigner/component/nodes';
import {
  CustomNodesWithGateway,
  DynamicConnectionsData,
  isDynamicConnectionsData,
  isNodeWithSubflow,
  Parameter,
  SubflowData,
} from '../../components/workbench/fileTypes/flowDesigner/component/types';
import { RootState } from '../store';

interface FlowDesignerState {
  [filePath: string]: {
    flow: FlowData;
    selectedFlowPath: string[];
  };
}

export const emptyFlowData: Omit<FlowData, 'id'> = {
  nodes: [],
  edges: [],
  subflows: [],
};

const initialState: FlowDesignerState = {};

function getSelectedSubflow(
  flow: FlowData,
  subflowSelectionPath: string[]
): FlowData {
  let selectedFlow = flow;
  subflowSelectionPath.forEach((subflowId) => {
    selectedFlow = selectedFlow.subflows.find(
      (subflow) => subflow.id === subflowId
    );
  });
  return selectedFlow;
}

function updateSelectedSubflow(
  flow: FlowData,
  subflowSelectionPath: string[],
  updatedFlow: Partial<FlowData>
): FlowData {
  if (subflowSelectionPath.length === 0) {
    return {
      ...flow,
      ...updatedFlow,
    };
  }

  const [subflowId, ...rest] = subflowSelectionPath;
  return {
    ...flow,
    subflows: flow.subflows.map((subflow) => {
      if (subflow.id === subflowId) {
        return updateSelectedSubflow(subflow, rest, updatedFlow);
      }
      return subflow;
    }),
  };
}

function updateSubflowReference(
  nodes: CustomNodesWithGateway[],
  subflowId: string,
  nodeId: string
) {
  return nodes.map((node) => {
    if (node.id === nodeId && isNodeWithSubflow(node)) {
      return {
        ...node,
        data: {
          ...node.data,
          config: {
            ...node.data.config,
            subflowId,
          },
        },
      } as typeof node;
    }
    return node;
  });
}

function addParameter(
  nodes: CustomNodesWithGateway[],
  parameter: Parameter,
  parameterType: 'in' | 'out',
  nodeId: string
) {
  return nodes.map((node) => {
    if (node.id === nodeId && isDynamicConnectionsData(node.data)) {
      const newConfig = {
        ...node.data.config,
        connections: {
          outputs:
            parameterType === 'out'
              ? [...node.data.config.connections.outputs, parameter]
              : node.data.config.connections.outputs,
          inputs:
            parameterType === 'in'
              ? [...node.data.config.connections.inputs, parameter]
              : node.data.config.connections.inputs,
        },
      } satisfies DynamicConnectionsData['config'];
      return {
        ...node,
        data: {
          ...node.data,
          config: newConfig,
        },
      } as typeof node;
    }
    return node;
  });
}

function removeParameter(
  nodes: CustomNodesWithGateway[],
  parameterId: string,
  parameterType: 'in' | 'out',
  nodeId: string
) {
  return nodes.map((node) => {
    if (node.id === nodeId && isDynamicConnectionsData(node.data)) {
      const newConfig = {
        ...node.data.config,
        connections: {
          outputs:
            parameterType === 'out'
              ? node.data.config.connections.outputs.filter(
                  (parameter) => parameter.id !== parameterId
                )
              : node.data.config.connections.outputs,
          inputs:
            parameterType === 'in'
              ? node.data.config.connections.inputs.filter(
                  (parameter) => parameter.id !== parameterId
                )
              : node.data.config.connections.inputs,
        },
      } satisfies DynamicConnectionsData['config'];
      return {
        ...node,
        data: {
          ...node.data,
          config: newConfig,
        },
      } as typeof node;
    }
    return node;
  });
}

export const flowDesignerSlice = createSlice({
  name: 'flowDesigner',
  initialState,
  reducers: {
    flowOpened: (
      state,
      action: PayloadAction<{ filePath: string; flow: FlowData }>
    ) => {
      const { filePath, flow } = action.payload;
      state[filePath] = {
        flow: flow
          ? flow
          : {
              ...emptyFlowData,
              id: uuidv4(),
            },
        selectedFlowPath: [],
      };
    },
    subflowSelected: (
      state,
      action: PayloadAction<{
        filePath: string;
        selectedFlowId: string;
      }>
    ) => {
      const { filePath, selectedFlowId } = action.payload;

      const selectedFlow = getSelectedSubflow(
        state[filePath].flow,
        state[filePath].selectedFlowPath
      );

      if (
        selectedFlow.subflows.find((subflow) => subflow.id === selectedFlowId)
      ) {
        state[filePath].selectedFlowPath = [
          ...state[filePath].selectedFlowPath,
          selectedFlowId,
        ];
      } else {
        console.warn(
          `Could not find subflow with id ${selectedFlowId} for selectedFlowPath ${state[
            filePath
          ].selectedFlowPath.toString()}`
        );
      }
    },
    subflowDeselected: (state, action: PayloadAction<{ filePath: string }>) => {
      const { filePath } = action.payload;
      state[filePath].selectedFlowPath = state[filePath].selectedFlowPath.slice(
        0,
        -1
      );
    },
    pathSelectionCleared: (
      state,
      action: PayloadAction<{ filePath: string }>
    ) => {
      const { filePath } = action.payload;
      state[filePath].selectedFlowPath = [];
    },
    nodesChanged: (
      state,
      action: PayloadAction<{
        filePath: string;
        changes: NodeChange<CustomNodesWithGateway>[];
        selectedFlowPath: string[];
      }>
    ) => {
      const { filePath, changes, selectedFlowPath } = action.payload;
      const previousFlow = getSelectedSubflow(
        state[filePath].flow,
        selectedFlowPath
      );
      const nodes = applyNodeChanges(changes, previousFlow.nodes);

      // if we delete a node with a subflow we also must delete the associated subflow object
      const subflowsToRemove: string[] = [];
      changes
        .filter(
          (change): change is NodeRemoveChange => change.type === 'remove'
        )
        .forEach((change) => {
          const nodeIndex = previousFlow.nodes.findIndex(
            (node) => node.id === change.id
          );
          if (isNodeWithSubflow(previousFlow.nodes[nodeIndex])) {
            const subflowId = (
              previousFlow.nodes[nodeIndex].data as SubflowData
            ).config.subflowId;
            if (subflowId) {
              subflowsToRemove.push(subflowId);
            }
          }
        });
      const subflows = previousFlow.subflows.filter(
        (subflow) => !subflowsToRemove.includes(subflow.id)
      );

      state[filePath].flow = updateSelectedSubflow(
        state[filePath].flow,
        selectedFlowPath,
        { ...previousFlow, nodes, subflows }
      );
    },
    edgesChanged: (
      state,
      action: PayloadAction<{
        filePath: string;
        changes: EdgeChange[];
        selectedFlowPath: string[];
      }>
    ) => {
      const { filePath, changes, selectedFlowPath } = action.payload;
      const previousFlow = getSelectedSubflow(
        state[filePath].flow,
        selectedFlowPath
      );
      const edges = applyEdgeChanges(changes, previousFlow.edges);
      state[filePath].flow = updateSelectedSubflow(
        state[filePath].flow,
        selectedFlowPath,
        {
          ...previousFlow,
          ...{ edges },
        }
      );
    },
    connectionCompleted: (
      state,
      action: PayloadAction<{
        filePath: string;
        selectedFlowPath: string[];
        connection: Connection;
      }>
    ) => {
      const { filePath, selectedFlowPath, connection } = action.payload;
      const previousFlow = getSelectedSubflow(
        state[filePath].flow,
        selectedFlowPath
      );
      const edges = addEdge(connection, previousFlow.edges);
      state[filePath].flow = updateSelectedSubflow(
        state[filePath].flow,
        selectedFlowPath,
        {
          ...previousFlow,
          ...{ edges },
        }
      );
    },
    subflowAdded: (
      state,
      action: PayloadAction<{
        filePath: string;
        selectedFlowPath: string[];
        subflow: FlowData;
        parentNodeId: string;
      }>
    ) => {
      const { filePath, selectedFlowPath, subflow, parentNodeId } =
        action.payload;
      const selectedFlow = getSelectedSubflow(
        { ...state[filePath].flow },
        selectedFlowPath
      );

      const nodes = updateSubflowReference(
        selectedFlow.nodes,
        subflow.id,
        parentNodeId
      );
      const subflows = [
        ...selectedFlow.subflows,
        { ...emptyFlowData, ...subflow },
      ];

      state[filePath].flow = updateSelectedSubflow(
        state[filePath].flow,
        selectedFlowPath,
        {
          ...selectedFlow,
          nodes,
          subflows,
        }
      );
    },
    subflowDeleted: (
      state,
      action: PayloadAction<{
        filePath: string;
        selectedFlowPath: string[];
        subflowId: string;
        parentNodeId: string;
      }>
    ) => {
      const { filePath, selectedFlowPath, subflowId, parentNodeId } =
        action.payload;
      const selectedFlow = getSelectedSubflow(
        state[filePath].flow,
        selectedFlowPath
      );

      const nodes = updateSubflowReference(
        selectedFlow.nodes,
        undefined,
        parentNodeId
      );
      const subflows = selectedFlow.subflows.filter(
        (subflow) => subflow.id === subflowId
      );

      state[filePath].flow = updateSelectedSubflow(
        state[filePath].flow,
        selectedFlowPath,
        {
          ...selectedFlow,
          nodes,
          subflows,
        }
      );
    },
    parameterAdded: (
      state,
      action: PayloadAction<{
        filePath: string;
        selectedFlowPath: string[];
        nodeId: string;
        gatewayType: 'in' | 'out';
        parameter: Parameter;
      }>
    ) => {
      const { filePath, selectedFlowPath, nodeId, gatewayType, parameter } =
        action.payload;
      const flow = getSelectedSubflow(state[filePath].flow, selectedFlowPath);
      const parentPath = selectedFlowPath.slice(0, -1);
      const parentFlow = getSelectedSubflow(state[filePath].flow, parentPath);

      // we have to invert the type here because the input of a subflow is an output parameter of a gateway node of type 'in' and vice versa
      const nodes = addParameter(
        flow.nodes,
        parameter,
        gatewayType === 'in' ? 'out' : 'in',
        nodeId
      );
      const parentNodes = addParameter(
        parentFlow.nodes,
        parameter,
        gatewayType,
        flow.parentNodeId
      );

      const flowWithUpdatedSubflow = updateSelectedSubflow(
        state[filePath].flow,
        selectedFlowPath,
        { nodes }
      );

      state[filePath].flow = updateSelectedSubflow(
        flowWithUpdatedSubflow,
        parentPath,
        {
          nodes: parentNodes,
        }
      );
    },
    parameterRemoved: (
      state,
      action: PayloadAction<{
        filePath: string;
        selectedFlowPath: string[];
        nodeId: string;
        gatewayType: 'in' | 'out';
        parameterId: string;
      }>
    ) => {
      const { filePath, selectedFlowPath, nodeId, gatewayType, parameterId } =
        action.payload;
      const flow = getSelectedSubflow(state[filePath].flow, selectedFlowPath);
      const parentPath = selectedFlowPath.slice(0, -1);
      const parentFlow = getSelectedSubflow(
        state[filePath].flow,
        selectedFlowPath.slice(-1)
      );

      // we have to invert the type here because the input of a subflow is an output parameter of a gateway node of type 'in' and vice versa
      const nodes = removeParameter(
        flow.nodes,
        parameterId,
        gatewayType === 'in' ? 'out' : 'in',
        nodeId
      );
      const parentNodes = removeParameter(
        parentFlow.nodes,
        parameterId,
        gatewayType,
        flow.parentNodeId
      );

      const flowWithUpdatedSubflow = updateSelectedSubflow(
        state[filePath].flow,
        selectedFlowPath,
        { nodes }
      );

      state[filePath].flow = updateSelectedSubflow(
        flowWithUpdatedSubflow,
        parentPath,
        {
          nodes: parentNodes,
        }
      );
    },
  },
});

export const {
  flowOpened,
  pathSelectionCleared,
  subflowDeleted,
  subflowAdded,
  subflowDeselected,
  subflowSelected,
  nodesChanged,
  edgesChanged,
  connectionCompleted,
  parameterAdded,
  parameterRemoved,
} = flowDesignerSlice.actions;

export const selectFlow = (state: RootState, filePath: string): FlowData => {
  const flowFileState = state.workbench.flowDesigner[filePath];
  if (!flowFileState) return undefined;

  return flowFileState?.flow;
};

export const selectSelectedFlow = (
  state: RootState,
  filePath: string
): FlowData => {
  const flowFileState = state.workbench.flowDesigner[filePath];
  if (!flowFileState) return undefined;

  return getSelectedSubflow(flowFileState.flow, flowFileState.selectedFlowPath);
};

export const selectSelectedFlowPath = (
  state: RootState,
  filePath: string
): string[] => {
  const flowFileState = state.workbench.flowDesigner[filePath];
  if (!flowFileState) return undefined;

  return state.workbench.flowDesigner[filePath]?.selectedFlowPath;
};

export default flowDesignerSlice.reducer;
