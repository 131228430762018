import type { Node, NodeProps } from '@xyflow/react';
import React from 'react';
import '@xyflow/react/dist/base.css';

import { FlowData } from './index';
import styles from './styles.module.scss';
import { generateHandles } from './utils';
import { CodeGenContext, getIncomingVarNameByHandleId } from '../codegen';
import {
  AS_NODE_TYPES,
  GenericNodeData,
  StaticConnectionsData,
} from '../types';

import classNames from 'classnames';

type PrintNodeData = GenericNodeData & StaticConnectionsData;

export type PrintNode = Node<PrintNodeData, typeof AS_NODE_TYPES.PRINT>;

export function isPrintNode(node: Node): node is PrintNode {
  return node.type === AS_NODE_TYPES.PRINT;
}

export const generatePrintNodeCode = (
  context: CodeGenContext,
  node: PrintNode,
  flow: FlowData
) => {
  const inputHandleId = node.data.connections.inputs[0].id;
  const inputVar =
    getIncomingVarNameByHandleId(context, flow, node, inputHandleId) ?? 'None';

  return [`print(${inputVar})`];
};

export function PrintNode({ data }: NodeProps<PrintNode>) {
  const {
    connections: { inputs },
  } = data;
  return (
    <div
      className={classNames(
        'react-flow__node-default',
        styles.defaultNodeStyle
      )}
      style={data.highlight ? { backgroundColor: 'rgb(222, 222, 222)' } : {}}
    >
      {generateHandles(inputs, 'target')}
      PRINT
    </div>
  );
}
