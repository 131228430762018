// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.enable-maintenance-container {
  padding: 0 20px;
}`, "",{"version":3,"sources":["webpack://./src/js/components/admin/maintenance/enable-maintenance/style.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;AACF","sourcesContent":[".enable-maintenance-container {\n  padding: 0 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
