// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_elementCardGrid__GMMHt {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(265px, 1fr));
  grid-gap: 20px;
}`, "",{"version":3,"sources":["webpack://./src/js/components/organisms/element-card-grid/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,4DAAA;EACA,cAAA;AACF","sourcesContent":[".elementCardGrid {\n  display: grid;\n  grid-template-columns: repeat(auto-fill, minmax(265px, 1fr));\n  grid-gap: 20px;\n\n  .elementCardGridChild {}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"elementCardGrid": `styles_elementCardGrid__GMMHt`
};
export default ___CSS_LOADER_EXPORT___;
