// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_modalContent__TeaL3 span {
  display: block;
}
.styles_modalContent__TeaL3 span.styles_affected__I3LWN {
  margin-top: 10px;
  font-weight: 400;
}
.styles_modalContent__TeaL3 .styles_conflicts__oJYPf .styles_fileName__5o53f {
  display: list-item;
  font-size: 14px;
  margin-left: 10px;
  list-style-type: "- ";
}`, "",{"version":3,"sources":["webpack://./src/js/components/workbench/part-right/repository/info/git-not-pulled/styles.module.scss"],"names":[],"mappings":"AACE;EACE,cAAA;AAAJ;AAEI;EACE,gBAAA;EACA,gBAAA;AAAN;AAKI;EACE,kBAAA;EACA,eAAA;EACA,iBAAA;EACA,qBAAA;AAHN","sourcesContent":[".modalContent {\n  span {\n    display: block;\n\n    &.affected {\n      margin-top: 10px;\n      font-weight: 400;\n    }\n  }\n\n  .conflicts {\n    .fileName {\n      display: list-item;\n      font-size: 14px;\n      margin-left: 10px;\n      list-style-type: '- '\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modalContent": `styles_modalContent__TeaL3`,
	"affected": `styles_affected__I3LWN`,
	"conflicts": `styles_conflicts__oJYPf`,
	"fileName": `styles_fileName__5o53f`
};
export default ___CSS_LOADER_EXPORT___;
