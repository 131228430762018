import { Node, NodeProps } from '@xyflow/react';
import classNames from 'classnames';
import React from 'react';
import { v4 as uuidv4 } from 'uuid';

import { FlowData } from './index';
import styles from './styles.module.scss';
import { createEmptySubflow } from './SubflowGatewayNode';
import { generateHandles } from './utils';
import Button from '../../../../../atoms/button/Button';
import {
  CodeGenContext,
  generateReturnValues,
  getIncomingVarNameByHandleId,
  subflowFn,
} from '../codegen';
import { useSubflowUtils } from '../hooks';
import {
  AS_NODE_TYPES,
  AS_TYPES,
  CustomNodesWithGateway,
  GenericNodeData,
  StaticConnectionsData,
  SubflowData,
} from '../types';

type MapNodeData = GenericNodeData & StaticConnectionsData & SubflowData;
export type MapNode = Node<MapNodeData, typeof AS_NODE_TYPES.MAP>;

export const generateMapNodeCode = (
  context: CodeGenContext,
  node: MapNode,
  flow: FlowData
) => {
  const subflow = flow.subflows.find(
    (subflow) => subflow.id === node.data.config.subflowId
  );
  const subflowName = subflowFn(context, subflow);

  const inputHandleId = node.data.connections.inputs[0].id;
  const inputVar = getIncomingVarNameByHandleId(
    context,
    flow,
    node,
    inputHandleId
  );

  const returnVar = generateReturnValues(context, node);
  return [`${returnVar} = [${subflowName}(x) for x in ${inputVar}]`];
};

export function isMapNode(node: CustomNodesWithGateway): node is MapNode {
  return node.type === AS_NODE_TYPES.MAP;
}

export function MapNode({ id, data }: NodeProps<MapNode>) {
  const {
    filePath,
    config,
    highlight,
    connections: { outputs, inputs },
  } = data;

  const { addSubflow, openSubflow } = useSubflowUtils(filePath);

  return (
    <div
      className={classNames(
        'react-flow__node-default',
        styles.defaultNodeStyle
      )}
      style={highlight ? { backgroundColor: 'rgb(222, 222, 222)' } : {}}
    >
      {generateHandles(inputs, 'target')}
      MAP
      {!config.subflowId && (
        <Button
          label={'Create Subflow'}
          onClick={() => {
            const subflow = createEmptySubflow(
              filePath,
              id,
              [
                {
                  id: uuidv4(),
                  type: AS_TYPES.ANY,
                },
              ],
              [
                {
                  id: uuidv4(),
                  type: AS_TYPES.ANY,
                },
              ],
              false
            );
            addSubflow(id, subflow);
            openSubflow(id, subflow.id);
          }}
        />
      )}
      {config.subflowId && (
        <Button
          label={'Open Subflow'}
          onClick={() => openSubflow(id, config.subflowId)}
        />
      )}
      {generateHandles(outputs, 'source')}
    </div>
  );
}
