import { Node, NodeProps } from '@xyflow/react';
import classNames from 'classnames';
import React from 'react';

import { FlowData } from './index';
import styles from './styles.module.scss';
import { createEmptySubflow } from './SubflowGatewayNode';
import { generateHandles } from './utils';
import Button from '../../../../../atoms/button/Button';
import {
  CodeGenContext,
  generateReturnValues,
  getIncomingVarNameByHandleId,
  subflowFn,
} from '../codegen';
import { useSubflowUtils } from '../hooks';
import {
  AS_NODE_TYPES,
  CustomNodesWithGateway,
  DynamicConnectionsData,
  GenericNodeData,
  SubflowData,
} from '../types';

export type SubflowNodeData = GenericNodeData &
  SubflowData &
  DynamicConnectionsData;
export type SubflowNode = Node<SubflowNodeData, typeof AS_NODE_TYPES.SUBFLOW>;

export const generateSubflowCode = (
  context: CodeGenContext,
  node: SubflowNode,
  flow: FlowData
) => {
  const subflow = flow.subflows.find(
    (subflow) => subflow.id === node.data.config.subflowId
  );
  const subflowName = subflowFn(context, subflow);

  const inputVars = node.data.config.connections.inputs
    .map((input) => getIncomingVarNameByHandleId(context, flow, node, input.id))
    .join(', ');
  const outputVars = generateReturnValues(context, node);

  return [`${outputVars} = ${subflowName}(${inputVars})`, ''];
};

export function isSubflowNode(
  node: CustomNodesWithGateway
): node is SubflowNode {
  return node.type === AS_NODE_TYPES.SUBFLOW;
}

export function SubflowNode({ id, data }: NodeProps<SubflowNode>) {
  const {
    filePath,
    config,
    highlight,
    // fixme-fd: is config really the correct place here?
    config: {
      connections: { inputs, outputs },
    },
  } = data;

  const { addSubflow, openSubflow } = useSubflowUtils(filePath);

  return (
    <div
      className={classNames(
        'react-flow__node-default',
        styles.defaultNodeStyle
      )}
      style={highlight ? { backgroundColor: 'rgb(222, 222, 222)' } : {}}
    >
      {generateHandles(inputs, 'target')}
      SUBFLOW
      {!config.subflowId && (
        <Button
          label={'Create Subflow'}
          onClick={() => {
            const subflow = createEmptySubflow(filePath, id, [], [], true);
            addSubflow(id, subflow);
            openSubflow(id, subflow.id);
          }}
        />
      )}
      {config.subflowId && (
        <Button
          label={'Open Subflow'}
          onClick={() => openSubflow(id, config.subflowId)}
        />
      )}
      {generateHandles(outputs, 'source')}
    </div>
  );
}
