import * as adminBackups from './admin/backups/state';
import * as adminMaintenance from './admin/maintenance/state';

// Workbench initial state
// --- Migrated to Typescript
import * as adminResources from './admin/resources/state';
import * as augurBiography from './augurBiography/slice';
import { DeprecatedRootState } from './state.type';
import * as admin from '../redux/state/admin.state';
import * as apps from '../redux/state/apps.state';
import * as codeCapsules from '../redux/state/codeCapsules.state';
import * as currentUser from '../redux/state/currentUser.state';
import * as dashboard from '../redux/state/dashboard.state';
import * as data from '../redux/state/data.state';
import * as intl from '../redux/state/intl.state';
import * as menu from '../redux/state/menu.state';
import * as newSchedule from '../redux/state/newSchedule.state';
import * as notifications from '../redux/state/notifications.state';
import * as codeCapsuleOutput from '../redux/state/orchestration.codeCapsuleOutput.state';
import * as codeCapsuleReport from '../redux/state/orchestration.codeCapsuleReport.state';
import * as history from '../redux/state/orchestration.history.state';
import * as jobconfigs from '../redux/state/orchestration.jobconfigs.state';
import * as jobDetails from '../redux/state/orchestration.jobdetails.state';
import * as queue from '../redux/state/orchestration.queue.state';
import * as schedules from '../redux/state/orchestration.schedules.state';
import * as orchestration from '../redux/state/orchestration.state';
import * as page from '../redux/state/page.state';
import * as summary from '../redux/state/summary.state';
import { initialState as initialWorkbenchState } from '../redux/workbench/state/initial';

export const test: DeprecatedRootState = {
  dashboard: dashboard.test,
  // @ts-ignore
  currentUser: currentUser.test,
  // @ts-ignore
  summary: summary.test,
  menu: menu.test,
  notifications: notifications.test,
  page: page.test,
  admin: admin.test,
  selectedMainTab: 'models',
  // @ts-ignore
  workbench: initialWorkbenchState,
  orchestration: orchestration.test,
  jobconfigs: jobconfigs.test,
  queue: queue.test,
  jobGroupHistory: history.test,
  jobDetails: jobDetails.test,
  codeCapsuleReport: codeCapsuleReport.test,
  codeCapsuleOutput: codeCapsuleOutput.test,
  jobSchedules: schedules.test,
  newSchedule: newSchedule.test,
  codeCapsules: codeCapsules.test,
  apps: apps.test,
  appVersions: {},
  adminResources: adminResources.test,
  adminBackups: adminBackups.test,
  adminMaintenance: adminMaintenance.test,
  // @ts-ignore
  data: data.test,
  augurBiography: augurBiography.test,
};

export const initial: DeprecatedRootState = {
  dashboard: dashboard.initial,
  // @ts-ignore
  currentUser: currentUser.initial,
  summary: summary.initial,
  menu: menu.initial,
  intl: intl.initial,
  notifications: notifications.initial,
  page: page.initial,
  admin: admin.initial,
  selectedMainTab: 'models',
  // @ts-ignore
  workbench: initialWorkbenchState,
  orchestration: orchestration.initial,
  jobconfigs: jobconfigs.initial,
  queue: queue.initial,
  jobGroupHistory: history.initial,
  jobDetails: jobDetails.initial,
  codeCapsuleReport: codeCapsuleReport.initial,
  codeCapsuleOutput: codeCapsuleOutput.initial,
  jobSchedules: schedules.initial,
  newSchedule: newSchedule.initial,
  codeCapsules: codeCapsules.initial,
  apps: apps.initial,
  appVersions: {},
  adminResources: adminResources.initial,
  adminBackups: adminBackups.initial,
  adminMaintenance: adminMaintenance.initial,
  // @ts-ignore
  data: data.initial,
  augurBiography: augurBiography.initial,
};
