// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:where(.css-dev-only-do-not-override-1kuana8).ant-select-outlined:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer):hover .ant-select-selector {
  border-color: #bbb;
}

:where(.css-dev-only-do-not-override-1kuana8).ant-select-outlined:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer):hover .ant-select-selector,
:where(.css-dev-only-do-not-override-1kuana8).ant-select-outlined:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer).ant-select-focused .ant-select-selector {
  border-color: #bbb;
  box-shadow: none;
}

.ant-select-focused:where(.css-dev-only-do-not-override-1kuana8).ant-select-outlined:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer) .ant-select-selector {
  border-color: #bbb;
  box-shadow: none;
  outline: 0;
}

:where(.css-dev-only-do-not-override-1kuana8).ant-select-dropdown .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: #eef0f2;
}`, "",{"version":3,"sources":["webpack://./src/js/components/molecules/cron-schedule-select/antstyles.scss"],"names":[],"mappings":"AAAA;EAIE,kBAAA;AAFF;;AAKA;;EAUE,kBAAA;EACA,gBAAA;AAVF;;AAaA;EAME,kBAAA;EACA,gBAAA;EACA,UAAA;AAfF;;AAkBA;EAEE,yBAAA;AAhBF","sourcesContent":[":where(.css-dev-only-do-not-override-1kuana8).ant-select-outlined:not(\n    .ant-select-disabled\n  ):not(.ant-select-customize-input):not(.ant-pagination-size-changer):hover\n  .ant-select-selector {\n  border-color: #bbb;\n}\n\n:where(.css-dev-only-do-not-override-1kuana8).ant-select-outlined:not(\n    .ant-select-disabled\n  ):not(.ant-select-customize-input):not(.ant-pagination-size-changer):hover\n  .ant-select-selector,\n:where(.css-dev-only-do-not-override-1kuana8).ant-select-outlined:not(\n    .ant-select-disabled\n  ):not(.ant-select-customize-input):not(\n    .ant-pagination-size-changer\n  ).ant-select-focused\n  .ant-select-selector {\n  border-color: #bbb;\n  box-shadow: none;\n}\n\n.ant-select-focused:where(\n    .css-dev-only-do-not-override-1kuana8\n  ).ant-select-outlined:not(.ant-select-disabled):not(\n    .ant-select-customize-input\n  ):not(.ant-pagination-size-changer)\n  .ant-select-selector {\n  border-color: #bbb;\n  box-shadow: none;\n  outline: 0;\n}\n\n:where(.css-dev-only-do-not-override-1kuana8).ant-select-dropdown\n  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {\n  background-color: #eef0f2;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
