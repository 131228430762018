import { connect } from 'react-redux';
import Language from './Language';
import { DeprecatedRootState } from '../../../../store/state.type';
import { saveLanguage } from '../../../../redux/modules/account.module';

function mapStateToProps(state: DeprecatedRootState) {
  return {
    intlLocale: state.intl.locale,
  };
}

const mapDispatchToProps = {
  saveLanguage,
};

export default connect(mapStateToProps, mapDispatchToProps)(Language);
