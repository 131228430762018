import type { Node, NodeProps } from '@xyflow/react';
import classNames from 'classnames';
import React from 'react';

import { FlowData } from './index';
import styles from './styles.module.scss';
import { generateHandles } from './utils';
import {
  CodeGenContext,
  generateReturnValues,
  getIncomingVarNameByHandleId,
} from '../codegen';
import {
  AS_NODE_TYPES,
  DynamicConnectionsData,
  GenericNodeData,
} from '../types';

export type PythonFunctionNodeData = {
  config: {
    name: string;
    sourceCode: string;
  };
} & GenericNodeData &
  DynamicConnectionsData;

export type PythonFunctionNode = Node<
  PythonFunctionNodeData,
  typeof AS_NODE_TYPES.PYTHON_FN
>;

export const generatePythonFunctionNodeCode = (
  context: CodeGenContext,
  node: PythonFunctionNode,
  flow: FlowData
) => {
  const sourceCodeStatements = node.data.config.sourceCode.split('\n');
  const statements = [...sourceCodeStatements, ''];

  const params = node.data.config.connections.inputs
    .map((input) => getIncomingVarNameByHandleId(context, flow, node, input.id))
    .join(', ');

  const returnVars = generateReturnValues(context, node);

  if (!returnVars) {
    statements.push(`${node.data.config.name}(${params})`);
  } else {
    statements.push(`${returnVars} = ${node.data.config.name}(${params})`);
  }

  return statements;
};

export function isPythonFunctionNode(node: Node): node is PythonFunctionNode {
  return node.type === AS_NODE_TYPES.PYTHON_FN;
}

export function PythonFunctionNode({ data }: NodeProps<PythonFunctionNode>) {
  const {
    config: {
      name,
      connections: { inputs, outputs },
    },
  } = data;

  return (
    <div
      className={classNames(
        'react-flow__node-default',
        styles.defaultNodeStyle
      )}
      style={data.highlight ? { backgroundColor: 'rgb(222, 222, 222)' } : {}}
    >
      {generateHandles(inputs, 'target')}
      <div>{name}</div>
      {generateHandles(outputs, 'source')}
    </div>
  );
}
