import { Node } from '@xyflow/react';

import { CodeGenContext } from './codegen';
import { FlowData } from './nodes';
import { ConditionalNode } from './nodes/ConditionalNode';
import { InputNode } from './nodes/InputNode';
import { isMapNode, MapNode } from './nodes/MapNode';
import { PrintNode } from './nodes/PrintNode';
import { PythonFunctionNode } from './nodes/PythonFunction';
import { SubflowGatewayNode } from './nodes/SubflowGatewayNode';
import { isSubflowNode, SubflowNode } from './nodes/SubflowNode';

export const AS_TYPES = {
  ANY: 'any',
  TABLE: 'table',
  BOOL: 'bool',
  NUMBER: 'number',
  STRING: 'string',
} as const;
export type AsTypes = (typeof AS_TYPES)[keyof typeof AS_TYPES];

export const AS_NODE_TYPES = {
  CONDITIONAL: 'conditional',
  INPUT_STRING: 'input_str',
  INPUT_INT: 'input_int',
  INPUT_BOOL: 'input_bool',
  MAP: 'map',
  PRINT: 'print',
  PYTHON_FN: 'python_fn',
  SUBFLOW: 'subflow',
} as const;
export type AsNodeTypes = (typeof AS_NODE_TYPES)[keyof typeof AS_NODE_TYPES];

export const AS_EXTENDED_NODE_TYPES = {
  ...AS_NODE_TYPES,
  GATEWAY: 'gateway',
} as const;
export type AsExtendedNodeTypes =
  (typeof AS_EXTENDED_NODE_TYPES)[keyof typeof AS_EXTENDED_NODE_TYPES];

export type Parameter = {
  id: string;
  type: AsTypes;
  name?: string;
};

export type GenericNodeData = {
  filePath: string;
  config: Record<string, unknown>;
  highlight?: boolean;
};

export type SubflowData = {
  config: {
    subflowId?: string;
  };
};

export type StaticConnectionsData = {
  dynamicConnections?: false;
  connections: ConnectionMeta;
};

export type DynamicConnectionsData = {
  dynamicConnections: true;
  connections?: never;
  config: {
    connections: ConnectionMeta;
  };
};

export function isDynamicConnectionsData(
  data: Record<string, unknown>
): data is DynamicConnectionsData {
  return !!data.dynamicConnections;
}

export type NodeMeta = {
  generate: (context: CodeGenContext, node: Node, flow: FlowData) => string[];
  defaultData: {
    connections?: ConnectionMeta;
    dynamicConnections?: boolean;
  };
};

export type ConnectionMeta = {
  extendable?: {
    inputs?: boolean;
    outputs?: boolean;
  };
  inputs: Parameter[];
  outputs: Parameter[];
};

export type CustomNodes =
  | MapNode
  | InputNode
  | ConditionalNode
  | PrintNode
  | PythonFunctionNode
  | SubflowNode;

export type CustomNodesWithGateway = CustomNodes | SubflowGatewayNode;

export type NodeWithSubflow = SubflowNode | MapNode;

export function isNodeWithSubflow(
  node: CustomNodesWithGateway
): node is NodeWithSubflow {
  return isSubflowNode(node) || isMapNode(node);
}
