import React, { FC, PropsWithChildren } from 'react';
import { useDrag } from 'react-dnd';

import { AsNodeTypes } from './types';

export type ToolbarNode = {
  name?: string;
  type: AsNodeTypes;
  //fixme-fd type this
  config?: Record<string, unknown>;
};

export const Toolbar: FC<{ nodes: ToolbarNode[] }> = ({ nodes }) => {
  return (
    <div
      style={{
        overflowX: 'scroll',
        display: 'flex',
        gap: '2em',
        padding: '2em',
        borderTop: '2px solid rgb(47, 47, 47)',
      }}
    >
      {nodes.map((node) => (
        <DraggableNode node={node}>
          <NodeWithoutHandles node={node} />
        </DraggableNode>
      ))}
    </div>
  );
};

const NodeWithoutHandles: FC<{ node: ToolbarNode }> = ({
  node: { name, type },
}) => (
  <div className='react-flow__node-default'>
    {type}
    {name ? `: ${name}` : ''}
  </div>
);

const DraggableNode: FC<PropsWithChildren<{ node: ToolbarNode }>> = ({
  node,
  children,
}) => {
  const [, drag] = useDrag({
    item: {
      type: 'fdNode',
      node: node,
    },
  });
  return (
    <div style={{ position: 'relative' }} ref={drag}>
      {children}
    </div>
  );
};
