export const ACTIVE_PROJECT_PATH_KEY = 'state.workbench.activeProject.path';

export const saveActiveProjectPath = (path: string): void => {
  try {
    localStorage.setItem(ACTIVE_PROJECT_PATH_KEY, path);
  } catch (err) {
    console.error('Could not save active project path to localStorage', err);
  }
};

export const clearLocalStorage = () => {
  localStorage.removeItem(ACTIVE_PROJECT_PATH_KEY);
};
