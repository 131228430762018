import React, { FC } from 'react';
import { FormattedMessage, FormattedDate, FormattedTime } from 'react-intl';

const Yes = (
  <strong className='text-green'>
    <FormattedMessage id='common.yes' defaultMessage='Yes' />
  </strong>
);

const No = (
  <strong className='text-red'>
    <FormattedMessage id='common.no' defaultMessage='No' />
  </strong>
);

export type LoginHistoryEntry = {
  time?: number;
  type?: 'LOGIN' | 'LOGIN_ERROR' | 'LOGOUT';
  userId: string;
  sessionId: string;
  ipAddress: string;
};

type Props = {
  /** Single entry of the Login history */
  item: LoginHistoryEntry;
};

const LoginHistoryItem: FC<Props> = (props) => {
  const {
    item: { time, ipAddress, type },
  }: Props = props;

  const SUCCESS_EVENTS = ['LOGIN', 'LOGOUT'];
  const success = SUCCESS_EVENTS.includes(type);

  return (
    <tr>
      <td>
        <FormattedDate value={time} />
        &nbsp;
        <FormattedTime value={time} />
      </td>
      <td>{type}</td>
      <td>{success ? Yes : No}</td>
      <td>{ipAddress}</td>
    </tr>
  );
};

export default LoginHistoryItem;
