// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_loadingSwitch__uGCKw {
  width: 40px;
  height: 20px;
  border-radius: 10px;
  overflow: hidden;
}

.styles_themedSwitch__A1b6J.styles_disabled__IjsUg {
  cursor: not-allowed;
}
.styles_themedSwitch__A1b6J.styles_disabled__IjsUg * {
  cursor: inherit !important;
}`, "",{"version":3,"sources":["webpack://./src/js/components/atoms/themed-switch/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,YAAA;EACA,mBAAA;EACA,gBAAA;AACF;;AAEE;EACE,mBAAA;AACJ;AAAI;EACE,0BAAA;AAEN","sourcesContent":[".loadingSwitch {\n  width: 40px;\n  height: 20px;\n  border-radius: 10px;\n  overflow: hidden;\n}\n.themedSwitch {\n  &.disabled {\n    cursor: not-allowed;\n    * {\n      cursor: inherit !important;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loadingSwitch": `styles_loadingSwitch__uGCKw`,
	"themedSwitch": `styles_themedSwitch__A1b6J`,
	"disabled": `styles_disabled__IjsUg`
};
export default ___CSS_LOADER_EXPORT___;
