// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_modalContent__lEGuC span {
  display: block;
}`, "",{"version":3,"sources":["webpack://./src/js/components/workbench/part-right/repository/info/git-not-pushed/styles.module.scss"],"names":[],"mappings":"AACE;EACE,cAAA;AAAJ","sourcesContent":[".modalContent {\n  span {\n    display: block;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modalContent": `styles_modalContent__lEGuC`
};
export default ___CSS_LOADER_EXPORT___;
