import classNames from 'classnames';
import React, { FC } from 'react';
import { Handle, Position } from 'reactflow';

import Group, { Props } from './Group';

const FlowElementGroup: FC<Props> = (props) => {
  const { data } = props;
  return (
    <div className={classNames('PipelineTuningChart--flow-element-group')}>
      <Handle type={'target'} position={Position.Left} />
      <Group data={data} />
      <Handle type={'source'} position={Position.Right} />
    </div>
  );
};

export default FlowElementGroup;
