// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_noData__vHwkw {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.styles_axisLabel__EMQgY {
  font-size: 11px;
  fill: #888888;
  font-weight: 500;
}

.styles_bar__JA0Iu {
  transition: filter ease-in-out 0.05s;
}
.styles_bar__JA0Iu:hover {
  filter: brightness(0.7);
}

.styles_container__9E7ON {
  width: 100%;
  height: 100%;
}

.styles_dropdown__xtOZy {
  max-width: 200px;
  margin-top: 5px;
  margin-left: 5px;
}`, "",{"version":3,"sources":["webpack://./src/js/components/molecules/augur-layout-elements/report-elements/performance-drift/v1/multi/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,WAAA;EACA,YAAA;AACF;;AAEA;EACE,eAAA;EACA,aAAA;EACA,gBAAA;AACF;;AAEA;EACE,oCAAA;AACF;AACE;EACE,uBAAA;AACJ;;AAGA;EACE,WAAA;EACA,YAAA;AAAF;;AAGA;EACE,gBAAA;EACA,eAAA;EACA,gBAAA;AAAF","sourcesContent":[".noData {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  width: 100%;\n  height: 100%;\n}\n\n.axisLabel {\n  font-size: 11px;\n  fill: #888888;\n  font-weight: 500;\n}\n\n.bar {\n  transition: filter ease-in-out 0.05s;\n\n  &:hover {\n    filter: brightness(0.7);\n  }\n}\n\n.container {\n  width: 100%;\n  height: 100%;\n}\n\n.dropdown {\n  max-width: 200px;\n  margin-top: 5px;\n  margin-left: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"noData": `styles_noData__vHwkw`,
	"axisLabel": `styles_axisLabel__EMQgY`,
	"bar": `styles_bar__JA0Iu`,
	"container": `styles_container__9E7ON`,
	"dropdown": `styles_dropdown__xtOZy`
};
export default ___CSS_LOADER_EXPORT___;
