import type { Node, NodeProps } from '@xyflow/react';
import { useReactFlow } from '@xyflow/react';
import classNames from 'classnames';
import React, {
  ChangeEvent,
  ChangeEventHandler,
  ReactElement,
  useCallback,
} from 'react';

import styles from './styles.module.scss';
import { generateHandles } from './utils';
import { CodeGenContext, generateReturnValues } from '../codegen';
import {
  AS_NODE_TYPES,
  AS_TYPES,
  CustomNodes,
  GenericNodeData,
  StaticConnectionsData,
} from '../types';

export type SupportedTypes = string | number | boolean;

export type InputNodeData<T extends SupportedTypes> = {
  config: {
    type: T extends string
      ? typeof AS_TYPES.STRING
      : T extends number
      ? typeof AS_TYPES.NUMBER
      : T extends boolean
      ? typeof AS_TYPES.BOOL
      : typeof AS_TYPES.ANY;
    value: T;
  };
};

export const generateInputNodeCode = (
  context: CodeGenContext,
  node: InputNode
) => {
  let value: string;
  switch (node.data.config.type) {
    case AS_TYPES.STRING:
      value = `"${node.data.config.value}"`;
      break;
    case AS_TYPES.NUMBER:
      value = `${node.data.config.value}`;
      break;
    case AS_TYPES.BOOL:
      value = `${node.data.config.value ? 'True' : 'False'}`;
      break;
    default:
      console.error(
        // @ts-ignore fixme-fd
        `Unknown input data type! Can't handle ${node.data.config.type}`
      );
      value = 'None';
  }

  const returnVar = generateReturnValues(context, node);
  return [`${returnVar} = ${value}`];
};

function isString(
  data: InputNodeData<SupportedTypes>
): data is InputNodeData<string> {
  return data.config.type === AS_TYPES.STRING;
}

function isNumber(
  data: InputNodeData<SupportedTypes>
): data is InputNodeData<number> {
  return data.config.type === AS_TYPES.NUMBER;
}

function isBoolean(
  data: InputNodeData<SupportedTypes>
): data is InputNodeData<boolean> {
  return data.config.type === AS_TYPES.BOOL;
}

function renderInput(
  data: InputNodeData<SupportedTypes>,
  onChange: ChangeEventHandler<HTMLInputElement>
): ReactElement {
  if (isString(data))
    return (
      <input
        style={{ width: '100%', boxSizing: 'border-box' }}
        type='text'
        value={data.config.value}
        onChange={onChange}
      />
    );
  if (isNumber(data))
    return (
      <input
        style={{ width: '100%', boxSizing: 'border-box' }}
        type='number'
        value={data.config.value}
        onChange={onChange}
      />
    );
  if (isBoolean(data))
    return (
      <input type='checkbox' checked={data.config.value} onChange={onChange} />
    );
  return (
    <span style={{ width: '100%', boxSizing: 'border-box' }}>
      Unsupported type {data.config.type}
    </span>
  );
}

type IntNode = Node<
  GenericNodeData & StaticConnectionsData & InputNodeData<number>,
  typeof AS_NODE_TYPES.INPUT_INT
>;
type StringNode = Node<
  GenericNodeData & StaticConnectionsData & InputNodeData<string>,
  typeof AS_NODE_TYPES.INPUT_STRING
>;
type BoolNode = Node<
  GenericNodeData & StaticConnectionsData & InputNodeData<boolean>,
  typeof AS_NODE_TYPES.INPUT_BOOL
>;

export type InputNode = IntNode | StringNode | BoolNode;

function isInputNode(node: CustomNodes): node is InputNode {
  return (
    node.type === AS_NODE_TYPES.INPUT_INT ||
    node.type === AS_NODE_TYPES.INPUT_STRING ||
    node.type === AS_NODE_TYPES.INPUT_BOOL
  );
}

export function InputNode({ id, data }: NodeProps<InputNode>) {
  const {
    connections: { outputs },
  } = data;
  const { getNodes, setNodes } = useReactFlow();
  const onChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setNodes(
        getNodes().map((n: CustomNodes) => {
          if (isInputNode(n) && n.id === id) {
            return {
              ...n,
              data: {
                ...n.data,
                config: {
                  ...n.data.config,
                  value:
                    data.config.type === 'bool'
                      ? e.target.checked
                      : e.target.value,
                },
              },
            };
          }

          return n;
        })
      );
    },
    [getNodes, setNodes, data.config.type, id]
  );
  return (
    <div
      className={classNames(
        'react-flow__node-default',
        styles.defaultNodeStyle
      )}
      style={data.highlight ? { backgroundColor: 'rgb(222, 222, 222)' } : {}}
    >
      {renderInput(data, onChange)}
      {generateHandles(outputs, 'source')}
    </div>
  );
}
