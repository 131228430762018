import React, { FC, useState } from 'react';
import { useParams } from 'react-router-dom';

import Header from './Header';
import InfluencerBar from './InfluencerBar';
import { OrderByType, SortByType, sortFeatures } from './sorting';
import styles from './styles.module.scss';
import {
  RejectedFeaturesCassandraAugurSettings,
  RejectedFeaturesCassandraConfig,
} from './type';
import { useCassandraTableSamplesForHabitat } from '../../../../../../core/api/data';
import { HabitatRouteParams } from '../../../../../index/routes';
import { AugurSettingsProps } from '../../types/meta';

export type Props = AugurSettingsProps<
  RejectedFeaturesCassandraAugurSettings,
  RejectedFeaturesCassandraConfig
>;

const RejectedFeaturesCassandra: FC<Props> = (props: Props) => {
  const {
    config,
    value = { rejected: [], enforced: [] },
    onChange,
    readOnly,
  } = props;
  const { cassandraKeyspace, cassandraTable } = config;
  const { habitatCode } = useParams<HabitatRouteParams>();

  const [sortBy, setSortBy] = useState<SortByType>('feature');
  const [orderBy, setOrderBy] = useState<OrderByType>('descending');

  const tableSamples = useCassandraTableSamplesForHabitat(
    cassandraKeyspace?.dataSourceCode,
    cassandraKeyspace?.keyspaceName,
    cassandraTable,
    habitatCode
  );

  const colSpecs = tableSamples.data?.colSpecs || [];
  const columns = colSpecs.map((colSpec) => colSpec.colName);

  const processedColumns = columns.sort((c1, c2) =>
    sortFeatures(c1, c2, value, sortBy, orderBy)
  );

  const handleSort = (newSortBy: SortByType, newOrderBy: OrderByType) => {
    setSortBy(newSortBy);
    setOrderBy(newOrderBy);
  };

  return (
    <div className={styles.grid}>
      <Header sort={handleSort} sortBy={sortBy} orderBy={orderBy} />

      {processedColumns.length === 0 && (
        <div className={styles.noFeaturesAvailable}>No features available.</div>
      )}

      {processedColumns.map((column: string) => (
        <InfluencerBar
          key={column}
          colName={column}
          value={value}
          onChange={onChange}
          readOnly={readOnly}
        />
      ))}
    </div>
  );
};

export default RejectedFeaturesCassandra;
