import { DASHBOARD_COMPONENT_SCOPES } from 'common/dist/constants/keycloak';
import React, { FC } from 'react';
import { useParams } from 'react-router-dom';

import { detailsRoute } from './AppPermissions';
import { useAppsNames } from '../../../../core/api/apps';
import Busy from '../../../atoms/busy/Busy';
import ResourcePermissions from '../../../organisms/permissions/ressource-permissions/ResourcePermissions.container';

const SingleAppPermissions: FC = () => {
  const { id } = useParams<{ id: string }>();

  const { data: appNames, isLoading } = useAppsNames();

  if (isLoading) {
    return <Busy isBusy />;
  }

  const appName = appNames[id];

  return (
    <ResourcePermissions
      baseUrl={detailsRoute(id)}
      showBackButton={false}
      resourceType={'app'}
      resourceName={`app:${id}`}
      details={{
        headline: {
          id: 'no-id',
          defaultMessage: `Permissions for App ${appName}`,
        },
        rows: [],
      }}
      scopes={DASHBOARD_COMPONENT_SCOPES}
      messagesGroupPermissions={{
        subTitle: {
          id: 'no-id',
          defaultMessage: `Assign group-wise permissions to the app ${appName}`,
        },
        description: {
          id: 'no-id',
          defaultMessage: `If you are a member of this group you have access to the app ${appName}`,
        },
      }}
      messagesUserPermissions={{
        subTitle: {
          id: 'no-id',
          defaultMessage: `Assign user-wise permissions to the app ${appName}`,
        },
      }}
    />
  );
};

export default SingleAppPermissions;
