import React, { Component } from 'react';
import Measure from 'react-measure';
import { TabLink } from 'react-tabs-redux';

interface LauncherTabLinkElementProps {
  measuredTabWidth(...args: unknown[]): unknown;
  updateOverlappingTabs(...args: unknown[]): unknown;
  paneId: string;
}

export default class LauncherTabLinkElement extends Component<LauncherTabLinkElementProps> {
  render() {
    const { paneId } = this.props;
    return (
      <div key={'launcher-' + paneId}>
        <Measure
          bounds
          onResize={(contentRect) => {
            this.props.measuredTabWidth('launcher', contentRect.bounds.width);
            this.props.updateOverlappingTabs();
          }}
        >
          {({ measureRef }) => (
            <div ref={measureRef}>
              <TabLink
                className={'notebook-tab-link'}
                isActive={true}
                handleSelect={() => {}}
                to={'launcher'}
              >
                <div className={'notebook-link-name'}>Launcher</div>
              </TabLink>
            </div>
          )}
        </Measure>
      </div>
    );
  }
}
