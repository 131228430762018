import {
  contentArrayToPath,
  contentPathToArray,
} from 'common/dist/utils/workbench/content';
import _ from 'lodash';
import {
  error as errorNotification,
  success as successNotification,
} from 'react-notification-system-redux';
import { createAction } from 'redux-act';
import {
  call,
  cancel,
  put,
  select,
  take,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';
import { v4 as uuidv4 } from 'uuid';

import {
  deleteSession,
  deleteSessionFailure,
  deleteSessionSuccess,
  fetchSessions,
  postSession,
} from './sessions.module';
import { addPane, removePane } from './utils/panes';
import {
  DIRECTORY_EXTENSION,
  WORKBENCH_FILENAMES,
} from '../../../components/workbench/part-right/config';
import { RightParts } from '../../../components/workbench/part-right/rightParts';
import { stripInMemoryContent } from '../../../components/workbench/utils';
import * as ContentApi from '../../../core/api/workbench/content';
import { contentKeys } from '../../../core/api/workbench/content';
import * as NotebookApi from '../../../core/api/workbench/notebook';
import { error as errorType } from '../../../core/notifications';
import { JUPYTER_CONTENT_TYPE } from '../../../store/workbench/state.types';
import { initialNotebook } from '../../../workbench/notebooks/initial/initial';
import { sendNotification } from '../../modules/notifications.module';
import { invalidateQueries } from '../../modules/reactQuery.module';
import {
  fetchRepositoryInfoFailed,
  notificationCreateNotebookFail,
  notificationEditNotebookFail,
  notificationEditNotebookSuccess,
  notificationPasteNotebookFail,
  notificationPasteNotebookSuccess,
  notificationSaveNotebookFail,
  notificationSaveNotebookSuccess,
} from '../notifications/notifications';
import { notebookUser } from '../selectors/notebookUser.selector';
import { getSessionByPath } from '../selectors/sessions.selectors';

// This action 'selects' a notebook in the notebook tab view ('opens' its view)
// TODO rename since this also does files
export const selectNotebook = createAction('select notebook', (selected) => ({
  selected,
}));

export const closeNotebook = createAction(
  'close notebook',
  (path, paneId, checkForChanges = true) => ({ path, paneId, checkForChanges })
);

/**
 * Makes sure a notebook is really closed.
 * There are no checks whether there are changes - the notebook is simply closed.
 * No paneId of the pane in which the notebook might be opened is required. So this action has a worse performance than
 * the closeNotebook action. When closing a notebook with the "x"-Button, closeNotebook should be preferred.
 * This method is mostly used to make sure that a notebook that is deleted isn't opened anymore.
 * @type {ComplexActionCreator1<unknown, {path: unknown}, {}>}
 */
export const ensureNotebookIsClosed = createAction(
  'ensure notebook is closed',
  (path) => ({ path })
);

export const hideAddNotebook = createAction('hide add notebook');

export const showPasteNotebook = createAction('show paste notebook');

export const hidePasteNotebook = createAction('hide paste notebook');

export const showEditNotebook = createAction(
  'show edit notebook',
  (name, path) => ({ name, path })
);

export const hideEditNotebook = createAction('hide edit notebook');

export const showWarningOpenFile = createAction(
  'show warning open file',
  (path, type) => ({ path, type })
);

export const hideWarningOpenFile = createAction('hide warning open file');

export const saveEditNotebook = createAction(
  'save edit notebook',
  (notebookName, dirPath, kernelName, kernelDisplayName, notebookOldPath) => ({
    notebookName,
    dirPath,
    kernelName,
    kernelDisplayName,
    notebookOldPath,
  })
);

export const saveEditNotebookSuccess = createAction(
  'save edit notebook - success',
  (success) => success
);

export const saveEditNotebookFail = createAction(
  'save edit notebook - fail',
  (error) => error
);

export const addNotebook = createAction(
  'add notebook',
  (notebookName, dirPath, kernelName, kernelDisplayName) => ({
    notebookName,
    dirPath,
    kernelName,
    kernelDisplayName,
  })
);

export const addNotebookSuccess = createAction(
  'add notebook - success',
  (success) => success
);

export const addNotebookRequest = createAction('add notebook - request');

export const addNotebookFail = createAction(
  'add notebook - fail',
  (error) => error
);

export const hideCloseConfirm = createAction(
  'close confirm - hide',
  (path) => path
);

export const fetchNotebook = createAction('fetch notebook', (path, paneId) => ({
  path,
  paneId,
}));

// TODO rename since this also does files
export const fetchNotebookSuccess = createAction(
  'fetch notebook - success',
  (content, paneId) => ({ content, paneId })
);

export const fetchNotebookFail = createAction(
  'fetch notebook - fail',
  (error, paneId, notebookPath) => ({ error, paneId, notebookPath })
);

/** Should only be used for already open notebooks (for example makes the assumption that a session already exists) */
export const fetchNotebookUpdate = createAction(
  'fetch notebook update',
  (path, paneId) => ({ path, paneId })
);

export const fetchNotebookUpdateSuccess = createAction(
  'fetch notebook update - success',
  (content, paneId) => ({ content, paneId })
);

export const saveNotebookByPath = createAction(
  'save notebook by path',
  (path) => ({ path })
);

// TODO rename since this also does files
export const saveNotebook = createAction(
  'save notebook',
  (path, content, type) => ({ path, content, type })
);

export const saveNotebookSuccess = createAction(
  'save notebook - success',
  (notebook) => notebook
);

export const saveNotebookFail = createAction(
  'save notebook - fail',
  (error) => error
);

export const selectCells = createAction(
  'select cells',
  (path, cellsToSelect) => ({ path, cellsToSelect })
);

/**
 * Marks a notebook cell as executing.
 * ATTENTION: executingCell should actually be called executingCellId! It's only the ID of the cell that is supposed
 * to be marked as executing.
 * @type {ComplexActionCreator2<unknown, unknown, {path: unknown, executingCell: unknown}, {}>}
 */
export const markCellAsExecuting = createAction(
  'mark cell as executing',
  (path, executingCell) => ({ path, executingCell })
);

export const changeCellType = createAction(
  'change cell type',
  (path, cellIds, newType) => ({ path, cellIds, newType })
);

export const addCell = createAction('add cell', (path, index) => ({
  path,
  index,
}));

export const findAndAdjustOpenedNotebookPath = createAction(
  'find and adjust opened notebook path',
  (oldPath, newPath) => ({ oldPath, newPath })
);

export const clearAllOutputs = createAction(
  'clear all outputs',
  (path) => path
);

export const markAllCellsAsNotExecuting = createAction(
  'mark all cells as not executing',
  (path) => path
);

export const copyCellsToClipboard = createAction(
  'copy cell to clipboard',
  (cellArray) => cellArray
);

export const deleteCells = createAction('delete cells', (path, cellIds) => ({
  path,
  cellIds,
}));

export const pasteCellsFromClipboard = createAction(
  'paste cells from clipboard',
  (path, cells, index) => ({ path, cells, index })
);

export const copyNotebookToClipboard = createAction(
  'copy notebook to clipboard',
  (path) => path
);

export const pasteNotebookFromClipboard = createAction(
  'paste notebook from clipboard',
  (notebookName, dirPath) => ({
    notebookName,
    dirPath,
  })
);

export const pasteNotebookSuccess = createAction(
  'paste notebook from clipboard - success',
  (notebook) => notebook
);

export const pasteNotebookFail = createAction(
  'paste notebook from clipboard - fail',
  (error) => error
);

export const measuredTabWidth = createAction(
  'measured notebook tab width',
  (path, width) => ({ path, width })
);

export const moveCellsUp = createAction(
  'move cells up',
  (path, selectedCells, selectedCellIndex) => ({
    path,
    selectedCells,
    selectedCellIndex,
  })
);

export const moveCellsDown = createAction(
  'move cells down',
  (path, selectedCells, selectedCellIndex) => ({
    path,
    selectedCells,
    selectedCellIndex,
  })
);

export const setFullscreen = createAction('set fullscreen', (fullscreen) => ({
  fullscreen,
}));

export const fetchNotebookParentRepository = createAction(
  'fetch notebook parent repository',
  (path) => ({ path })
);

export const fetchNotebookParentRepositorySuccess = createAction(
  'fetch notebook parent repository - success',
  (path, parentRepository) => ({ path, parentRepository })
);

export const fetchNotebookParentRepositoryFail = createAction(
  'fetch notebook parent repository - fail',
  (path, error) => ({ path, error })
);

export const loadRepoMeta = createAction('load repo meta', (path) => ({
  path,
}));

export const loadRepoMetaSuccess = createAction(
  'load repo meta - success',
  (repoMeta, path) => ({ repoMeta, path })
);

export const moveNotebookToAnotherPane = createAction(
  'move notebook to another pane',
  (path, sourcePaneId, targetPaneId) => ({ path, sourcePaneId, targetPaneId })
);

export const splitPane = createAction(
  'split editor pane',
  (name, path, type, sourcePaneId, parentPaneId, split, position) => ({
    name,
    path,
    type,
    sourcePaneId,
    parentPaneId,
    split,
    position,
  })
);

export const resizeSplitPane = createAction(
  'resize editor split pane',
  (paneId, newMeasures) => ({ paneId, newMeasures })
);

export const setNotebookTabDragging = createAction(
  'set notebook tab dragging',
  (isDragging) => ({ isDragging })
);

export const reducer = {
  [selectNotebook](state, { selected }) {
    // Don't do anything if the notebook to select isn't loaded
    if (!state.notebooks[selected]) return state;

    // Find the pane this notebook is loaded in
    const pane = Object.values(state.panes).find((pane) =>
      (pane.content || []).map((c) => c.path).includes(selected)
    );
    if (!pane) return state; // Should never happen, just to be sure

    return {
      ...state,
      panes: {
        ...state.panes,
        [pane.id]: {
          ...(state.panes[pane.id] || {}),
          selectedContent: selected,
        },
      },
    };
  },
  [ensureNotebookIsClosed](state, { path }) {
    const filteredNotebooks = _.omit(state.notebooks, path);

    let filteredPanes = {};
    Object.keys(state.panes || {}).forEach((paneKey) => {
      const pane = state.panes[paneKey];

      const filteredContent = (pane.content || []).filter(
        (c) => c.path !== path
      );
      if (filteredContent.length > 0) {
        // Only add the pane if it still has content
        filteredPanes[paneKey] = {
          ...pane,
          content: filteredContent,
          selectedContent:
            pane.selectedContent === path
              ? filteredContent[0].path
              : pane.selectedContent,
        };
      }
    });

    // If there would be no pane left - add one pane with the launcher
    if (Object.keys(filteredPanes).length === 0) {
      filteredPanes = {
        e1: {
          id: 'e1',
          type: 'editor',
          content: [
            {
              type: 'launcher',
              path: 'launcher',
            },
          ],
          selectedContent: 'launcher',
          loadingContent: false,
        },
      };
    }

    return {
      ...state,
      notebooks: filteredNotebooks,
      panes: filteredPanes,
    };
  },
  [closeNotebook](state, { path, paneId, checkForChanges }) {
    // Possible early exit: If there should be a check for changes in the notebook (instead of a simply "force" close)
    // AND there are changes in the notebook - show the close confirm instead of closing the notebook.
    if (checkForChanges && (state.notebooks[path] || {}).unsavedChanges) {
      // -> Check AND There are changes in the notebook -> Show the close confirm
      return {
        ...state,
        notebooks: {
          ...state.notebooks,
          [path]: {
            ...(state.notebooks[path] || {}),
            showCloseConfirm: true,
          },
        },
      };
    }

    const filteredNotebooks = _.omit(state.notebooks, path);
    let filteredPaneContent = (
      (state.panes[paneId] || {}).content || []
    ).filter((c) => c.path !== path);

    const selectedContent =
      (state.panes[paneId] || {}).selectedContent !== path // If the selected notebook was closed select the first open notebook
        ? (state.panes[paneId] || {}).selectedContent
        : filteredPaneContent.length > 0
        ? filteredPaneContent[filteredPaneContent.length - 1].path
        : 'launcher';

    // Check if it was the last notebook of the pane
    if (filteredPaneContent.length === 0) {
      // It was the last notebook of the pane ...
      if (Object.keys(state.panes).length <= 1) {
        // ... if it is also the last pane - show the Launcher
        filteredPaneContent = [{ type: 'launcher', path: 'launcher' }];
      } else {
        // ... if it's not the last pane - remove it.
        const { panes, paneHierarchy } = removePane(state, paneId);
        return {
          ...state,
          notebooks: filteredNotebooks,
          mostRecentPaneId:
            Object.keys(panes).length > 0 ? Object.keys(panes)[0] : '', // Object.keys(panes.length) === must never happen actually!
          panes,
          paneHierarchy,
        };
      }
    }

    return {
      ...state,
      notebooks: filteredNotebooks,
      panes: {
        ...state.panes,
        [paneId]: {
          ...(state.panes[paneId] || {}),
          content: filteredPaneContent,
          selectedContent,
        },
      },
    };
  },
  [hideCloseConfirm]: (state, path) => ({
    ...state,
    notebooks: {
      ...state.notebooks,
      [path]: {
        ...(state.notebooks[path] || {}),
        showCloseConfirm: false,
      },
    },
  }),
  [hideAddNotebook]: (state, path) => ({
    // TODO Should be removed since routing is used now
    ...state,
    showOnRight: RightParts.EDITOR,
  }),
  [showPasteNotebook]: (state, path) => ({
    // TODO Should be removed since routing is used now
    ...state,
    showOnRight: RightParts.PASTE_NOTEBOOK,
  }),
  [hidePasteNotebook]: (state, path) => ({
    // TODO Should be removed since routing is used now
    ...state,
    showOnRight: RightParts.EDITOR,
  }),
  [showEditNotebook]: (state, { name, path }) => ({
    ...state,
    showEditNotebook: {
      name,
      path,
    },
    showOnRight: RightParts.EDIT_NOTEBOOK,
  }),
  [hideEditNotebook]: (state) => ({
    ...state,
    showOnRight: RightParts.EDITOR,
    showEditNotebook: undefined,
  }),
  [showWarningOpenFile]: (state, { path, type }) => ({
    ...state,
    showWarningOpenFile: {
      path,
      type,
    },
  }),
  [hideWarningOpenFile]: (state) => ({
    ...state,
    showWarningOpenFile: undefined,
  }),
  [fetchNotebook]: (state, { path, paneId }) => ({
    ...state,
    panes: {
      ...state.panes,
      [paneId]: {
        ...(state.panes[paneId] || {}),
        loadingContent: true,
      },
    },
    loadingNotebooks: [...state.loadingNotebooks, path],
  }),
  [fetchNotebookSuccess]: (state, { content, paneId }) => ({
    ...state,
    notebooks: {
      ...state.notebooks,
      [content.path]: {
        ...content,
        selectedCells: [], // Don't select any cell when opening the notebook
      },
    },
    panes: {
      ...state.panes,
      [paneId]: {
        ...(state.panes[paneId] || {}),
        content: [
          // Make sure the launcher is removed when a notebook is opened (this is actually only important for when the first notebook is opened
          ...((state.panes[paneId] || {}).content || []).filter(
            (c) => c.type !== 'launcher'
          ),
          {
            name: content.name,
            path: content.path,
            type: content.type,
          },
        ],
        loadingContent: false,
      },
    },
    loadingNotebooks: state.loadingNotebooks.filter(
      (nb) => nb !== content.path
    ),
  }),
  [fetchNotebookUpdate]: (state, { path, paneId }) => ({
    ...state,
    panes: {
      ...state.panes,
      [paneId]: {
        ...(state.panes[paneId] || {}),
        loadingContent: true,
      },
    },
    loadingNotebooks: [...state.loadingNotebooks, path],
  }),
  [fetchNotebookUpdateSuccess]: (state, { content, paneId }) => ({
    ...state,
    notebooks: {
      ...state.notebooks,
      [content.path]: {
        // Leave other parts of the state intact, sessions for example, but reset the unsavedChanges state
        ...(state.notebooks[content.path] || {}),
        unsavedChanges: false,
        ...content,
      },
    },
    panes: {
      ...state.panes,
      [paneId]: {
        ...(state.panes[paneId] || {}),
        content: [
          // Change only the updated content (and do nothing if it is missing?)
          ...(state.panes[paneId]?.content || []).map((contentOld) =>
            contentOld.path === content.path
              ? {
                  name: content.name,
                  path: content.path,
                  type: content.type,
                }
              : contentOld
          ),
        ],
        loadingContent: false,
      },
    },
    loadingNotebooks: state.loadingNotebooks.filter(
      (nb) => nb !== content.path
    ),
  }),
  [fetchNotebookFail]: (state, { error, notebookPath, paneId }) => ({
    ...state,
    panes: {
      ...state.panes,
      [paneId]: {
        ...(state.panes[paneId] || {}),
        loadingContent: false,
      },
    },
    loadingNotebooks: state.loadingNotebooks.filter(
      (nb) => nb !== notebookPath
    ),
  }),
  [saveNotebookSuccess]: (state, notebook) => ({
    ...state,
    notebooks: {
      ...state.notebooks,
      [notebook.path]: {
        ...(state.notebooks[notebook.path] || {}),
        unsavedChanges: false,
      },
    },
  }),
  [saveNotebookFail]: (state) => ({
    ...state,
  }),
  [selectCells]: (state, { path, cellsToSelect }) => ({
    ...state,
    notebooks: {
      ...state.notebooks,
      [path]: {
        ...(state.notebooks[path] || {}),
        selectedCells: cellsToSelect,
      },
    },
  }),
  [markCellAsExecuting]: (state, { path, executingCell }) => ({
    ...state,
    notebooks: {
      ...state.notebooks,
      [path]: {
        ...(state.notebooks[path] || {}),
        content: {
          ...(state.notebooks[path] || {}).content,
          cells: (state.notebooks[path] || {}).content.cells.map((c) =>
            c.id === executingCell
              ? {
                  ...c,
                  execution_count: null, // Will be overwritten soon by the "execute_input" message anyway, but seems to be the standard behavior
                  // This is a non-standard field and should not be saved
                  executing: true,
                }
              : c
          ),
        },
      },
    },
  }),
  [changeCellType]: (state, { path, cellIds, newType }) => ({
    ...state,
    notebooks: {
      ...state.notebooks,
      [path]: {
        ...(state.notebooks[path] || {}),
        // This is only checked by Cells on mount and thus only affects newly created cells or where the type changed!
        forceFocus: cellIds[0],
        content: {
          ...(state.notebooks[path] || {}).content,
          cells: (state.notebooks[path] || {}).content.cells.map((c) =>
            cellIds.includes(c.id) ? { ...c, cell_type: newType } : { ...c }
          ),
        },
      },
    },
  }),
  [addCell](state, { path, index }) {
    const cellId = uuidv4();
    return {
      ...state,
      notebooks: {
        ...state.notebooks,
        [path]: {
          ...(state.notebooks[path] || {}),
          // This is only checked by Cells on mount and thus only affects newly created cells or where the type changed!
          forceFocus: cellId,
          content: {
            ...(state.notebooks[path] || {}).content,
            cells: [
              ...(state.notebooks[path] || {}).content.cells.slice(0, index),
              {
                cell_type: 'code',
                id: cellId,
                outputs: [],
                source: '',
                metadata: {
                  trusted: true,
                },
              },
              ...(state.notebooks[path] || {}).content.cells.slice(index),
            ],
          },
          selectedCells: [cellId],
        },
      },
    };
  },
  [findAndAdjustOpenedNotebookPath](state, { oldPath, newPath }) {
    return {
      ...state,
      notebooks: {
        ...state.notebooks,
        [oldPath]: {
          ...(state.notebooks[oldPath] || {}),
          path: newPath,
        },
      },
      selectedNotebook:
        state.selectedNotebook === oldPath ? newPath : state.selectedNotebook, // TODO Adjust to the new state management
    };
  },
  [clearAllOutputs]: (state, path) => ({
    ...state,
    notebooks: {
      ...state.notebooks,
      [path]: {
        ...(state.notebooks[path] || {}),
        content: {
          ...(state.notebooks[path] || {}).content,
          cells: (state.notebooks[path] || {}).content.cells.map((c) => ({
            ...c,
            outputs: [],
            execution_count: undefined,
            as_elements: (c.as_elements || []).map((e) => ({
              ...e,
              data: {
                ...e?.data,
                outputs: [],
              },
            })),
          })),
        },
      },
    },
  }),
  [markAllCellsAsNotExecuting]: (state, path) => ({
    ...state,
    notebooks: {
      ...state.notebooks,
      [path]: {
        ...(state.notebooks[path] || {}),
        content: {
          ...(state.notebooks[path] || {}).content,
          cells: (state.notebooks[path] || {}).content.cells.map((c) => ({
            ...c,
            executing: false,
          })),
        },
      },
    },
  }),
  [copyCellsToClipboard]: (state, cellArray) => ({
    ...state,
    clipboard: {
      type: 'cells',
      data: cellArray,
    },
  }),
  [copyNotebookToClipboard]: (state, path) => ({
    ...state,
    clipboard: {
      type: 'notebook',
      data: path,
    },
  }),
  [deleteCells](state, { path, cellIds }) {
    if (!cellIds) return { ...state };
    return {
      ...state,
      notebooks: {
        ...state.notebooks,
        [path]: {
          ...(state.notebooks[path] || {}),
          content: {
            ...(state.notebooks[path] || {}).content,
            cells: (state.notebooks[path] || {}).content.cells.filter(
              (c) => !cellIds.includes(c.id)
            ),
          },
        },
      },
    };
  },
  [pasteCellsFromClipboard]: (state, { path, cells, index }) => ({
    ...state,
    notebooks: {
      ...state.notebooks,
      [path]: {
        ...(state.notebooks[path] || {}),
        content: {
          ...(state.notebooks[path] || {}).content,
          cells: (state.notebooks[path] || {}).content.cells
            .slice(0, index)
            .concat(cells.map((c) => ({ ...c, id: uuidv4() })))
            .concat((state.notebooks[path] || {}).content.cells.slice(index)),
        },
      },
    },
  }),
  [measuredTabWidth]: (state, { path, width }) => ({
    ...state,
    notebooks: {
      ...state.notebooks,
      [path]: {
        ...(state.notebooks[path] || {}),
        tabWidth: width,
      },
    },
  }),
  [moveCellsUp](state, { path, selectedCells, selectedCellIndex }) {
    const nb = state.notebooks[path];
    const shiftCount = selectedCells.length;
    const newIndex =
      selectedCellIndex === 0 ? 0 : selectedCellIndex - shiftCount;
    const selected = nb.content.cells.filter((c) =>
      selectedCells.includes(c.id)
    );
    const cellsReordered = nb.content.cells
      .slice(0, newIndex)
      .filter((c) => !selectedCells.includes(c.id))
      .concat(selected)
      .concat(
        nb.content.cells
          .slice(newIndex)
          .filter((c) => !selectedCells.includes(c.id))
      );

    return {
      ...state,
      notebooks: {
        ...state.notebooks,
        [path]: {
          ...(state.notebooks[path] || {}),
          content: {
            ...nb.content,
            cells: cellsReordered,
          },
        },
      },
    };
  },
  [moveCellsDown](state, { path, selectedCells, selectedCellIndex }) {
    const nb = state.notebooks[path];
    const shiftCount = selectedCells.length + 1;
    const newIndex =
      selectedCellIndex >= nb.content.cells.length - 1
        ? nb.content.cells.length
        : selectedCellIndex + shiftCount;
    const selected = nb.content.cells.filter((c) =>
      selectedCells.includes(c.id)
    );

    const cellsReordered = nb.content.cells
      .slice(0, newIndex)
      .filter((c) => !selectedCells.includes(c.id))
      .concat(selected)
      .concat(
        nb.content.cells
          .slice(newIndex)
          .filter((c) => !selectedCells.includes(c.id))
      );

    return {
      ...state,
      notebooks: {
        ...state.notebooks,
        [path]: {
          ...(state.notebooks[path] || {}),
          content: {
            ...nb.content,
            cells: cellsReordered,
          },
        },
      },
    };
  },
  [setFullscreen]: (state, { fullscreen }) => ({
    ...state,
    fullscreen,
  }),
  [addNotebookRequest]: (state) => ({
    ...state,
    isCreatingNoteBook: true,
  }),
  [addNotebookSuccess]: (state) => ({
    ...state,
    isCreatingNoteBook: false,
  }),
  [addNotebookFail]: (state) => ({
    ...state,
    isCreatingNoteBook: false,
  }),
  [fetchNotebookParentRepositorySuccess](state, { path, parentRepository }) {
    return {
      ...state,
      notebooks: {
        ...state.notebooks,
        [path]: {
          ...(state.notebooks[path] || {}),
          as_parentRepository: parentRepository,
        },
      },
    };
  },
  [loadRepoMetaSuccess]: (state, { repoMeta, path }) => ({
    ...state,
    // The currently selected repoMeta in the currently selected path, will be overwritten when loading a new repoMeta
    content: {
      ...state.content,
      repoMeta,
    },
    // All seen repoMetas indexed by their location
    repoMetas: {
      ...state.repoMetas,
      [path]: repoMeta,
    },
  }),
  [moveNotebookToAnotherPane]: (
    state,
    { path, sourcePaneId, targetPaneId }
  ) => {
    // If source = target, nothing to do, return the original state
    if (sourcePaneId === targetPaneId) return state;

    const contentElement = (
      (state.panes[sourcePaneId] || {}).content || []
    ).find((c) => c.path === path);

    const filteredSourceContent = (
      (state.panes[sourcePaneId] || {}).content || []
    ).filter((c) => c.path !== path); // Take out the tab from the source
    const sourceSelectedContent =
      (state.panes[sourcePaneId] || {}).selectedContent !== path // If the selected notebook was closed select the first open notebook
        ? (state.panes[sourcePaneId] || {}).selectedContent
        : filteredSourceContent.length > 0
        ? filteredSourceContent[filteredSourceContent.length - 1].path
        : '';

    if (filteredSourceContent.length === 0) {
      // It was the last notebook of the source pane - remove that pane! (there must be at least one other pane - the target pane, so simply removing the source pane is fine)
      const { panes, paneHierarchy } = removePane(state, sourcePaneId);
      return {
        ...state,
        paneHierarchy,
        mostRecentPaneId: targetPaneId,
        panes: {
          ...panes,
          [targetPaneId]: {
            ...(panes[targetPaneId] || {}),
            content: [
              ...((panes[targetPaneId] || {}).content || []),
              contentElement, // And add the tab to the target pane
            ],
            selectedContent: path,
          },
        },
      };
    }

    return {
      ...state,
      mostRecentPaneId: targetPaneId,
      panes: {
        ...state.panes,
        [sourcePaneId]: {
          ...(state.panes[sourcePaneId] || {}),
          content: filteredSourceContent,
          selectedContent: sourceSelectedContent,
        },
        [targetPaneId]: {
          ...(state.panes[targetPaneId] || {}),
          content: [
            ...((state.panes[targetPaneId] || {}).content || []),
            contentElement, // And add the tab to the target pane
          ],
          selectedContent: path,
        },
      },
    };
  },
  [splitPane]: (
    state,
    { name, path, type, sourcePaneId, parentPaneId, split, position }
  ) => {
    // Calculate the new panes
    const { panes, paneHierarchy, newPaneId } = addPane(
      state,
      parentPaneId,
      split,
      position,
      sourcePaneId,
      name,
      path,
      type
    );

    return {
      ...state,
      panes,
      paneHierarchy,
      mostRecentPaneId: newPaneId,
    };
  },
  [resizeSplitPane]: (state, { paneId, newMeasures }) => ({
    ...state,
    panes: {
      ...state.panes,
      [paneId]: {
        ...(state.panes[paneId] || {}),
        measures: newMeasures,
      },
    },
  }),
  [setNotebookTabDragging]: (state, { isDragging }) => ({
    ...state,
    isNotebookTabDragging: isDragging,
  }),
};

function addIdsToNotebookCells(content) {
  if (
    content &&
    content.name &&
    (content.name.endsWith('.ipynb') || content.name.endsWith('.asapp'))
  ) {
    const enrichedCells = content.content.cells.map((c) =>
      c.id ? { ...c } : { ...c, id: uuidv4() }
    );
    return {
      ...content,
      content: {
        ...content.content,
        cells: enrichedCells,
      },
    };
  } else {
    // No notebook - nothing to do
    return content;
  }
}

/**
 * TODO rename this also handles plain files like .txt
 */
export function* fetchNotebookSaga({ payload: { path, paneId } }) {
  const jupyterUser = yield select((state) => notebookUser(state));
  const { response, error } = yield call(
    ContentApi.fetchContent,
    jupyterUser,
    path
  );
  if (response) {
    const content = addIdsToNotebookCells(response); // This function checks whether it's a notebook - and if yes, it adds IDs to the cells
    yield put(fetchNotebookSuccess(content, paneId));
    yield put(selectNotebook(content.path));

    const kernelName = content.name.endsWith('.asflow')
      ? 'python310'
      : content.content.metadata?.kernelspec?.name;
    yield put(postSession(content.name, content.path, kernelName, jupyterUser));
    yield put(fetchSessions());
    yield put(fetchNotebookParentRepository(path)); // Fetch the parent repository information for the notebook (whether the notebook belongs to a repository - and if yes get the information about the repository)
  } else {
    yield put(fetchNotebookFail(error, paneId, path));
    yield put(
      sendNotification(
        'Failed to fetch notebook',
        JSON.stringify(error),
        errorType
      )
    );
  }
}

export function* watchFetchNotebook() {
  yield takeEvery(fetchNotebook.getType(), fetchNotebookSaga);
}

/**
 * Fetch Notebook and only update the content. Used in context of updating already open notebooks, potentially multiple.
 * @param path
 * @param paneId
 * @return {Generator<SimpleEffect<"CALL", CallEffectDescriptor<function(*, *): * extends ((...args: any[]) => SagaIterator<infer RT>) ? RT : (function(*, *): * extends ((...args: any[]) => Promise<infer RT>) ? RT : (function(*, *): * extends ((...args: any[]) => infer RT) ? RT : never))>>|SimpleEffect<"PUT", PutEffectDescriptor<Action<{notebookPath: unknown, paneId: unknown, error: unknown}, {}>>>|SimpleEffect<"PUT", PutEffectDescriptor<Action<{paneId: unknown, content: unknown}, {}>>>|SimpleEffect<"SELECT", SelectEffectDescriptor>, void, *>}
 */
export function* fetchNotebookUpdateSaga({ payload: { path, paneId } }) {
  const jupyterUser = yield select((state) => notebookUser(state));
  const { response, error } = yield call(
    ContentApi.fetchContent,
    jupyterUser,
    path
  );
  if (response) {
    const content = addIdsToNotebookCells(response); // This function checks whether it's a notebook - and if yes, it adds IDs to the cells
    yield put(fetchNotebookUpdateSuccess(content, paneId));
  } else {
    yield put(fetchNotebookFail(error, paneId, path));
  }
}

export function* watchFetchNotebookUpdate() {
  yield takeEvery(fetchNotebookUpdate.getType(), fetchNotebookUpdateSaga);
}

/**
 *
 * @param path
 * @param content
 * @param type
 * @returns {Generator<<"CALL", CallEffectDescriptor>|<"SELECT", SelectEffectDescriptor>|<"PUT", PutEffectDescriptor<Action<unknown, {}>>>|<"PUT", PutEffectDescriptor<*>>, void, ?>}
 */
export function* saveNotebookSaga({ payload: { path, content, type } }) {
  const jupyterUser = yield select((state) => notebookUser(state));

  const { response, error } =
    type === JUPYTER_CONTENT_TYPE.FILE
      ? yield call(NotebookApi.saveFile, path, content, jupyterUser)
      : yield call(
          NotebookApi.saveNotebook,
          path,
          stripInMemoryContent(content),
          jupyterUser
        );
  if (response) {
    yield put(saveNotebookSuccess(response));
    yield put(successNotification(notificationSaveNotebookSuccess(path)));
  } else {
    yield put(saveNotebookFail(error));
    yield put(errorNotification(notificationSaveNotebookFail(path)));
  }
}

export function* watchSaveNotebook() {
  yield takeEvery(saveNotebook.getType(), saveNotebookSaga);
}

/**
 * Wrapper for the 'saveNotebookSaga' that allows to save a notebook only by its path
 * @param path
 * @returns {Generator<<"PUT", PutEffectDescriptor<Action<{path: unknown, type: unknown, content: unknown}, {}>>>|<"SELECT", SelectEffectDescriptor>|<"PUT", PutEffectDescriptor<*>>, void, *>}
 */
export function* saveNotebookByPathSaga({ payload: { path } }) {
  const notebook = yield select((state) => state.workbench.notebooks[path]);
  if (!notebook) {
    yield put(errorNotification(notificationSaveNotebookFail(path)));
  }
  yield put(saveNotebook(path, notebook.content, notebook.type));
}

export function* watchSaveNotebookByPath() {
  yield takeEvery(saveNotebookByPath.getType(), saveNotebookByPathSaga);
}

/**
 *
 * @param notebookName
 * @param kernelName
 * @param kernelDisplayName
 * @param altaSigmaMeta
 * @returns ?
 */
export function* addNotebookSaga({
  payload: { notebookName, dirPath, kernelName, kernelDisplayName },
}) {
  const notebookNameSafe =
    notebookName.endsWith('.ipynb') || notebookName.endsWith('.asapp')
      ? notebookName
      : `${notebookName}.ipynb`;

  // api expects a trailing slash here
  const fixedDirPath = `${dirPath}${dirPath.endsWith('/') ? '' : '/'}`;

  yield put(addNotebookRequest());
  const { response, error } = yield call(
    addNotebookCalls,
    notebookNameSafe,
    fixedDirPath,
    kernelName,
    kernelDisplayName
  );
  if (response) {
    const path = response.path;
    yield put(addNotebookSuccess(response));
    yield put(invalidateQueries(contentKeys.all()));
    yield put(hideAddNotebook());
    const mostRecentPaneId = yield select(
      (state) => state.workbench.mostRecentPaneId
    );
    yield put(fetchNotebook(path, mostRecentPaneId));
  } else {
    yield put(addNotebookFail(error));
    yield put(hideAddNotebook());
    yield put(errorNotification(notificationCreateNotebookFail()));
  }
}

export function* watchAddNotebook() {
  yield takeLatest(addNotebook.getType(), addNotebookSaga);
}

/**
 * The chain of function calls required to add a new notebook
 *
 * @param notebookName
 * @param notebookPath
 * @param kernelName
 * @param kernelDisplayName
 * @param initialContent
 * @returns {Generator<<"SELECT", SelectEffectDescriptor>|*, *, Generator<<"SELECT", SelectEffectDescriptor>|*, *, ?>>}
 */
export function* addNotebookCalls(
  notebookName,
  notebookPath,
  kernelName,
  kernelDisplayName,
  initialContent
) {
  const jupyterUser = yield select((state) => notebookUser(state));

  return yield NotebookApi.createFile(jupyterUser).then((response, error) => {
    if (response) {
      const name = response.response.name; // This is strange ...
      const newPathRaw = notebookPath + notebookName;
      const newPath = newPathRaw.startsWith('/')
        ? newPathRaw.slice(1)
        : newPathRaw;
      return ContentApi.renameContent(name, newPath, jupyterUser).then(
        (response, error) => {
          if (response) {
            // If passed, use the initialNotebook. Otherwise pick the standard.
            const initial =
              initialContent || initialNotebook(kernelName, kernelDisplayName);
            return NotebookApi.initialSaveFile(newPath, initial, jupyterUser);
          }
          return { response: null, error };
        }
      );
    }
    return { response: null, error };
  });
}

export function* pasteNotebookSaga({ payload: { notebookName, dirPath } }) {
  const oldPath = yield select((state) => {
    const clipboard = state.workbench.clipboard;
    if (!clipboard.data) return null;
    return clipboard.data.path;
  });
  if (!oldPath) yield cancel(); // Exit if the old path couldn't be selected

  const notebookNameSafe =
    notebookName.endsWith('.ipynb') || notebookName.endsWith('.asapp')
      ? notebookName
      : `${notebookName}.ipynb`;

  // api expects a trailing slash here
  const fixedDirPath = `${dirPath}${dirPath.endsWith('/') ? '' : '/'}`;

  const { response, error } = yield call(
    pasteNotebookCalls,
    oldPath,
    fixedDirPath,
    notebookNameSafe
  );
  if (response) {
    yield put(pasteNotebookSuccess(response));
    yield put(invalidateQueries(contentKeys.all()));
    yield put(hidePasteNotebook());
    yield put(
      successNotification(notificationPasteNotebookSuccess(notebookName))
    );
  } else {
    yield put(pasteNotebookFail(error));
    yield put(hidePasteNotebook());
    yield put(errorNotification(notificationPasteNotebookFail()));
  }
}

export function* watchPasteNotebook() {
  yield takeEvery(pasteNotebookFromClipboard.getType(), pasteNotebookSaga);
}

function* pasteNotebookCalls(oldPath, newNotebookPath, newName) {
  const jupyterUser = yield select((state) => notebookUser(state));
  return yield NotebookApi.copyNotebook(oldPath, jupyterUser).then(
    (response, error) => {
      if (response) {
        const name = response.response.name; // This is strange ...
        const newPathRaw = newNotebookPath + newName;
        const newPath = newPathRaw.startsWith('/')
          ? newPathRaw.slice(1)
          : newPathRaw;
        return ContentApi.renameContent(name, newPath, jupyterUser);
      }
      return { response: null, error };
    }
  );
}

export function* saveEditNotebookSaga({
  payload: {
    notebookName,
    dirPath,
    kernelName,
    kernelDisplayName,
    notebookOldPath,
  },
}) {
  /*
  const oldPath = yield select(
    (state) => state.workbench.showEditNotebook?.path
  );
   */
  if (!notebookOldPath) {
    console.log('Could not select old path, cancelling.');
    yield cancel(); // Exit if the old path couldn't be selected
  }

  const sessionOpt = yield select((state) =>
    getSessionByPath(state, state.workbench.showEditNotebook?.path)
  );

  const notebookNameSafe =
    notebookName.endsWith('.ipynb') || notebookName.endsWith('.asapp')
      ? notebookName
      : `${notebookName}.ipynb`;
  const fixedDirPath = `${dirPath}${dirPath.endsWith('/') ? '' : '/'}`;

  // Select the notebook if it is already opened
  const openNb = yield select(
    (state) => state.workbench.notebooks[notebookOldPath]
  );

  const { response, error } = yield call(
    saveEditNotebookCalls,
    notebookOldPath,
    notebookNameSafe,
    fixedDirPath,
    kernelName,
    kernelDisplayName,
    openNb
  );
  if (response) {
    if (sessionOpt && sessionOpt.kernel.name !== kernelName) {
      yield put(deleteSession(sessionOpt.id, sessionOpt.name));
      // Wait until the session is deleted, so that starting a new session when reopening works correctly.
      while (true) {
        const action = yield take([
          deleteSessionSuccess.getType(),
          deleteSessionFailure.getType(),
        ]);
        if (action.payload.id === sessionOpt.id) break;
      }
    }
    yield put(saveEditNotebookSuccess(response));
    yield put(invalidateQueries(contentKeys.all()));
    yield put(hideEditNotebook());
    if (openNb) {
      // Edited Notebook is open. Close it for one to get a new session if necessary.
      const mostRecentPaneId = yield select(
        (state) => state.workbench.mostRecentPaneId
      );
      yield put(closeNotebook(notebookOldPath, mostRecentPaneId, false));
      const newPathRaw = fixedDirPath + notebookNameSafe;
      const newPath = newPathRaw.startsWith('/')
        ? newPathRaw.slice(1)
        : newPathRaw;
      yield put(fetchNotebook(newPath, mostRecentPaneId));
    }
    yield put(
      successNotification(notificationEditNotebookSuccess(notebookNameSafe))
    );
  } else {
    yield put(saveEditNotebookFail(error));
    yield put(hideEditNotebook());
    yield put(errorNotification(notificationEditNotebookFail()));
  }
}

export function* watchSaveEditNotebook() {
  yield takeEvery(saveEditNotebook.getType(), saveEditNotebookSaga);
}

function* saveEditNotebookCalls(
  oldPath,
  notebookName,
  notebookPath,
  kernelName,
  kernelDisplayName,
  openNb
) {
  const jupyterUser = yield select((state) => notebookUser(state));
  const newPathRaw = notebookPath + notebookName;
  const newPath = newPathRaw.startsWith('/') ? newPathRaw.slice(1) : newPathRaw;
  return yield ContentApi.renameContent(oldPath, newPath, jupyterUser).then(
    (response, error) => {
      if (response) {
        // 1. Check whether the notebook is open.
        if (openNb) {
          // Notebook is open
          const openNbPatchedContent = patchContent(
            openNb.content,
            kernelDisplayName,
            kernelName
          );
          return NotebookApi.saveNotebook(
            newPath,
            openNbPatchedContent,
            jupyterUser
          );
        }
        // Notebook is not opened
        return ContentApi.fetchContent(jupyterUser, newPath).then(
          (response, error) => {
            if (response) {
              const content = response.response.content;
              const patchedContent = patchContent(
                content,
                kernelDisplayName,
                kernelName
              );
              return NotebookApi.saveNotebook(
                newPath,
                patchedContent,
                jupyterUser
              );
            }
            return { response: null, error };
          }
        );
      }
      return { response: null, error };
    }
  );
}

const patchContent = (content, kernelDisplayName, kernelName) => ({
  ...content,
  metadata: {
    ...content.metadata,
    kernelspec: {
      display_name: kernelDisplayName,
      name: kernelName,
    },
  },
});

export function* fetchNotebookParentRepositorySaga({ payload: { path } }) {
  if (!path) return;

  // Check whether the path contains a directory ending with ".asr" (= the directory is a repository)
  // -> This would mean that the notebook belongs to a repository
  const arrayPath = contentPathToArray(path);
  const repoIndex = arrayPath.findIndex((dir) =>
    dir.endsWith(DIRECTORY_EXTENSION)
  );
  if (repoIndex < 0) return; // The notebook doesn't belong to a repository -> Simply return, no information to fetch or to update in this case

  // --- If this point is reached it's clear that the notebook belongs to a repository.
  //      -> Fetch the repository information from the meta file and attach it to the notebook.

  // Creates the path for the meta file, for example: ["dir1", "repo2.asr"] -> dir1/repo2/repository.asr
  const metaFilePath = contentArrayToPath(
    arrayPath
      .slice(0, repoIndex + 1)
      .concat(WORKBENCH_FILENAMES.REPOSITORY_META)
  );

  const jupyterUser = yield select((state) => notebookUser(state));
  const { response, error } = yield call(
    ContentApi.fetchContent,
    jupyterUser,
    metaFilePath
  );
  if (response) {
    try {
      const parentRepository = JSON.parse(response.content);
      yield put(fetchNotebookParentRepositorySuccess(path, parentRepository));
    } catch (e) {
      yield put(errorNotification(fetchRepositoryInfoFailed(e)));
    }
  } else {
    yield put(fetchNotebookParentRepositoryFail(path, error));
    yield put(errorNotification(fetchRepositoryInfoFailed()));
  }
}

export function* watchFetchNotebookParentRepository() {
  yield takeEvery(
    fetchNotebookParentRepository.getType(),
    fetchNotebookParentRepositorySaga
  );
}

export function* loadRepoMetaSaga({ payload: { path } }) {
  if (!path) return;

  const jupyterUser = yield select((state) => notebookUser(state));
  const { response, error } = yield call(
    ContentApi.fetchContent,
    jupyterUser,
    path
  );
  if (response) {
    try {
      const metaFile = JSON.parse(response.content);
      yield put(loadRepoMetaSuccess(metaFile, path));
    } catch (e) {
      // Nothing to do in this case
    }
  } else {
    // Nothing to do in this case
  }
}

export function* watchLoadRepoMeta() {
  yield takeEvery(loadRepoMeta.getType(), loadRepoMetaSaga);
}
