import type { Edge, Node } from '@xyflow/react';

import { ConditionalNode } from './ConditionalNode';
import { InputNode } from './InputNode';
import { MapNode } from './MapNode';
import { PrintNode } from './PrintNode';
import { PythonFunctionNode } from './PythonFunction';
import styles from './styles.module.scss';
import { SubflowGatewayNode } from './SubflowGatewayNode';
import { SubflowNode } from './SubflowNode';
import { nodeMetas } from '../meta';
import {
  AS_EXTENDED_NODE_TYPES,
  AS_NODE_TYPES,
  AS_TYPES,
  AsExtendedNodeTypes,
  AsTypes,
  CustomNodesWithGateway,
} from '../types';

export type PersistedNode = Pick<Node, 'id' | 'position'> & {
  type: AsExtendedNodeTypes;
  config: Record<string, unknown>;
};

export type PersistedEdge = Pick<
  Edge,
  'id' | 'type' | 'source' | 'target' | 'sourceHandle' | 'targetHandle'
>;

export type FlowConfig = {
  id: string;
  edges: PersistedEdge[];
  nodes: PersistedNode[];
  subflows: FlowConfig[];
  name?: string;
  // backwards reference to parent of subflow
  // fixme-fd: extend to string[]?
  parentNodeId?: string;
};

export type FlowData = {
  id: string;
  edges: Edge[];
  nodes: CustomNodesWithGateway[];
  subflows: FlowData[];
  name?: string;
  // backwards reference to parent of subflow
  // fixme-fd: extend to string[]?
  parentNodeId?: string;
};

export const nodeTypes = {
  [AS_NODE_TYPES.PYTHON_FN]: PythonFunctionNode,
  [AS_NODE_TYPES.CONDITIONAL]: ConditionalNode,
  [AS_NODE_TYPES.INPUT_STRING]: InputNode,
  [AS_NODE_TYPES.INPUT_INT]: InputNode,
  [AS_NODE_TYPES.INPUT_BOOL]: InputNode,
  [AS_NODE_TYPES.PRINT]: PrintNode,
  [AS_NODE_TYPES.MAP]: MapNode,
  [AS_NODE_TYPES.SUBFLOW]: SubflowNode,
  [AS_EXTENDED_NODE_TYPES.GATEWAY]: SubflowGatewayNode,
  // Add any of your custom nodes here!
} as const;

export const getNodeTypeStyle = (type: AsTypes) => {
  switch (type) {
    case AS_TYPES.BOOL:
      return styles.boolean;
    case AS_TYPES.NUMBER:
      return styles.number;
    case AS_TYPES.STRING:
      return styles.string;
    default:
      return styles.any;
  }
};

export function enrichWithData(
  filePath: string,
  node: PersistedNode
): CustomNodesWithGateway {
  return {
    id: node.id,
    type: node.type,
    position: node.position,
    // @ts-ignore fixme-fd: TS can't know what nodeMetas[node.type] returns
    data: {
      ...(nodeMetas[node.type]?.defaultData || {}),
      filePath,
      config: node.config || {},
    },
  };
}
