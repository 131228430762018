import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';

import styles from './styles.module.scss';
import UserDetails from './user-details/UserDetails';
import AccountAddPhoto from '../../../atoms/account-add-photo/AccountAddPhoto';

const UserInfo: FC = () => {
  return (
    <div className={styles.container}>
      <div className={styles.title}>
        <FormattedMessage id='account.info.title' defaultMessage='Info' />
      </div>

      <div className={styles.content}>
        <UserDetails />
        <AccountAddPhoto />
      </div>
    </div>
  );
};

export default UserInfo;
