/**
 * Takes a long containing the amount of bytes and returns a speaking size
 * @param size
 * @returns {string}
 */
import { S3Object, Table } from '../../store/dataManagement/state.types';

export function styledFileSize(size: number) {
  if (size === undefined || size === null || typeof size !== 'number')
    return '';
  if (size === 0) return '0 Bytes';

  // const factor = 1024; -> With factor = 1000 it matches the size displayed by the regular MacOS Finder
  const factor = 1000;

  let reducedSize = size;
  if (reducedSize <= factor) return `${reducedSize.toFixed(1)} Bytes`;

  reducedSize = reducedSize / factor;
  if (reducedSize <= factor) return `${reducedSize.toFixed(1)} KB`;

  reducedSize = reducedSize / factor;
  if (reducedSize <= factor) return `${reducedSize.toFixed(1)} MB`;

  reducedSize = reducedSize / factor;
  return `${reducedSize.toFixed(1)} GB`;
}

export const objectIsTable = (file: S3Object): boolean =>
  file.objectType === 'file' && file.name.endsWith('.parquet');

export const objectIsImage = (file: S3Object): boolean =>
  file.objectType === 'file' &&
  (file.name.toLowerCase().endsWith('.jpg') ||
    file.name.toLowerCase().endsWith('.jpeg') ||
    file.name.toLowerCase().endsWith('.png'));

export const s3ObjectToTableObject = (s3Object: S3Object): Table => ({
  name: s3Object.name.slice(0, -'.parquet'.length),
});
