import React, { Component } from 'react';

import Wizard from '../../../../pages/wizard/Wizard';
import './styles.scss';
import { ButtonProps } from '../../../../atoms/button/Button';
import {
  JUPYTER_CONTENT_TYPE,
  JupyterContentType,
} from '../../../../../store/workbench/state.types';

interface CloseConfirmProps {
  /** Path of the notebook/file */
  path: string;
  /** ID of the pane the notebook/file is opened in */
  paneId: string;
  /** Content of the notebook/file */
  content: Record<string, unknown> | string;
  /** Save the notebook/file. If the type is not file, the content is expected to be an object */
  saveNotebook(
    path: string,
    content: Record<string, unknown> | string,
    type?: string
  ): void;
  /** Close the notebook/file */
  closeNotebook(...args: unknown[]): unknown;
  /** Hide the confirmation dialog without doing anything */
  hideCloseConfirm(...args: unknown[]): unknown;
  /** Type of the content. */
  type: JupyterContentType;
}

/**
 * Confirmation that is shown if there are unsaved changes in the notebook and the user clicked "close"
 */
export default class CloseConfirm extends Component<CloseConfirmProps> {
  render() {
    const {
      path,
      content,
      saveNotebook,
      closeNotebook,
      hideCloseConfirm,
      paneId,
      type = JUPYTER_CONTENT_TYPE.NOTEBOOK,
    } = this.props;

    const buttons: ButtonProps[] = [
      {
        color: 'orange',
        label: 'Discard Changes',
        onClick: () => {
          closeNotebook(path, paneId, false);
        },
      },
      {
        color: 'secondary',
        label: 'Save & Close',
        onClick: () => {
          saveNotebook(path, content, type);
          closeNotebook(path, paneId, false);
        },
      },
      {
        color: 'white',
        label: 'Cancel',
        onClick: () => hideCloseConfirm(path),
      },
    ];

    return (
      <Wizard
        headline={'Unsaved Changes'}
        buttons={buttons}
        additionalClassname={'CloseConfirm'}
      >
        <div className={'CloseConfirm--body'}>
          <div className={'CloseConfirm--headline'}>
            <p>There are unsaved changes.</p>
          </div>
          <div className={'CloseConfirm--text'}>
            <p>Are you sure you want to close without saving?</p>
          </div>
        </div>
      </Wizard>
    );
  }
}
