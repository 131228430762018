// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_container__rb1JE {
  width: 100%;
  margin-top: 1rem;
  display: flex;
  align-items: flex-end;
}
.styles_container__rb1JE div {
  flex-grow: 1;
}

.styles_group__hE0dM {
  flex-grow: 0 !important;
  height: 46px;
  display: flex;
  align-items: center;
}
.styles_group__hE0dM span {
  margin-right: 0.25rem;
  font-weight: 400;
}`, "",{"version":3,"sources":["webpack://./src/js/components/collaborationSpace/wizards/repository-add/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,gBAAA;EACA,aAAA;EACA,qBAAA;AACF;AACE;EACE,YAAA;AACJ;;AAGA;EACE,uBAAA;EACA,YAAA;EACA,aAAA;EACA,mBAAA;AAAF;AAEE;EACE,qBAAA;EACA,gBAAA;AAAJ","sourcesContent":[".container {\n  width: 100%;\n  margin-top: 1rem;\n  display: flex;\n  align-items: flex-end;\n\n  div {\n    flex-grow: 1;\n  }\n}\n\n.group {\n  flex-grow: 0 !important;\n  height: 46px;\n  display: flex;\n  align-items: center;\n\n  span {\n    margin-right: .25rem;\n    font-weight: 400;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `styles_container__rb1JE`,
	"group": `styles_group__hE0dM`
};
export default ___CSS_LOADER_EXPORT___;
