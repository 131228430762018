import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { NamesResponseBody } from 'common/dist/types/responseBodies/base';
import { ResourceNames } from 'common/dist/types/utils';
import qs from 'qs';

import { apiRequest, CompletedApiRequest, fetchQueryFn } from './_tools';

export const appsKeys = {
  all: () => ['apps'] as const,
  names: () => [...appsKeys.all(), 'names'] as const,
};

export function fetchApps(onlyWithAtLeastOneVersion: boolean) {
  const query = qs.stringify(
    { onlyWithAtLeastOneVersion },
    { addQueryPrefix: true }
  );
  return apiRequest(`/api/apps${query}`);
}

export function fetchAppVersions(appCode: string) {
  return apiRequest(`/api/apps/${appCode}/versions`);
}

export function getAppsNames(): CompletedApiRequest<NamesResponseBody> {
  return apiRequest(`/api/apps/names`);
}

export function useAppsNames(enabled = true): UseQueryResult<ResourceNames> {
  const key = appsKeys.names();
  return useQuery(key, async () => fetchQueryFn(key, () => getAppsNames()), {
    enabled,
  });
}
