import React from 'react';
import { useDrag } from 'react-dnd';
import { FiX } from 'react-icons/fi';
import Measure from 'react-measure';
import { TabLink } from 'react-tabs-redux';

import { Pane, PaneContentType } from '../../../../../../workbench/types';
import { DND_TYPES } from '../../editor-parent/dndConstants';

interface TabLinkElementProps {
  /** Name of the file */
  name: string;
  /** Path of the file */
  path: string;
  type: PaneContentType;
  measuredTabWidth(...args: unknown[]): unknown;
  updateOverlappingTabs(...args: unknown[]): unknown;
  closeNotebook(...args: unknown[]): unknown;
  selectNotebook(...args: unknown[]): unknown;
  hideOverlappingMenu(...args: unknown[]): unknown;
  paneId: string;
  pane: Pane | undefined;
  setNotebookTabDragging(...args: unknown[]): unknown;
}

export default function TabLinkElement({
  selectNotebook,
  hideOverlappingMenu,
  updateOverlappingTabs,
  paneId,
  pane,
  name,
  path,
  type,
  closeNotebook,
  measuredTabWidth,
  setNotebookTabDragging,
}: TabLinkElementProps) {
  const [{ isDragging }, drag] = useDrag({
    item: {
      type: DND_TYPES.FILE_TAB,
      paneId,
      path,
      name,
      contentType: type,
    },
    begin: (monitor) => {
      setNotebookTabDragging(true);
    },
    end: (item, monitor) => {
      setNotebookTabDragging(false);
    },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  });

  return (
    <div key={path} ref={drag}>
      <Measure
        bounds
        onResize={(contentRect) => {
          measuredTabWidth(path, contentRect.bounds.width);
          updateOverlappingTabs();
        }}
      >
        {({ measureRef }) => (
          <div ref={measureRef}>
            <TabLink
              className={'notebook-tab-link'}
              isActive={path === pane.selectedContent}
              handleSelect={(content) => {
                selectNotebook(content);
                hideOverlappingMenu();
              }}
              to={path}
            >
              <div className={'notebook-link-name'}>{name}</div>
              <div
                className={'notebook-close-icon-container'}
                title={'Close Notebook'}
                onClick={() => {
                  // closeNotebook(_,_, true) will check for changes instead of simply "force" closing the notebook.
                  // If there are changes, the "close confirm" wizard will be shown
                  closeNotebook(path, paneId, true);
                  updateOverlappingTabs();
                }}
              >
                <FiX className={'notebook-close-icon'} size={'14px'} />
              </div>
            </TabLink>
          </div>
        )}
      </Measure>
    </div>
  );
}
