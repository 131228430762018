import {
  Background,
  Connection,
  Controls,
  Edge,
  EdgeChange,
  getOutgoers,
  MiniMap,
  Node,
  NodeChange,
  ReactFlow,
  useReactFlow,
} from '@xyflow/react';
import React, { FC, useCallback } from 'react';
import { v4 as uuidv4 } from 'uuid';

import '@xyflow/react/dist/base.css';
import { useDrop } from 'react-dnd';

import { edgeTypes } from './edges';
import { enrichWithData, nodeTypes, PersistedNode } from './nodes';
import { Toolbar, ToolbarNode } from './Toolbar';
import {
  AS_NODE_TYPES,
  AS_TYPES,
  CustomNodesWithGateway,
  isDynamicConnectionsData,
} from './types';

export type PrototypeProps = {
  filePath: string;
  nodes: CustomNodesWithGateway[];
  edges: Edge[];
  onNodesChanged: (changes: NodeChange<CustomNodesWithGateway>[]) => void;
  onEdgesChanged: (changes: EdgeChange[]) => void;
  onConnect: (connection: Connection) => void;
};

//fixme-fd type config
const toolbarNodes: ToolbarNode[] = [
  {
    name: 'produces_list',
    type: AS_NODE_TYPES.PYTHON_FN,
    config: {
      name: 'produces_list',
      connections: {
        inputs: [],
        outputs: [{ id: 'produces_list_out', type: AS_TYPES.ANY }],
      },
      sourceCode: `def produces_list() -> list[str]:
    return ["a","b","c"]`,
    },
  },
  {
    type: AS_NODE_TYPES.MAP,
  },
  {
    type: AS_NODE_TYPES.CONDITIONAL,
  },
  {
    type: AS_NODE_TYPES.INPUT_STRING,
    config: {
      type: AS_TYPES.STRING,
      value: '',
    },
  },
  {
    type: AS_NODE_TYPES.INPUT_INT,
    config: {
      type: AS_TYPES.NUMBER,
      value: 0,
    },
  },
  {
    type: AS_NODE_TYPES.INPUT_BOOL,
    config: {
      type: AS_TYPES.BOOL,
      value: false,
    },
  },
  {
    type: 'print',
  },
  {
    type: 'subflow',
    config: {
      connections: {
        inputs: [],
        outputs: [],
      },
    },
  },
];

const Prototype: FC<PrototypeProps> = ({
  filePath,
  nodes,
  edges,
  onNodesChanged,
  onEdgesChanged,
  onConnect,
}) => {
  const { getNodes, getEdges, screenToFlowPosition } = useReactFlow();

  const [_, drop] = useDrop({
    accept: 'fdNode',
    drop: (item, monitor) => {
      // @ts-ignore fixme-fd
      const node: ToolbarNode = item.node;

      const clientOffset = monitor.getClientOffset();

      const position = screenToFlowPosition({
        x: clientOffset.x,
        y: clientOffset.y,
      });

      const newNode: PersistedNode = {
        id: uuidv4(),
        position,
        type: node.type,
        config: node.config,
      };
      const nodeWithData = enrichWithData(filePath, newNode);

      onNodesChanged([
        {
          type: 'add',
          item: nodeWithData,
        },
      ]);
    },
  });

  const validNoCycle = useCallback(
    (connection: Connection | Edge) => {
      const nodes = getNodes();
      const edges = getEdges();
      const target = nodes.find((node) => node.id === connection.target);
      if (target === undefined) return true;
      const hasCycle = (node: Node, visited = new Set()) => {
        if (visited.has(node.id)) return false;

        visited.add(node.id);

        for (const outgoer of getOutgoers(node, nodes, edges)) {
          if (outgoer.id === connection.source) return true;
          if (hasCycle(outgoer, visited)) return true;
        }
      };

      if (target.id === connection.source) return false;
      return !hasCycle(target);
    },
    [getNodes, getEdges]
  );

  return (
    <div
      style={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        width: 0,
        flexGrow: 1,
      }}
    >
      <div style={{ flexGrow: 1 }}>
        <ReactFlow
          ref={drop}
          isValidConnection={(conn) => {
            // no cycle
            if (!validNoCycle(conn)) return false;
            // not more than one incoming edge
            if (
              edges.find(
                (e) =>
                  e.target === conn.target &&
                  e.targetHandle === conn.targetHandle
              )
            )
              return false;

            const sourceNode = nodes.find((n) => conn.source === n.id);
            const targetNode = nodes.find((n) => conn.target === n.id);

            const sourceOutputs = isDynamicConnectionsData(sourceNode.data)
              ? sourceNode.data.config.connections.outputs
              : sourceNode.data.connections.outputs;
            const sourceType =
              sourceOutputs.length === 1
                ? sourceOutputs[0].type
                : sourceOutputs.find(
                    (output) => output.id === conn.sourceHandle
                  ).type;
            const targetInputs = isDynamicConnectionsData(targetNode.data)
              ? targetNode.data.config.connections.inputs
              : targetNode.data.connections.inputs;
            const targetType =
              targetInputs.length === 1
                ? targetInputs[0].type
                : targetInputs.find((output) => output.id === conn.targetHandle)
                    .type;

            return (
              sourceType === AS_TYPES.ANY ||
              targetType === AS_TYPES.ANY ||
              sourceType === targetType
            );
          }}
          nodes={nodes}
          nodeTypes={nodeTypes}
          onNodesChange={onNodesChanged}
          edges={edges}
          edgeTypes={edgeTypes}
          onEdgesChange={onEdgesChanged}
          onConnect={onConnect}
          fitView
        >
          <Background />
          <MiniMap />
          <Controls />
        </ReactFlow>
      </div>
      <Toolbar nodes={toolbarNodes} />
    </div>
  );
};

export default Prototype;
