// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_container__M2e3A {
  display: flex;
  width: 100%;
  height: 100%;
  position: relative;
}

.styles_table__nBbJU {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  max-width: 350px;
  height: 100%;
}
.styles_table__nBbJU a {
  text-decoration: none;
}`, "",{"version":3,"sources":["webpack://./src/js/components/molecules/augur-layout-elements/report-elements/performance-drift/v1/single/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,WAAA;EACA,YAAA;EACA,kBAAA;AACF;;AAEA;EACE,aAAA;EACA,sBAAA;EACA,kBAAA;EACA,WAAA;EACA,gBAAA;EACA,YAAA;AACF;AAAE;EACE,qBAAA;AAEJ","sourcesContent":[".container {\n  display: flex;\n  width: 100%;\n  height: 100%;\n  position: relative;\n}\n\n.table {\n  display: flex;\n  flex-direction: column;\n  position: relative;\n  width: 100%;\n  max-width: 350px;\n  height: 100%;\n  & a {\n    text-decoration: none;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `styles_container__M2e3A`,
	"table": `styles_table__nBbJU`
};
export default ___CSS_LOADER_EXPORT___;
