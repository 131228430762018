import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';

type Props = {
  expanded: boolean;
  onExpand: () => void;
  onShrink: () => void;
};

const Expanded: FC<Props> = ({ expanded, onExpand, onShrink }) => {
  return (
    <div className='LoginHistory--expand-link'>
      {expanded ? (
        <a className='LoginHistory--expand-button' onClick={onShrink}>
          <FormattedMessage
            id='account.loginHistory.datetime.show_less'
            defaultMessage='Show Less'
          />
        </a>
      ) : (
        <a className='LoginHistory--expand-button' onClick={onExpand}>
          <FormattedMessage
            id='account.loginHistory.datetime.show_more'
            defaultMessage='Show More'
          />
        </a>
      )}
    </div>
  );
};

export default Expanded;
