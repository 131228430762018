import React, { FC, useState } from 'react';
import { useParams } from 'react-router-dom';

import {
  RejectedFeaturesS3AugurSettings,
  RejectedFeaturesS3Config,
} from './type';
import { useS3TableSamplesForHabitat } from '../../../../../../core/api/data';
import { HabitatRouteParams } from '../../../../../index/routes';
import Header from '../../rejected-features-cassandra/v1/Header';
import InfluencerBar from '../../rejected-features-cassandra/v1/InfluencerBar';
import {
  OrderByType,
  SortByType,
  sortFeatures,
} from '../../rejected-features-cassandra/v1/sorting';
import styles from '../../rejected-features-cassandra/v1/styles.module.scss';
import { AugurSettingsProps } from '../../types/meta';

export type Props = AugurSettingsProps<
  RejectedFeaturesS3AugurSettings,
  RejectedFeaturesS3Config
>;

const RejectedFeaturesS3: FC<Props> = (props: Props) => {
  const {
    config,
    value = { rejected: [], enforced: [] },
    onChange,
    readOnly,
  } = props;
  const { s3Bucket, s3File } = config;
  const { habitatCode } = useParams<HabitatRouteParams>();

  const [sortBy, setSortBy] = useState<SortByType>('feature');
  const [orderBy, setOrderBy] = useState<OrderByType>('descending');
  const tableSamples = useS3TableSamplesForHabitat(
    s3Bucket?.dataSourceCode,
    s3Bucket?.bucketName,
    s3File,
    habitatCode
  );
  const colSpecs = tableSamples.data?.colSpecs || [];
  const columns = colSpecs.map((colSpec) => colSpec.colName);
  const processedColumns = columns.sort((c1, c2) =>
    sortFeatures(c1, c2, value, sortBy, orderBy)
  );

  const handleSort = (newSortBy: SortByType, newOrderBy: OrderByType) => {
    setSortBy(newSortBy);
    setOrderBy(newOrderBy);
  };

  return (
    <div className={styles.grid}>
      <Header sort={handleSort} sortBy={sortBy} orderBy={orderBy} />

      {processedColumns.length === 0 && (
        <div className={styles.noFeaturesAvailable}>No features available.</div>
      )}

      {processedColumns.map((column: string) => (
        <InfluencerBar
          colName={column}
          value={value}
          onChange={onChange}
          readOnly={readOnly}
        />
      ))}
    </div>
  );
};

export default RejectedFeaturesS3;
