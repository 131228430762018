import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import styles from './styles.module.scss';
import { useUser } from '../../../../core/api/accounts';
import { isAdmin } from '../../../../redux/selectors/user.selector';
import Busy from '../../../atoms/busy/Busy';
import { FormattedRelativeTimeConvenient } from '../../../atoms/formatted-date-time/FormattedDateTime';

const UserSidenotes: FC = () => {
  const { data: user, isLoading } = useUser();
  const isAdminUser = useSelector(isAdmin);

  if (isLoading) {
    return <Busy isBusy={true} />;
  }

  return (
    <div className={styles.container}>
      <hr />

      <div className={styles.line}>
        <div className={styles.column}>
          <label className={styles.label}>
            <FormattedMessage
              id='account.info.user_created'
              defaultMessage='User Created'
            />
          </label>
          <div className={styles.value}>
            {user?.createdTimestamp && (
              <FormattedRelativeTimeConvenient
                date={new Date(user.createdTimestamp)}
              />
            )}
          </div>
        </div>

        <div className={styles.column}>
          <label className={styles.label}>
            <FormattedMessage
              id='account.info.account_enabled'
              defaultMessage='Account Enabled'
            />
          </label>
          <div className={styles.value}>
            <span>{user?.enabled ? 'Yes' : 'No'}</span>
          </div>
        </div>

        <div className={styles.column}>
          <label className={styles.label}>
            <FormattedMessage
              id='account.info.is_admin'
              defaultMessage='Is Administrator'
            />
          </label>
          <div className={styles.value}>
            <span>{isAdminUser ? 'Yes' : 'No'}</span>
          </div>
        </div>

        <div className={styles.column}>
          <label className={styles.label}>
            <FormattedMessage id='no-id' defaultMessage='User Id' />
          </label>
          <div className={styles.value}>
            <span>{user?.id}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserSidenotes;
