// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ChangePassword {
  margin: 0 0 60px 0;
}
.ChangePassword--Field--Parent {
  margin-bottom: 1rem;
}
.ChangePassword .InputError {
  margin-top: 11px;
  display: block;
}
.ChangePassword .buttons {
  margin-top: 20px;
  display: flex;
  gap: 8px;
  flex-direction: row;
  justify-content: flex-end;
}`, "",{"version":3,"sources":["webpack://./src/js/components/molecules/user-profile/change-password/styles.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;AACF;AACE;EACE,mBAAA;AACJ;AAEE;EACE,gBAAA;EACA,cAAA;AAAJ;AAGE;EACE,gBAAA;EACA,aAAA;EACA,QAAA;EACA,mBAAA;EACA,yBAAA;AADJ","sourcesContent":[".ChangePassword {\n  margin: 0 0 60px 0;\n\n  &--Field--Parent {\n    margin-bottom: 1rem;\n  }\n\n  .InputError {\n    margin-top: 11px;\n    display: block;\n  }\n\n  .buttons {\n    margin-top: 20px;\n    display: flex;\n    gap: 8px;\n    flex-direction: row;\n    justify-content: flex-end;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
