// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_header__DfpIe {
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/js/components/organisms/collapsible-container/generic/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;AACF","sourcesContent":[".header {\n  cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": `styles_header__DfpIe`
};
export default ___CSS_LOADER_EXPORT___;
