import React, { Component } from 'react';
import PanelGroup from 'react-panelgroup';

import SingleEditorPane from './SingleEditorPane.container';
import { Pane } from '../../../../../workbench/types';

export const PANE_TYPE = {
  EDITOR: 'editor',
  PANES: 'panes',
};

interface EditorParentProps {
  paneHierarchy: { type: string; id: string };
  panes: { [id: string]: Pane };
  resizeSplitPane(...args: unknown[]): unknown;
}

export default class EditorParent extends Component<EditorParentProps> {
  renderPane(paneElement) {
    const { panes, resizeSplitPane } = this.props;
    if (!paneElement) {
      console.log('Undefined paneElement');
      return undefined;
    }

    const { type, id, split, children } = paneElement;
    if (!type || ![PANE_TYPE.EDITOR, PANE_TYPE.PANES].includes(type)) {
      console.log('Undefined or unknown type ' + type);
      return undefined;
    }

    // type = editor
    if (type === PANE_TYPE.EDITOR) {
      return <SingleEditorPane key={id} paneId={id} />;
    }

    if (type === PANE_TYPE.PANES) {
      return (
        <PanelGroup
          key={id}
          direction={split === 'vertical' ? 'row' : 'column'}
          panelWidths={(panes[id] || {}).measures}
          onResizeEnd={(newMeasures) => resizeSplitPane(id, newMeasures)}
        >
          {children.map((element) => this.renderPane(element))}
        </PanelGroup>
      );
    }
  }

  render() {
    const { paneHierarchy } = this.props;
    return (
      <div style={{ height: '100%' }}>{this.renderPane(paneHierarchy)}</div>
    );
  }
}
