import {
  contentArrayToPath,
  contentPathToArray,
} from 'common/dist/utils/workbench/content';
import qs from 'qs';
import {
  FiArrowUp,
  FiCode,
  FiCornerUpLeft,
  FiEdit,
  FiFile,
  FiFileText,
  FiFolder,
  FiGitBranch,
  FiTrash2,
  FiWind,
} from 'react-icons/fi';
import { GrConfigure } from 'react-icons/gr';
import { ImGit } from 'react-icons/im';
import { IoLogoMarkdown } from 'react-icons/io';
import { RiSettingsLine } from 'react-icons/ri';
import { Dispatch } from 'redux-act';

import { renameContent } from '../../../../redux/workbench/modules/content.module';
import { DeprecatedRootState } from '../../../../store/state.type';
import { setActiveProjectPath } from '../../../../store/workbench/activeProject.slice';
import { workbenchRoutes } from '../../../../workbench/common/workbenchRoutes';
import { getSelectedDirPathQuery, openFile } from '../../../workbench/utils';
import { ContextMenuEntry } from '../context-menu/ContextMenu';
import {
  Copy,
  CopyName,
  CopyPath,
  Delete,
  Download,
  Edit,
  EditDirectory,
  OpenDir,
  OpenNotebook,
} from '../context-menu/ContextMenu.defaults';
import { ContentElementType } from '../generic-file-browser/GenericFileBrowser';
import {
  AddDirectory,
  AddFile,
  AddNotebook,
  Paste,
  Reload,
  Settings,
  Upload,
} from '../icon-button-bar/IconButtonBar.defaults';

export const buttonBarButtons = [
  AddNotebook,
  AddFile,
  AddDirectory,
  Paste,
  Upload,
  Reload,
  Settings,
];

export const onClickListeners = {
  [ContentElementType.FILE_ASFLOW]: openFile,
  [ContentElementType.FILE_CODE]: openFile,
  [ContentElementType.FILE_DEFAULT]: undefined,
  [ContentElementType.FILE_MARKDOWN]: openFile,
  [ContentElementType.FILE_NOTEBOOK]: openFile,
  [ContentElementType.FILE_REPOSITORY_INFO]: openFile,
  [ContentElementType.FILE_TEXT]: openFile,
  [ContentElementType.FILE_AUGUR_SETTINGS]: openFile,
  [ContentElementType.FILE_CONFIG]: openFile,
  [ContentElementType.FILE_GITIGNORE]: openFile,
  [ContentElementType.DIRECTORY_PLAIN]: (
    state,
    dispatch,
    history,
    selectedPath: string[],
    element
  ) =>
    // FIXME: not sure if worth it but would be nice if we could also just use the hook here OR get rid of state and dispatch
    history.push(
      `${history.location.pathname}${getSelectedDirPathQuery([
        ...selectedPath,
        element.name,
      ])}`
    ),
  [ContentElementType.DIRECTORY_REPOSITORY]: (
    state,
    dispatch,
    history,
    selectedPath: string[],
    element
  ) => {
    history.push(
      `${history.location.pathname}${getSelectedDirPathQuery([
        ...selectedPath,
        element.name,
      ])}`
    );
  },
  [ContentElementType.SPECIAL_ONE_DIRECTORY_UP]: (
    state,
    dispatch,
    history,
    selectedPath: string[],
    element
  ) => {
    history.push(
      `${history.location.pathname}${getSelectedDirPathQuery(
        selectedPath.slice(0, -1)
      )}`
    );
  },
  [ContentElementType.SPECIAL_LAUNCHER]: undefined,
  [ContentElementType.RECYCLE_BIN]: (
    state,
    dispatch,
    history,
    selectedPath: string[],
    element
  ) => {
    history.push(`${workbenchRoutes.basePath}${workbenchRoutes.recycleBin}`);
  },
  [ContentElementType.SPECIAL_LEAVE_RECYCLE_BIN]: (
    state,
    dispatch,
    history,
    selectedPath: string[],
    element
  ) => {
    history.push(
      `${workbenchRoutes.basePath}${workbenchRoutes.fileBrowser}${history.location.search}`
    );
  },
  [ContentElementType.CLOSE_ACTIVE_PROJECT]: (
    state,
    dispatch,
    history,
    selectedPath: string[],
    element
  ) => {
    // Close active project and navigate to root
    dispatch(setActiveProjectPath(''));
    history.push(
      `${history.location.pathname}${getSelectedDirPathQuery([''])}`
    );
  },
};

export const contextMenuEntries: {
  [type in ContentElementType]: ContextMenuEntry[];
} = {
  [ContentElementType.FILE_ASFLOW]: [CopyName, CopyPath, Download, Delete],
  [ContentElementType.FILE_CODE]: [CopyName, CopyPath, Download, Delete],
  [ContentElementType.FILE_DEFAULT]: [CopyName, CopyPath, Download, Delete],
  [ContentElementType.FILE_MARKDOWN]: [CopyName, CopyPath, Download, Delete],
  [ContentElementType.FILE_NOTEBOOK]: [
    CopyName,
    CopyPath,
    OpenNotebook,
    Copy,
    Edit,
    Download,
    Delete,
  ],
  [ContentElementType.FILE_REPOSITORY_INFO]: [CopyName, CopyPath, Download],
  [ContentElementType.FILE_TEXT]: [CopyName, CopyPath, Download, Delete],
  [ContentElementType.FILE_CONFIG]: [CopyName, CopyPath, Download],
  [ContentElementType.FILE_GITIGNORE]: [CopyName, CopyPath, Download, Delete],
  [ContentElementType.FILE_AUGUR_SETTINGS]: [
    CopyName,
    CopyPath,
    Download,
    Delete,
  ],
  [ContentElementType.DIRECTORY_PLAIN]: [OpenDir, Delete, EditDirectory],
  [ContentElementType.DIRECTORY_REPOSITORY]: [OpenDir, Delete],
  [ContentElementType.SPECIAL_ONE_DIRECTORY_UP]: [],
  [ContentElementType.SPECIAL_LAUNCHER]: [],
  [ContentElementType.RECYCLE_BIN]: [], // TODO (allow to open and to empty recycle bin there?)
  [ContentElementType.SPECIAL_LEAVE_RECYCLE_BIN]: [],
  [ContentElementType.CLOSE_ACTIVE_PROJECT]: [],
};

export const icons = {
  [ContentElementType.FILE_ASFLOW]: FiWind,
  [ContentElementType.FILE_CODE]: FiCode,
  [ContentElementType.FILE_DEFAULT]: FiFile,
  [ContentElementType.FILE_MARKDOWN]: IoLogoMarkdown,
  [ContentElementType.FILE_NOTEBOOK]: FiEdit,
  [ContentElementType.FILE_REPOSITORY_INFO]: FiGitBranch,
  [ContentElementType.FILE_TEXT]: FiFileText,
  [ContentElementType.FILE_AUGUR_SETTINGS]: RiSettingsLine,
  [ContentElementType.FILE_CONFIG]: GrConfigure,
  [ContentElementType.FILE_GITIGNORE]: ImGit,
  [ContentElementType.DIRECTORY_PLAIN]: FiFolder,
  [ContentElementType.DIRECTORY_REPOSITORY]: FiGitBranch,
  [ContentElementType.SPECIAL_ONE_DIRECTORY_UP]: FiArrowUp,
  [ContentElementType.SPECIAL_LAUNCHER]: undefined,
  [ContentElementType.RECYCLE_BIN]: FiTrash2,
  [ContentElementType.SPECIAL_LEAVE_RECYCLE_BIN]: FiArrowUp,
  [ContentElementType.CLOSE_ACTIVE_PROJECT]: FiCornerUpLeft,
};

const directoryDrop = (
  state: DeprecatedRootState,
  dispatch: Dispatch,
  path: string,
  name: string
) => (e) => {
  const dataRaw = e.dataTransfer.getData('text/plain');
  const data = JSON.parse(dataRaw);
  if (data.type === 'notebookmove') {
    const oldPath = data.path;
    const newPath = `${path}/${data.name}`;
    // @ts-ignore: Type actions/reducers first
    dispatch(renameContent(oldPath, newPath));
  }
};

export const dnd = {
  [ContentElementType.FILE_ASFLOW]: {
    isDraggable: false,
  },
  [ContentElementType.FILE_CODE]: {
    isDraggable: false,
  },
  [ContentElementType.FILE_DEFAULT]: {
    isDraggable: false,
  },
  [ContentElementType.FILE_MARKDOWN]: {
    isDraggable: false,
  },
  [ContentElementType.FILE_NOTEBOOK]: {
    isDraggable: true,
    onDragStart: (
      state: DeprecatedRootState,
      dispatch: Dispatch,
      path: string,
      name: string
    ) => (e) => {
      const data = {
        type: 'notebookmove',
        name,
        path,
      };
      e.dataTransfer.setData('text/plain', JSON.stringify(data));
    },
  },
  [ContentElementType.FILE_REPOSITORY_INFO]: {
    isDraggable: false,
  },
  [ContentElementType.FILE_TEXT]: {
    isDraggable: false,
  },
  [ContentElementType.FILE_AUGUR_SETTINGS]: {
    isDraggable: false,
  },
  [ContentElementType.FILE_CONFIG]: {
    isDraggable: false,
  },
  [ContentElementType.FILE_GITIGNORE]: {
    isDraggable: false,
  },
  [ContentElementType.DIRECTORY_PLAIN]: {
    isDraggable: false,
    onDrop: directoryDrop,
    onDragEnter: (
      state: DeprecatedRootState,
      dispatch: Dispatch,
      path: string,
      name: string,
      setHighlighted: (val: boolean) => void
    ) => (e) => {
      setHighlighted(true);
    },
    onDragLeave: (
      state: DeprecatedRootState,
      dispatch: Dispatch,
      path: string,
      name: string,
      setHighlighted: (val: boolean) => void
    ) => (e) => {
      setHighlighted(false);
    },
    onDragOver: (
      state: DeprecatedRootState,
      dispatch: Dispatch,
      path: string,
      name: string
    ) => (e) => {
      e.preventDefault();
    },
  },
  [ContentElementType.DIRECTORY_REPOSITORY]: {
    isDraggable: false,
    onDrop: directoryDrop,
    onDragEnter: (
      state: DeprecatedRootState,
      dispatch: Dispatch,
      path: string,
      name: string,
      setHighlighted: (val: boolean) => void
    ) => (e) => {
      setHighlighted(true);
    },
    onDragLeave: (
      state: DeprecatedRootState,
      dispatch: Dispatch,
      path: string,
      name: string,
      setHighlighted: (val: boolean) => void
    ) => (e) => {
      setHighlighted(false);
    },
    onDragOver: (
      state: DeprecatedRootState,
      dispatch: Dispatch,
      path: string,
      name: string
    ) => (e) => {
      e.preventDefault();
    },
  },
  [ContentElementType.SPECIAL_ONE_DIRECTORY_UP]: {
    isDraggable: false,
    onDrop: (
      state: DeprecatedRootState,
      dispatch: Dispatch,
      path: string,
      name: string
    ) => (e) => {
      const dataRaw = e.dataTransfer.getData('text/plain');
      const data = JSON.parse(dataRaw);
      if (data.type === 'notebookmove') {
        const oldPath = data.path;

        // remove 2 entries for file itself and old folder
        const newDir = contentPathToArray(oldPath).slice(0, 2);
        const newPath = contentArrayToPath([...newDir, data.name]);

        // @ts-ignore: Type actions/reducers first
        dispatch(renameContent(oldPath, newPath));
      }
    },
    onDragEnter: (
      state: DeprecatedRootState,
      dispatch: Dispatch,
      path: string,
      name: string,
      setHighlighted: (val: boolean) => void
    ) => (e) => {
      setHighlighted(true);
    },
    onDragLeave: (
      state: DeprecatedRootState,
      dispatch: Dispatch,
      path: string,
      name: string,
      setHighlighted: (val: boolean) => void
    ) => (e) => {
      setHighlighted(false);
    },
    onDragOver: (
      state: DeprecatedRootState,
      dispatch: Dispatch,
      path: string,
      name: string
    ) => (e) => {
      e.preventDefault();
    },
  },
  [ContentElementType.SPECIAL_LAUNCHER]: {
    isDraggable: false,
  },
  [ContentElementType.RECYCLE_BIN]: {
    isDraggable: false,
    // TODO: Allow to drop files into the recycle bin
  },
  [ContentElementType.SPECIAL_LEAVE_RECYCLE_BIN]: {
    isDraggable: false,
  },
  [ContentElementType.CLOSE_ACTIVE_PROJECT]: {
    isDraggable: false,
  },
};
