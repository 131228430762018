// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_any__Q7M-V {
  background-color: #000000 !important;
}

.styles_boolean__le5aj {
  background-color: #0000ff !important;
}

.styles_number__4kntQ {
  background-color: #ff0000 !important;
}

.styles_string__oJIRv {
  background-color: #00ff00 !important;
}

.styles_defaultNodeStyle__eepUN {
  min-height: 30px;
  min-width: 30px;
}`, "",{"version":3,"sources":["webpack://./src/js/components/workbench/fileTypes/flowDesigner/component/nodes/styles.module.scss"],"names":[],"mappings":"AACA;EACE,oCAAA;AAAF;;AAGA;EACE,oCAAA;AAAF;;AAGA;EACE,oCAAA;AAAF;;AAGA;EACE,oCAAA;AAAF;;AAGA;EACE,gBAAA;EACA,eAAA;AAAF","sourcesContent":["\n.any {\n  background-color: #000000 !important;\n}\n\n.boolean {\n  background-color: #0000ff !important;\n}\n\n.number {\n  background-color: #ff0000 !important;\n}\n\n.string {\n  background-color: #00ff00 !important;\n}\n\n.defaultNodeStyle {\n  min-height: 30px;\n  min-width: 30px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"any": `styles_any__Q7M-V`,
	"boolean": `styles_boolean__le5aj`,
	"number": `styles_number__4kntQ`,
	"string": `styles_string__oJIRv`,
	"defaultNodeStyle": `styles_defaultNodeStyle__eepUN`
};
export default ___CSS_LOADER_EXPORT___;
