import React, { Component, FC } from 'react';
import { DropTarget, useDrop } from 'react-dnd';
import Measure from 'react-measure';

import { DND_TYPES } from './dndConstants';
import { editorMainCollect, elementEditorMain } from './dndContracts';
import vars from '../../../../../../scss/base/var.module.scss';

type CommonProps = {
  width: number;
  height: number;
  paneId: string;
  splitPane: (...args: unknown[]) => unknown;
  setNotebookTabDragging: (...args: unknown[]) => unknown;
};

type DragObject = {
  paneId: string;
  path: string;
  name: string;
  contentType: string;
  type: string;
};

type CollectedProps = {
  isOverCurrent: boolean;
  canDrop: boolean;
};

// --- LEFT
const DropOverlayLeft: FC<CommonProps> = ({
  width,
  height,
  paneId,
  splitPane,
  setNotebookTabDragging,
}) => {
  const [{ isOverCurrent, canDrop }, drop] = useDrop<
    DragObject,
    unknown,
    CollectedProps
  >({
    accept: DND_TYPES.FILE_TAB,
    drop(item, monitor) {
      // Obtain the dragged item
      const { paneId: sourcePaneId, path, name, contentType: type } = item;
      setNotebookTabDragging(false);
      splitPane(name, path, type, sourcePaneId, paneId, 'vertical', 'first');
    },
    canDrop: (item, monitor) => true,
    collect: (monitor) => ({
      // isOver: monitor.isOver(),
      isOverCurrent: monitor.isOver({ shallow: true }),
      canDrop: monitor.canDrop(),
    }),
  });

  return (
    <div
      ref={drop}
      style={{
        position: 'absolute',
        height: '100%',
        width: '100%',
      }}
    >
      <div
        className={'dropzone-left'}
        style={{
          position: 'absolute',
          zIndex: '6',
          borderLeft: width * 0.25 + 'px solid transparent',
          borderBottom: height * 0.25 + 'px solid transparent',
          borderTop: height * 0.25 + 'px solid transparent',
          left: '0',
          height: '100%',
          width: '0',
        }}
      />
      {isOverCurrent && canDrop && (
        <div
          className={'drop-indicator-left'}
          style={{
            position: 'absolute',
            zIndex: '5',
            height: '100%',
            width: '50%',
            backgroundColor: vars.colorPrimaryHighlightTransparent,
            border: `2px dashed ${vars.colorPrimaryHighlight}`,
          }}
        />
      )}
    </div>
  );
};

// --- RIGHT
const DropOverlayRight: FC<CommonProps> = ({
  width,
  height,
  paneId,
  splitPane,
  setNotebookTabDragging,
}) => {
  const [{ isOverCurrent, canDrop }, drop] = useDrop<
    DragObject,
    unknown,
    CollectedProps
  >({
    accept: DND_TYPES.FILE_TAB,
    drop(item, monitor) {
      // Obtain the dragged item
      const { paneId: sourcePaneId, path, name, contentType: type } = item;
      setNotebookTabDragging(false);
      splitPane(name, path, type, sourcePaneId, paneId, 'vertical', 'last');
    },
    canDrop: (item, monitor) => true,
    collect: (monitor) => ({
      // isOver: monitor.isOver(),
      isOverCurrent: monitor.isOver({ shallow: true }),
      canDrop: monitor.canDrop(),
    }),
  });

  return (
    <div
      ref={drop}
      style={{
        position: 'absolute',
        height: '100%',
        width: '100%',
      }}
    >
      <div
        className={'dropzone-right'}
        style={{
          position: 'absolute',
          zIndex: '6',
          borderRight: width * 0.25 + 'px solid transparent',
          borderBottom: height * 0.25 + 'px solid transparent',
          borderTop: height * 0.25 + 'px solid transparent',
          right: '0',
          height: '100%',
          width: '0',
        }}
      />
      {isOverCurrent && canDrop && (
        <div
          className={'drop-indicator-right'}
          style={{
            position: 'absolute',
            zIndex: '5',
            height: '100%',
            width: '50%',
            backgroundColor: vars.colorPrimaryHighlightTransparent,
            border: `2px dashed ${vars.colorPrimaryHighlight}`,
            right: '0',
          }}
        />
      )}
    </div>
  );
};

// --- TOP
const DropOverlayTop: FC<CommonProps> = ({
  width,
  height,
  paneId,
  splitPane,
  setNotebookTabDragging,
}) => {
  const [{ isOverCurrent, canDrop }, drop] = useDrop<
    DragObject,
    unknown,
    CollectedProps
  >({
    accept: DND_TYPES.FILE_TAB,
    drop(item, monitor) {
      // Obtain the dragged item
      const { paneId: sourcePaneId, path, name, contentType: type } = item;
      setNotebookTabDragging(false);
      splitPane(name, path, type, sourcePaneId, paneId, 'horizontal', 'first');
    },
    canDrop: (item, monitor) => true,
    collect: (monitor) => ({
      // isOver: monitor.isOver(),
      isOverCurrent: monitor.isOver({ shallow: true }),
      canDrop: monitor.canDrop(),
    }),
  });

  return (
    <div
      ref={drop}
      style={{
        position: 'absolute',
        height: '100%',
        width: '100%',
      }}
    >
      <div
        className={'dropzone-top'}
        style={{
          position: 'absolute',
          zIndex: '6',
          borderTop: height * 0.25 + 'px solid transparent',
          borderLeft: width * 0.25 + 'px solid transparent',
          borderRight: width * 0.25 + 'px solid transparent',
          top: '0',
          height: '0',
          width: '100%',
        }}
      />
      {isOverCurrent && canDrop && (
        <div
          className={'drop-indicator-top'}
          style={{
            position: 'absolute',
            zIndex: '5',
            height: '50%',
            width: '100%',
            backgroundColor: vars.colorPrimaryHighlightTransparent,
            border: `2px dashed ${vars.colorPrimaryHighlight}`,
          }}
        />
      )}
    </div>
  );
};

// --- BOTTOM
const DropOverlayBottom: FC<CommonProps> = ({
  width,
  height,
  paneId,
  splitPane,
  setNotebookTabDragging,
}) => {
  const [{ isOverCurrent, canDrop }, drop] = useDrop<
    DragObject,
    unknown,
    CollectedProps
  >({
    accept: DND_TYPES.FILE_TAB,
    drop(item, monitor) {
      // Obtain the dragged item
      const { paneId: sourcePaneId, path, name, contentType: type } = item;
      setNotebookTabDragging(false);
      splitPane(name, path, type, sourcePaneId, paneId, 'horizontal', 'last');
    },
    canDrop: (item, monitor) => true,
    collect: (monitor) => ({
      // isOver: monitor.isOver(),
      isOverCurrent: monitor.isOver({ shallow: true }),
      canDrop: monitor.canDrop(),
    }),
  });

  return (
    <div
      ref={drop}
      style={{
        position: 'absolute',
        height: '100%',
        width: '100%',
      }}
    >
      <div
        className={'dropzone-bottom'}
        style={{
          position: 'absolute',
          zIndex: '6',
          borderBottom: height * 0.25 + 'px solid transparent',
          borderLeft: width * 0.25 + 'px solid transparent',
          borderRight: width * 0.25 + 'px solid transparent',
          bottom: '0',
          height: '0',
          width: '100%',
        }}
      />
      {isOverCurrent && canDrop && (
        <div
          className={'drop-indicator-top'}
          style={{
            position: 'absolute',
            zIndex: '5',
            height: '50%',
            width: '100%',
            backgroundColor: vars.colorPrimaryHighlightTransparent,
            border: `2px dashed ${vars.colorPrimaryHighlight}`,
            bottom: '0',
          }}
        />
      )}
    </div>
  );
};

interface DropOverlayCenterProps {
  moveNotebookToAnotherPane(...args: unknown[]): unknown;
}

// --- CENTER
const DropOverlayCenter: FC<
  Pick<CommonProps, 'paneId'> & DropOverlayCenterProps
> = ({ paneId, moveNotebookToAnotherPane }) => {
  const [{ isOverCurrent, canDrop }, drop] = useDrop<
    DragObject,
    unknown,
    CollectedProps
  >({
    accept: DND_TYPES.FILE_TAB,
    drop(item, monitor) {
      // Obtain the dragged item
      const { paneId: sourcePaneId, path, name, contentType: type } = item;
      moveNotebookToAnotherPane(path, sourcePaneId, paneId);
    },
    canDrop: (item, monitor) => true,
    collect: (monitor) => ({
      // isOver: monitor.isOver(),
      isOverCurrent: monitor.isOver({ shallow: true }),
      canDrop: monitor.canDrop(),
    }),
  });

  return (
    <div
      ref={drop}
      style={{
        position: 'absolute',
        height: '100%',
        width: '100%',
      }}
    >
      <div
        className={'dropzone-center'}
        style={{
          position: 'absolute',
          zIndex: '6',
          // backgroundColor: "#5ec33355",
          height: '50%',
          width: '50%',
          left: '25%',
          top: '25%',
        }}
      />
      {isOverCurrent && canDrop && (
        <div
          className={'drop-indicator-top'}
          style={{
            position: 'absolute',
            zIndex: '5',
            height: '100%',
            width: '100%',
            backgroundColor: vars.colorPrimaryHighlightTransparent,
            border: `2px dashed ${vars.colorPrimaryHighlight}`,
          }}
        />
      )}
    </div>
  );
};

interface DropOverlayProps {
  connectDropTarget(...args: unknown[]): unknown;
  splitPane(...args: unknown[]): unknown;
  moveNotebookToAnotherPane(...args: unknown[]): unknown;
  paneId: string;
  isNotebookTabDragging?: boolean;
  setNotebookTabDragging(...args: unknown[]): unknown;
}

// ---
class DropOverlay extends Component<
  DropOverlayProps,
  { width: number; height: number }
> {
  constructor(props) {
    super(props);
    this.state = {
      width: 0,
      height: 0,
    };
  }

  render() {
    const {
      isNotebookTabDragging,
      connectDropTarget,
      splitPane,
      moveNotebookToAnotherPane,
      paneId,
      setNotebookTabDragging,
    } = this.props;

    return connectDropTarget(
      <div
        style={{
          position: 'absolute',
          width: '100%',
          height: '100%',
          visibility: isNotebookTabDragging ? 'visible' : 'hidden',
        }}
      >
        <Measure
          bounds
          onResize={(contentRect) => {
            this.setState({
              width: contentRect.bounds.width,
              height: contentRect.bounds.height,
            });
          }}
        >
          {({ measureRef }) => (
            <div
              ref={measureRef}
              className={'notebook-tab-content-drop-overlay'}
              style={{
                width: '100%',
                height: '100%',
                position: 'absolute',
              }}
            >
              <DropOverlayTop
                width={this.state.width}
                height={this.state.height}
                splitPane={splitPane}
                paneId={paneId}
                setNotebookTabDragging={setNotebookTabDragging}
              />
              <DropOverlayBottom
                width={this.state.width}
                height={this.state.height}
                splitPane={splitPane}
                paneId={paneId}
                setNotebookTabDragging={setNotebookTabDragging}
              />
              <DropOverlayLeft
                width={this.state.width}
                height={this.state.height}
                splitPane={splitPane}
                paneId={paneId}
                setNotebookTabDragging={setNotebookTabDragging}
              />
              <DropOverlayRight
                width={this.state.width}
                height={this.state.height}
                splitPane={splitPane}
                paneId={paneId}
                setNotebookTabDragging={setNotebookTabDragging}
              />
              <DropOverlayCenter
                moveNotebookToAnotherPane={moveNotebookToAnotherPane}
                paneId={paneId}
              />
            </div>
          )}
        </Measure>
      </div>
    );
  }
}

export default DropTarget(
  DND_TYPES.FILE_TAB,
  elementEditorMain,
  editorMainCollect
)(DropOverlay);
