import {
  generateConditionalNodeCode,
  HANDLE_ID_CONDITION,
  HANDLE_ID_FALSE,
  HANDLE_ID_TRUE,
} from './nodes/ConditionalNode';
import { generateInputNodeCode } from './nodes/InputNode';
import { generateMapNodeCode } from './nodes/MapNode';
import { generatePrintNodeCode } from './nodes/PrintNode';
import { generatePythonFunctionNodeCode } from './nodes/PythonFunction';
import { generateSubflowCode } from './nodes/SubflowNode';
import {
  AS_EXTENDED_NODE_TYPES,
  AS_NODE_TYPES,
  AS_TYPES,
  AsExtendedNodeTypes,
  NodeMeta,
} from './types';

export const nodeMetas: Record<AsExtendedNodeTypes, NodeMeta> = {
  [AS_NODE_TYPES.MAP]: {
    generate: generateMapNodeCode,
    defaultData: {
      connections: {
        inputs: [
          {
            id: 'map_in',
            type: AS_TYPES.ANY,
          },
        ],
        outputs: [
          {
            id: 'map_out',
            type: AS_TYPES.ANY,
          },
        ],
      },
    },
  },
  [AS_NODE_TYPES.SUBFLOW]: {
    generate: generateSubflowCode,
    defaultData: {
      dynamicConnections: true,
    },
  },
  [AS_NODE_TYPES.PRINT]: {
    generate: generatePrintNodeCode,
    defaultData: {
      connections: {
        inputs: [
          {
            id: 'print_in',
            type: AS_TYPES.ANY,
          },
        ],
        outputs: [],
      },
    },
  },
  [AS_NODE_TYPES.CONDITIONAL]: {
    generate: generateConditionalNodeCode,
    defaultData: {
      connections: {
        inputs: [
          {
            id: HANDLE_ID_TRUE,
            type: AS_TYPES.ANY,
          },
          {
            id: HANDLE_ID_CONDITION,
            type: AS_TYPES.BOOL,
          },
          {
            id: HANDLE_ID_FALSE,
            type: AS_TYPES.ANY,
          },
        ],
        outputs: [
          {
            id: 'conditional_out',
            type: AS_TYPES.ANY,
          },
        ],
      },
    },
  },
  [AS_NODE_TYPES.INPUT_STRING]: {
    generate: generateInputNodeCode,
    defaultData: {
      connections: {
        inputs: [],
        outputs: [
          {
            id: 'input_string_in',
            type: AS_TYPES.STRING,
          },
        ],
      },
    },
  },
  [AS_NODE_TYPES.INPUT_INT]: {
    generate: generateInputNodeCode,
    defaultData: {
      connections: {
        inputs: [],
        outputs: [
          {
            id: 'input_int_out',
            type: AS_TYPES.NUMBER,
          },
        ],
      },
    },
  },
  [AS_NODE_TYPES.INPUT_BOOL]: {
    generate: generateInputNodeCode,
    defaultData: {
      connections: {
        inputs: [],
        outputs: [
          {
            id: 'input_bool_out',
            type: AS_TYPES.BOOL,
          },
        ],
      },
    },
  },
  [AS_NODE_TYPES.PYTHON_FN]: {
    generate: generatePythonFunctionNodeCode,
    defaultData: {
      dynamicConnections: true,
    },
  },
  [AS_EXTENDED_NODE_TYPES.GATEWAY]: {
    generate: null,
    defaultData: {
      dynamicConnections: true,
    },
  },
} as const;
