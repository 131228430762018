// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_formContainer__FDmqT {
  height: 100%;
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/js/components/pages/habitat-wizard/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,WAAA;AACF","sourcesContent":[".formContainer {\n  height: 100%;\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"formContainer": `styles_formContainer__FDmqT`
};
export default ___CSS_LOADER_EXPORT___;
