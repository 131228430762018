// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_marginTop__CNBeT {
  margin-top: 17px;
}`, "",{"version":3,"sources":["webpack://./src/js/components/molecules/augur-layout-elements/settings-elements/pipeline-tuning/v1/pipeline-tuning-selection/node-editor/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;AACF","sourcesContent":[".marginTop {\n  margin-top: 17px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"marginTop": `styles_marginTop__CNBeT`
};
export default ___CSS_LOADER_EXPORT___;
