import { Edge } from '@xyflow/react';

import { nodeMetas } from './meta';
import { FlowData } from './nodes';
import {
  ConditionalNode,
  HANDLE_ID_CONDITION,
  HANDLE_ID_FALSE,
  HANDLE_ID_TRUE,
  isConditionalNode,
} from './nodes/ConditionalNode';
import {
  isSubflowGatewayNode,
  SubflowGatewayNode,
} from './nodes/SubflowGatewayNode';
import {
  CustomNodes,
  CustomNodesWithGateway,
  isDynamicConnectionsData,
  Parameter,
} from './types';

export type CodeGenContext = {
  nodeIndices: Record<string, number>;
  subflowIndices: Record<string, number>;
};

export const indentStr = '    ';

function assert(condition: unknown, msg?: string): asserts condition {
  if (!condition) {
    throw new Error(msg);
  }
}

export function varname(
  context: CodeGenContext,
  node: CustomNodesWithGateway,
  handleId?: string
): string {
  // special handling for gateway nodes, these are always parameters for subflows
  if (isSubflowGatewayNode(node)) {
    const index = node.data.config.connections.outputs.findIndex(
      (param) => param.id === handleId
    );
    const parameter = node.data.config.connections.outputs[index];
    if (parameter.name) return parameter.name;
    return parameter.name ?? `param_${index}`;
  }

  const nodeIndex = context.nodeIndices[node.id];
  if (!handleId) {
    return `val_${node.type}_${nodeIndex}`;
  }

  let handleIndex: number;
  let handle: Parameter;
  let multipleHandles: boolean;
  if (isDynamicConnectionsData(node.data)) {
    handleIndex = node.data.config.connections.outputs.findIndex(
      (handle) => handle.id === handleId
    );
    handle = node.data.config.connections.outputs[handleIndex];
    multipleHandles = node.data.config.connections.outputs.length > 1;
  } else {
    handleIndex = node.data.connections.outputs.findIndex(
      (handle) => handle.id === handleId
    );
    handle = node.data.connections.outputs[handleIndex];
    multipleHandles = node.data.connections.outputs.length > 1;
  }

  // if there are multiple output handle we add its index
  return (
    handle.name ??
    `val_${node.type}_${nodeIndex}${multipleHandles ? `_${handleIndex}` : ''}`
  );
}

export function subflowFn(
  context?: CodeGenContext,
  subflow?: FlowData
): string {
  if (!subflow) return '???';

  if (subflow.name) {
    return `fn_${subflow.name}`;
  }

  if (context?.subflowIndices[subflow.id] != null) {
    return `fn_subflow_${context.subflowIndices[subflow.id]}`;
  }

  return `fn_subflow`;
}

export function getIncomingVarNameByHandleId(
  context: CodeGenContext,
  { nodes, edges }: Pick<FlowData, 'nodes' | 'edges'>,
  node: CustomNodesWithGateway,
  handleId: string
) {
  const ie = edges.find(
    (edge) => edge.target === node.id && edge.targetHandle === handleId
  );

  if (!ie) return 'None';

  const sourceNode = nodes.find((node) => node.id === ie.source);
  return varname(context, sourceNode, ie.sourceHandle);
}

function topsort(
  nodes: CustomNodesWithGateway[],
  edges: Edge[]
): CustomNodesWithGateway[] {
  const incomingEdges: Record<string, number> = {};
  nodes.forEach((node) => {
    incomingEdges[node.id] = 0;
  });

  edges.forEach((edge) => {
    if (incomingEdges[edge.target] !== undefined) {
      incomingEdges[edge.target]++;
    }
  });

  const queue: CustomNodesWithGateway[] = [];
  nodes.forEach((node) => {
    if (incomingEdges[node.id] === 0) {
      queue.push(node);
    }
  });

  const topologicalOrder: CustomNodesWithGateway[] = [];

  while (queue.length > 0) {
    const currentNode = queue.shift();
    assert(currentNode !== undefined, 'currentNode undefined');
    topologicalOrder.push(currentNode);

    const outgoingEdges = edges.filter(
      (edge) => edge.source === currentNode.id
    );
    outgoingEdges.forEach((edge) => {
      incomingEdges[edge.target]--;
      if (incomingEdges[edge.target] === 0) {
        const targetNode = nodes.find((node) => node.id === edge.target);
        assert(targetNode !== undefined, 'targetNode undefined');
        queue.push(targetNode);
      }
    });
  }
  assert(topologicalOrder.length === nodes.length, 'cycle??');

  return topologicalOrder;
}

function propagateConditionBackwards(
  nodes: CustomNodesWithGateway[],
  edges: Edge[],
  conditionalNode: string,
  condition: boolean
) {
  const incomingEdges: Record<string, string[]> = {};
  nodes.forEach((node) => {
    incomingEdges[node.id] = [];
  });

  edges.forEach((edge) => {
    if (incomingEdges[edge.target] !== undefined) {
      incomingEdges[edge.target].push(edge.source);
    }
  });

  const visited = new Set();
  const maximalSet: string[] = [];

  function dfs(nodeId: string) {
    visited.add(nodeId);
    maximalSet.push(nodeId);

    const incomingNodes = incomingEdges[nodeId];
    incomingNodes.forEach((incomingNodeId) => {
      if (!visited.has(incomingNodeId)) {
        const outgoingEdges = edges.filter(
          (edge) => edge.source === incomingNodeId
        );
        const isConnectedToOtherNodes = outgoingEdges.some(
          (edge) =>
            (!visited.has(edge.target) && edge.target !== nodeId) ||
            (edge.target === conditionalNode && !edge.targetHandle === condition
              ? HANDLE_ID_TRUE
              : HANDLE_ID_FALSE)
        );

        if (!isConnectedToOtherNodes) {
          dfs(incomingNodeId);
        }
      }
    });
  }

  dfs(conditionalNode);

  return maximalSet;
}

/**
 * e.g. 'return (val_a, val_b)
 */
function generateSubflowReturn(
  context: CodeGenContext,
  flow: FlowData,
  node: SubflowGatewayNode
) {
  assert(
    node.data.config.gatewayType === 'out',
    'Cannot generate return statement for gateway with type in.'
  );

  const gateways = node.data.config.connections.inputs;
  if (gateways.length === 0) {
    return 'pass';
  } else if (gateways.length === 1) {
    return `return ${getIncomingVarNameByHandleId(
      context,
      flow,
      node,
      gateways[0].id
    )}`;
  } else {
    const varNames = gateways.map((gateway) =>
      getIncomingVarNameByHandleId(context, flow, node, gateway.id)
    );
    return `return (${varNames.join(', ')})`;
  }
}

/**
 * e.g. 'def fn_a(param_0, param_1):
 */
function generateSubflowDefinition(
  context: CodeGenContext,
  subflow: FlowData,
  node: SubflowGatewayNode
) {
  assert(
    node.data.config.gatewayType === 'in',
    'Cannot generate definition statement for gateway with type out.'
  );

  const params = node.data.config.connections.outputs.map((parameter, i) => {
    if (parameter.name) return parameter.name;
    return parameter.name ?? `param_${i}`;
  });
  return `def ${subflowFn(context, subflow)}(${params.join(', ')}):`;
}

/**
 * e.g. '(val_0, val_1) = fn()'
 */
export function generateReturnValues(
  context: CodeGenContext,
  node: CustomNodes
): string {
  let outputs: Parameter[];
  if (isDynamicConnectionsData(node.data)) {
    outputs = node.data.config.connections.outputs;
  } else {
    outputs = node.data.connections.outputs;
  }

  const vars = outputs.map((output) => varname(context, node, output.id));

  if (outputs.length === 0) {
    return null;
  } else if (outputs.length === 1) {
    return `${vars[0]}`;
  } else {
    return `(${vars.join(', ')})`;
  }
}

function aggregateConditionalBlock(
  nodes: CustomNodesWithGateway[],
  edges: Edge[],
  statements: [CustomNodes, string[]][],
  node: ConditionalNode,
  conditional: boolean
): string[] {
  const propagated = propagateConditionBackwards(
    nodes,
    edges,
    node.id,
    conditional
  );
  const propStatements = statements.filter(([n]) =>
    propagated.slice(1).includes(n.id)
  );
  return propStatements.flatMap(([, ss]) => ss.map((s) => `${indentStr}${s}`));
}

function optimizeConditionals(
  context: CodeGenContext,
  nodes: CustomNodes[],
  edges: Edge[],
  statements: [CustomNodes, string[]][],
  visited: string[] = []
): string[] {
  const conditionals = statements
    .map(([node, statement], i) => [node, statement, i] as const)
    .filter((statement): statement is [ConditionalNode, string[], number] =>
      isConditionalNode(statement[0])
    )
    .filter(([node, ,]) => !visited.includes(node.id));
  if (conditionals.length <= 0)
    return statements.flatMap(([, statement]) => statement);
  const [node, , i] = conditionals[0];
  const propagated = [
    ...propagateConditionBackwards(nodes, edges, node.id, true),
    ...propagateConditionBackwards(nodes, edges, node.id, false),
  ];
  const beforeConditional = statements
    .slice(0, i)
    .filter(([n]) => !propagated.slice(1).includes(n.id));
  const afterConditional = statements
    .slice(i + 1)
    .filter(([n]) => !propagated.slice(1).includes(n.id));
  const aggregateTrueBlock = aggregateConditionalBlock(
    nodes,
    edges,
    statements,
    node,
    true
  );
  const aggregateFalseBlock = aggregateConditionalBlock(
    nodes,
    edges,
    statements,
    node,
    false
  );

  const flow = { nodes, edges };

  const conditionVar = getIncomingVarNameByHandleId(
    context,
    flow,
    node,
    HANDLE_ID_CONDITION
  );

  const trueVar = getIncomingVarNameByHandleId(
    context,
    flow,
    node,
    HANDLE_ID_TRUE
  );

  const falseVar = getIncomingVarNameByHandleId(
    context,
    flow,
    node,
    HANDLE_ID_FALSE
  );

  const outputVar = generateReturnValues(context, node);
  const statement = [
    `${outputVar} = 'None'`,
    `if ${conditionVar}:`,
    ...aggregateTrueBlock,
    `${indentStr}${outputVar} = ${trueVar}`,
    'else:',
    ...aggregateFalseBlock,
    `${indentStr}${outputVar} = ${falseVar}`,
  ].filter((line) => line.length > 0);
  return optimizeConditionals(
    context,
    nodes,
    edges,
    [...beforeConditional, [node, statement], ...afterConditional],
    [...visited, node.id]
  );
}

export function codegen(flow: FlowData, oldContext?: CodeGenContext): string[] {
  const { nodes, edges, subflows } = flow;

  const nodeIndices = Object.fromEntries(nodes.map((node, i) => [node.id, i]));
  const subflowIndices = Object.fromEntries(
    subflows.map((subflow, i) => [subflow.id, i])
  );
  const context: CodeGenContext = {
    nodeIndices,
    subflowIndices,
  };

  // we define all subflows as separate functions at the top of each function definition
  const subflowStatements = subflows.flatMap((subflow) => {
    return codegen(subflow, context);
  });

  const orderedNodes = topsort(nodes, edges);
  // remove gateway nodes because they are 'pseudo-nodes' that don't generate a statement
  const orderedNodesWithoutGateways = orderedNodes.filter(
    (node): node is CustomNodes => !isSubflowGatewayNode(node)
  );
  const orderedStatementLists: [CustomNodes, string[]][] =
    orderedNodesWithoutGateways.map((node) => {
      const generatedStatements = nodeMetas[node.type].generate(
        context,
        node,
        flow
      );
      return [node, generatedStatements];
    });
  const nonGatewayNodes = nodes.filter(
    (node): node is CustomNodes => !isSubflowGatewayNode(node)
  );
  const optimizedStatements = optimizeConditionals(
    context,
    nonGatewayNodes,
    edges,
    orderedStatementLists
  );

  const statements = [...subflowStatements, ...optimizedStatements];

  const gatewayNodes = nodes.filter(isSubflowGatewayNode);
  const isSubflow = gatewayNodes.length !== 0;
  if (isSubflow) {
    const inGatewayNode = gatewayNodes.find(
      (node) => node.data.config.gatewayType === 'in'
    );
    const definitionStatement = generateSubflowDefinition(
      oldContext, // old context here because the subflow definition is used in the parent
      flow,
      inGatewayNode
    );

    const outGatewayNode = gatewayNodes.find(
      (node) => node.data.config.gatewayType === 'out'
    );
    const returnStatement = generateSubflowReturn(
      context,
      flow,
      outGatewayNode
    );

    // root level subflow -> indent all statements except the subflow function definition
    return [
      definitionStatement,
      ...statements.map((str) => `${indentStr}${str}`),
      `${indentStr}${returnStatement}`,
      '',
    ];
  }

  // no indentation on the first level
  return statements;
}
