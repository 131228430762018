// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_footer__z0NNm {
  margin-top: 20px;
  display: flex;
  align-items: center;
}

.styles_field__G-Fnp {
  margin-bottom: 1rem;
}

.styles_saveButton__xbDW6 {
  display: inline-block;
  margin-left: auto;
}`, "",{"version":3,"sources":["webpack://./src/js/components/admin/users/user-details/change-password/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,aAAA;EACA,mBAAA;AACF;;AAEA;EACE,mBAAA;AACF;;AAEA;EACE,qBAAA;EACA,iBAAA;AACF","sourcesContent":[".footer {\n  margin-top: 20px;\n  display: flex;\n  align-items: center;\n}\n\n.field {\n  margin-bottom: 1rem;\n}\n\n.saveButton {\n  display: inline-block;\n  margin-left: auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"footer": `styles_footer__z0NNm`,
	"field": `styles_field__G-Fnp`,
	"saveButton": `styles_saveButton__xbDW6`
};
export default ___CSS_LOADER_EXPORT___;
