import React from 'react';

import FlowDesigner from './component/FlowDesigner';
import { File, FileTypeWrapper } from '../FileTypeWrapper';

export const FileFlowDesigner: FileTypeWrapper = {
  component: (file: File, paneId: string) => (
    <FlowDesigner
      path={file.path}
      // @ts-ignore
      content={file.content}
      unsavedChanges={file.unsavedChanges}
      showCloseConfirm={file.showCloseConfirm}
      // @ts-ignore
      session={file.session}
      paneId={paneId}
    />
  ),
};
