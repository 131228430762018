// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_fieldsContainer__RFuYK {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  z-index: 10;
}`, "",{"version":3,"sources":["webpack://./src/js/components/molecules/regular-schedule-select/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,qCAAA;EACA,SAAA;EACA,WAAA;AACF","sourcesContent":[".fieldsContainer {\n  display: grid;\n  grid-template-columns: repeat(3, 1fr);\n  gap: 10px;\n  z-index: 10;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"fieldsContainer": `styles_fieldsContainer__RFuYK`
};
export default ___CSS_LOADER_EXPORT___;
