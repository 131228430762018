import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import Skeleton from 'react-loading-skeleton';

import { useUser } from '../../../../../core/api/accounts';
import styles from '../styles.module.scss';

const UserDetails: FC = () => {
  const { data: user, isLoading } = useUser();

  return (
    <div className={styles.userInfo}>
      <div className={styles.line}>
        <div className={styles.column}>
          <label className={styles.label}>
            <FormattedMessage
              id='account.info.first_name'
              defaultMessage='First Name'
            />
          </label>
          {isLoading ? (
            <Skeleton className={styles.value} style={{ width: '50px' }} />
          ) : (
            <div className={styles.value}>
              <span>{user?.firstName}</span>
            </div>
          )}
        </div>

        <div className={styles.column}>
          <label className={styles.label}>
            <FormattedMessage
              id='account.info.last_name'
              defaultMessage='Last Name'
            />
          </label>
          {isLoading ? (
            <Skeleton className={styles.value} style={{ width: '50px' }} />
          ) : (
            <div className={styles.value}>
              <span>{user?.lastName}</span>
            </div>
          )}
        </div>
      </div>

      <div className={styles.line}>
        <div className={styles.column}>
          <label className={styles.label}>
            <FormattedMessage id='account.info.email' defaultMessage='E-Mail' />
          </label>
          {isLoading ? (
            <Skeleton className={styles.value} style={{ width: '100px' }} />
          ) : (
            <div className={styles.value}>
              <span>{user?.email}</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default UserDetails;
