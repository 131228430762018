// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_container__16ZFq {
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-top: 16px;
}

.styles_title__EHUYS {
  font-size: 14px;
  font-weight: 400;
}

.styles_description__17YG9 {
  font-size: 13px;
  color: lightgray;
}`, "",{"version":3,"sources":["webpack://./src/js/components/atoms/error-tile/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,QAAA;EACA,gBAAA;AACF;;AAEA;EACE,eAAA;EACA,gBAAA;AACF;;AAEA;EACE,eAAA;EACA,gBAAA;AACF","sourcesContent":[".container {\n  display: flex;\n  flex-direction: column;\n  gap: 4px;\n  margin-top: 16px;\n}\n\n.title {\n  font-size: 14px;\n  font-weight: 400;\n}\n\n.description {\n  font-size: 13px;\n  color: lightgray;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `styles_container__16ZFq`,
	"title": `styles_title__EHUYS`,
	"description": `styles_description__17YG9`
};
export default ___CSS_LOADER_EXPORT___;
