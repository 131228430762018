import React, { FC } from 'react';

import ChangePassword from '../molecules/user-profile/change-password/ChangePassword';
import Language from '../molecules/user-profile/language/Language.container';
import LoginHistory from '../molecules/user-profile/login-history/LoginHistory';
import AccountInfo from '../molecules/user-profile/user-info/UserInfo';
import UserSidenotes from '../molecules/user-profile/user-sidenotes/UserSidenotes';
import MainContainer from '../pages/main-container/MainContainer';
import './style.scss';

const UserProfile: FC = () => {
  return (
    <MainContainer
      additionalClassname={'Account'}
      transparent={false}
      fullWidth
    >
      <div className={'UserProfile'}>
        <AccountInfo />
        <Language />
        <ChangePassword />
        <LoginHistory />
        <UserSidenotes />
      </div>
    </MainContainer>
  );
};

export default UserProfile;
