// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Hide the scrollbar, while still allowing to scroll */
.utils_hideScrollbar__5CZSQ {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}
.utils_hideScrollbar__5CZSQ::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}`, "",{"version":3,"sources":["webpack://./src/scss/base/utils.module.scss"],"names":[],"mappings":"AAAA,uDAAA;AACA;EACE,wBAAA,EAAA,0BAAA;EACA,qBAAA,EAAA,YAAA;AACF;AACE;EACE,aAAA,EAAA,sBAAA;AACJ","sourcesContent":["/* Hide the scrollbar, while still allowing to scroll */\n.hideScrollbar {\n  -ms-overflow-style: none; /* Internet Explorer 10+ */\n  scrollbar-width: none; /* Firefox */\n\n  &::-webkit-scrollbar {\n    display: none; /* Safari and Chrome */\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"hideScrollbar": `utils_hideScrollbar__5CZSQ`
};
export default ___CSS_LOADER_EXPORT___;
