import type { Node, NodeProps } from '@xyflow/react';
import classNames from 'classnames';
import React from 'react';

import { FlowData } from './index';
import styles from './styles.module.scss';
import { generateHandles } from './utils';
import {
  CodeGenContext,
  generateReturnValues,
  getIncomingVarNameByHandleId,
} from '../codegen';
import {
  AS_NODE_TYPES,
  CustomNodes,
  GenericNodeData,
  StaticConnectionsData,
} from '../types';

type ConditionalNodeData = GenericNodeData & StaticConnectionsData;

export type ConditionalNode = Node<
  ConditionalNodeData,
  typeof AS_NODE_TYPES.CONDITIONAL
>;

export function isConditionalNode(node: CustomNodes): node is ConditionalNode {
  return node.type === AS_NODE_TYPES.CONDITIONAL;
}

export const HANDLE_ID_CONDITION = 'in-conditional' as const;
export const HANDLE_ID_TRUE = 'in-true' as const;
export const HANDLE_ID_FALSE = 'in-false' as const;

export const generateConditionalNodeCode = (
  context: CodeGenContext,
  node: ConditionalNode,
  flow: FlowData
) => {
  const conditionVar = getIncomingVarNameByHandleId(
    context,
    flow,
    node,
    HANDLE_ID_CONDITION
  );

  const trueVar = getIncomingVarNameByHandleId(
    context,
    flow,
    node,
    HANDLE_ID_TRUE
  );

  const falseVar = getIncomingVarNameByHandleId(
    context,
    flow,
    node,
    HANDLE_ID_FALSE
  );

  const outputVar = generateReturnValues(context, node);
  return [`${outputVar} = ${trueVar} if ${conditionVar} else ${falseVar}`];
};

export function ConditionalNode({ data }: NodeProps<ConditionalNode>) {
  const {
    connections: { inputs, outputs },
    highlight,
  } = data;
  return (
    <div
      className={classNames(
        'react-flow__node-default',
        styles.defaultNodeStyle
      )}
      style={highlight ? { backgroundColor: 'rgb(222, 222, 222)' } : {}}
    >
      {generateHandles(inputs, 'target')}
      IF
      {generateHandles(outputs, 'source')}
    </div>
  );
}
