import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ACTIVE_PROJECT_PATH_KEY } from '../../localStorage';
import { RootState } from '../store';

export interface ActiveProjectState {
  path?: string;
}

const initialState: ActiveProjectState = {
  path: localStorage.getItem(ACTIVE_PROJECT_PATH_KEY) || '',
};

export const slice = createSlice({
  name: 'activeProject',
  initialState,
  reducers: {
    setActiveProjectPath: (state, action: PayloadAction<string>) => {
      state.path = action.payload;
    },
  },
});

export const getActiveProject = (state: RootState) =>
  state.workbench.activeProject;
export const getActiveProjectPath = (state: RootState) => {
  if (!state.workbench.activeProject.path) {
    return '';
  }
  return state.workbench.activeProject.path;
};

export const { setActiveProjectPath } = slice.actions;
export const activeProjectReducer = slice.reducer;
