// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_Resources__ygks5 {
  height: 100%;
}
.styles_Resources__ygks5 .styles_stopWorkbenchButton__kKUEk {
  margin-left: auto;
  display: inline-block;
  padding: 4px 12px;
}
.styles_Resources__ygks5 .styles_parent__GxO96 {
  padding: 20px;
  height: 100%;
  overflow: auto;
}`, "",{"version":3,"sources":["webpack://./src/js/components/admin/resources/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;AACF;AACE;EACE,iBAAA;EACA,qBAAA;EACA,iBAAA;AACJ;AAEE;EACE,aAAA;EACA,YAAA;EACA,cAAA;AAAJ","sourcesContent":[".Resources {\n  height: 100%;\n\n  .stopWorkbenchButton {\n    margin-left: auto;\n    display: inline-block;\n    padding: 4px 12px; //Defines the height of the button\n  }\n\n  .parent {\n    padding: 20px;\n    height: 100%;\n    overflow: auto;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Resources": `styles_Resources__ygks5`,
	"stopWorkbenchButton": `styles_stopWorkbenchButton__kKUEk`,
	"parent": `styles_parent__GxO96`
};
export default ___CSS_LOADER_EXPORT___;
