import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from '@tanstack/react-query';
import axios from 'axios';
import HttpStatusCodes from 'common/dist/constants/httpStatusCodes';
import { PutChangePasswordResponseBody } from 'common/dist/types/responseBodies/currentUser';
import { Avatar, User } from 'common/dist/types/users';
import { useSelector } from 'react-redux';

import {
  apiRequest,
  CompletedApiRequest,
  deleteApiRequest,
  fetchQueryFn,
  putApiRequest,
} from './_tools';
import { userSummaryKeys } from './users';
import keycloak from '../../../keycloak';
import { LoginHistoryEntry } from '../../components/molecules/user-profile/login-history/LoginHistoryItem';
import { RootState } from '../../store/store';

export const accountsKeys = {
  all: () => ['accounts'] as const,
  user: () => [...accountsKeys.all(), 'user'] as const,
  avatar: () => [...accountsKeys.all(), 'avatar'] as const,
  avatarUpload: () => [...accountsKeys.avatar(), 'upload'] as const,
  avatarDelete: () => [...accountsKeys.avatar(), 'delete'] as const,
  loginHistory: () => [...accountsKeys.all(), 'loginHistory'] as const,
};

export function changePassword(
  newPassword
): CompletedApiRequest<PutChangePasswordResponseBody> {
  return putApiRequest('/api/user/password', { newPassword });
}

export function getPasswordPolicies() {
  return apiRequest('/api/password-policies');
}

export function getUser(): CompletedApiRequest<User> {
  return apiRequest('/api/user');
}

export function useUser(): UseQueryResult<User> {
  const key = accountsKeys.user();
  return useQuery(key, () => fetchQueryFn(key, getUser));
}

export function changeLanguage(language) {
  return putApiRequest('/api/user/locale', { locale: language });
}

export function getAvatar(): CompletedApiRequest<Avatar> {
  return apiRequest('/api/user/avatar');
}

export function useAvatar(): UseQueryResult<Avatar | null> {
  const key = accountsKeys.avatar();

  return useQuery(
    key,
    () =>
      fetchQueryFn(key, async () => {
        const getAvatarResponse = await getAvatar();
        if (getAvatarResponse.status === HttpStatusCodes.NOT_FOUND) {
          return { response: null, status: getAvatarResponse.status };
        }
        return getAvatarResponse;
      }),
    {
      retry: false, // endpoint returns 404 if there is no avatar set
    }
  );
}

const uploadAvatar = async ({
  file,
  onUploadProgress,
}: {
  file: File;
  onUploadProgress: (progress: number) => void;
}) => {
  const formData = new FormData();
  formData.append('file', file);

  const response = await axios.post('/api/user/avatar', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${keycloak.token}`,
    },
    onUploadProgress: (progressEvent) => {
      const progress = Math.round(
        (100 * progressEvent.loaded) / progressEvent.total
      );
      onUploadProgress(progress);
    },
  });

  return response.data;
};

export function useUploadAvatar() {
  const userId = useSelector((state: RootState) => state.currentUser.id);

  const queryClient = useQueryClient();
  const key = accountsKeys.avatarUpload();
  return useMutation(
    key,
    (payload: { file: File; onUploadProgress: (progress: number) => void }) =>
      fetchQueryFn(key, () => uploadAvatar(payload)),
    {
      onSettled: async () => {
        await queryClient.invalidateQueries({
          queryKey: accountsKeys.avatar(),
        });
        await queryClient.invalidateQueries({
          queryKey: userSummaryKeys.userSummary(userId),
        });
      },
    }
  );
}

export function deleteAvatar() {
  return deleteApiRequest('/api/user/avatar');
}

export function useDeleteAvatar() {
  const userId = useSelector((state: RootState) => state.currentUser.id);

  const queryClient = useQueryClient();
  const key = accountsKeys.avatarDelete();
  return useMutation(key, () => fetchQueryFn(key, deleteAvatar), {
    onSettled: async () => {
      await queryClient.invalidateQueries({
        queryKey: accountsKeys.avatar(),
      });
      await queryClient.invalidateQueries({
        queryKey: userSummaryKeys.userSummary(userId),
      });
    },
  });
}

export function getLoginHistory(): CompletedApiRequest<LoginHistoryEntry[]> {
  return apiRequest('/api/user/login-history');
}

export function useLoginHistory(): UseQueryResult<LoginHistoryEntry[]> {
  const key = accountsKeys.loginHistory();
  return useQuery(key, () => fetchQueryFn(key, getLoginHistory));
}

export function updateInfo(info) {
  return putApiRequest('/api/user', info);
}
