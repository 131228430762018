import React, { FC, useState } from 'react';
import DropZone from 'react-dropzone';
import './styles.scss';

import DropZoneBody from './DropZoneBody';
import {
  useAvatar,
  useDeleteAvatar,
  useUploadAvatar,
} from '../../../core/api/accounts';

export const MAX_SIZE = 1048576; // 1MB max

const AccountAddPhoto: FC = () => {
  const [uploadProgress, setUploadProgress] = useState<number>();

  const { data: avatar, isLoading } = useAvatar();
  const { mutate: uploadAvatar, isLoading: isUploading } = useUploadAvatar();
  const { mutate: deleteAvatar } = useDeleteAvatar();

  function handleDrop(files: File[]) {
    const file = files[0];
    if (file) {
      // Can be undefined if a too large file was dropped (and was then rejected by the DropZone)
      uploadAvatar({
        file,
        onUploadProgress: setUploadProgress,
      });
    }
  }

  const dropZoneClassName = `AccountAddPhoto--dz ${
    !avatar ? 'AccountAddPhoto--dz-no-avatar' : 'AccountAddPhoto--dz-avatar'
  }`;

  return (
    <div className={'AccountAddPhoto'}>
      <div className={'AccountAddPhoto--grey-block'}>
        <div className={'AccountAddPhoto--wrap'}>
          <DropZone
            className={dropZoneClassName}
            onDrop={handleDrop}
            accept={'image/*'}
            minSize={0}
            maxSize={MAX_SIZE}
            multiple={false}
          >
            {({ rejectedFiles }) => {
              const isFileTooLarge =
                rejectedFiles.length > 0 && rejectedFiles[0].size > MAX_SIZE;
              return (
                <DropZoneBody
                  loading={isLoading}
                  data={avatar}
                  deleteAvatar={() => deleteAvatar()}
                  isFileTooLarge={isFileTooLarge}
                  uploading={isUploading}
                  progress={uploadProgress}
                />
              );
            }}
          </DropZone>
        </div>
      </div>
    </div>
  );
};

export default AccountAddPhoto;
