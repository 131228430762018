import {
  useInfiniteQuery,
  UseInfiniteQueryResult,
  useQuery,
  UseQueryResult,
} from '@tanstack/react-query';
import { JOB_STATUS, JobStatus } from 'common/dist/constants/enums';
import { AugurJobsWithoutRealtime, DashboardJob } from 'common/dist/types/job';
import qs from 'qs';

import { apiRequest, CompletedApiRequest, fetchQueryFn } from './_tools';
import { useTimeTravel } from '../../components/organisms/time-travel/hooks';

export const jobKeys = {
  all: () => ['jobs'] as const,
  job: (jobCode: string) => [...jobKeys.all(), jobCode] as const,
  jobs: (
    augurCode?: string,
    modelCode?: string,
    status?: JobStatus,
    type?: AugurJobsWithoutRealtime,
    offset?: number,
    limit?: number,
    selection?: string[]
  ) =>
    [
      ...jobKeys.all(),
      augurCode,
      modelCode,
      status,
      type,
      offset,
      limit,
      selection,
    ] as const,
  selectedJobs: (selection: string[]) => [...jobKeys.all(), selection] as const,
};

export const getJobs = (
  augurCode?: string,
  modelCode?: string,
  status?: JobStatus,
  type?: AugurJobsWithoutRealtime,
  offset?: number,
  limit?: number
): CompletedApiRequest<DashboardJob[]> => {
  const query = qs.stringify(
    { augurCode, modelCode, type, offset, limit, status },
    { addQueryPrefix: true }
  );
  return apiRequest(`/api/jobs${query}`);
};

export const getJobsByCode = (
  jobCodes: string[]
): CompletedApiRequest<DashboardJob[]> => {
  const query = qs.stringify(
    { jobCodes },
    { addQueryPrefix: true, arrayFormat: 'comma' }
  );
  return apiRequest(`/api/jobs${query}`);
};

/**
 * Queries all jobs for the current TimeTravel selection.
 * @param enabled
 */
export const useTimeTravelJobsSelection = (
  enabled = true
): UseQueryResult<DashboardJob[]> => {
  const { singleSelection, compareSelection } = useTimeTravel();
  const selection =
    compareSelection ?? (singleSelection ? [singleSelection] : undefined) ?? [];

  const key = jobKeys.selectedJobs(selection);
  return useQuery(
    key,
    () => fetchQueryFn(key, () => getJobsByCode(selection)),
    {
      enabled: enabled && selection.length > 0,
    }
  );
};

/**
 * Queries the latest job for the given model and type.
 * @param modelCode
 * @param type
 * @param enabled
 */
export const useLatestModelJob = (
  modelCode: string,
  type: AugurJobsWithoutRealtime,
  enabled = true
): UseQueryResult<DashboardJob[]> => {
  const key = jobKeys.jobs(undefined, modelCode, undefined, type);
  return useQuery(
    key,
    () =>
      fetchQueryFn(key, () =>
        getJobs(undefined, modelCode, JOB_STATUS.FINISHED, type, undefined, 1)
      ),
    {
      enabled: enabled && !!modelCode,
    }
  );
};

export const useInfiniteJobs = (
  augurCode: string,
  status: JobStatus,
  enabled = true,
  modelCode?: string,
  type?: 'evaluation' | 'prediction',
  limit = 20 // Default limit per page
): UseInfiniteQueryResult<{
  jobs: DashboardJob[];
  nextCursor: number | undefined;
}> => {
  const key = jobKeys.jobs(augurCode, modelCode, status, type);

  return useInfiniteQuery(
    key,
    async ({ pageParam = 0 }) => {
      console.debug(`Fetching jobs with offset: ${pageParam}, limit: ${limit}`);
      const jobs = await fetchQueryFn(key, () =>
        getJobs(
          augurCode,
          modelCode,
          status,
          type,
          pageParam,
          limit + 1 // Request one extra item to check if there are more
        )
      );

      const hasNextPage = jobs.length > limit;
      const paginatedJobs = hasNextPage ? jobs.slice(0, limit) : jobs;

      console.debug(
        `Fetched ${paginatedJobs.length} jobs, hasNextPage: ${hasNextPage}`
      );
      return {
        jobs: paginatedJobs,
        nextCursor: hasNextPage ? pageParam + limit : undefined,
      };
    },
    {
      getNextPageParam: (lastPage) => lastPage.nextCursor,
      keepPreviousData: true, // Only use this for paging
      enabled: enabled,
    }
  );
};

export const getJob = (jobCode: string): CompletedApiRequest<DashboardJob> =>
  apiRequest(`/api/jobs/${jobCode}`);

export const useJob = (jobCode: string): UseQueryResult<DashboardJob> => {
  const key = jobKeys.job(jobCode);
  return useQuery(key, () => fetchQueryFn(key, () => getJob(jobCode)));
};
