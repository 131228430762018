import { contentArrayToPath } from 'common/dist/utils/workbench/content';
import React, { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';

import { useContent } from '../../../../core/api/workbench/content';
import { defaultSidebarSize } from '../../../../core/constants/layout';
import { DeprecatedRootState } from '../../../../store/state.type';
import { getActiveProjectPath } from '../../../../store/workbench/activeProject.slice';
import { JupyterContentElement } from '../../../../store/workbench/state.types';
import { err, ok, Result } from '../../../../utils';
import { ContentElement } from '../../../organisms/workbench-browser/generic-file-browser/GenericFileBrowser';
import { prepareContent } from '../../../organisms/workbench-browser/generic-file-browser/GenericFileBrowser.utils';
import WorkbenchFileBrowser from '../../../organisms/workbench-browser/workbench-file-browser/WorkbenchFileBrowser';
import { useSelectedDirPath } from '../../hooks';

type Props = {};

/**
 * This component is the main part on the left side an contains the file browser
 * (that shows the 'content' = files + directories)
 */
const Content: FC<Props> = () => {
  const location = useLocation();
  const history = useHistory();
  const selectedDirPath = useSelectedDirPath();
  const activeProjectPath: string = useSelector(getActiveProjectPath);

  const width =
    useSelector<DeprecatedRootState, number>(
      (state) => state.workbench.browserWidth
    ) || defaultSidebarSize;

  const { data, error, isError } = useContent(
    contentArrayToPath(selectedDirPath, false)
  );
  const content = (data?.content ?? []) as JupyterContentElement[];

  const selectedContent: Result<ContentElement[], any> = isError
    ? err(error)
    : ok(prepareContent(content, selectedDirPath, activeProjectPath));

  useEffect(() => {
    // If no path is set in the URL, set the path to the active project path
    const params = new URLSearchParams(location.search);
    if (
      activeProjectPath &&
      (!params.get('path') || params.get('path') === '%2F')
    ) {
      params.set('path', encodeURIComponent(activeProjectPath));
      history.replace({ search: params.toString() });
    }
  }, [activeProjectPath, history, location.search]);

  return (
    <WorkbenchFileBrowser
      content={selectedContent}
      path={selectedDirPath}
      width={width}
    />
  );
};

export default Content;
