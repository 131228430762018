import { connect } from 'react-redux';

import TabLine from './TabLine';
import {
  selectNotebook,
  closeNotebook,
  moveNotebookToAnotherPane,
} from '../../../../../redux/workbench/modules/notebook.module';
import {
  measuredParentWidth,
  showMenu,
  hideMenu,
} from '../../../../../redux/workbench/modules/notebook.overlapping.module';
import { DeprecatedRootState } from '../../../../../store/state.type';

export function mapStateToProps(state: DeprecatedRootState, { paneId }) {
  return {
    pane: state.workbench.panes[paneId],
    overlappingMenuIconActive:
      state.workbench.overlappingNotebookTabs &&
      state.workbench.overlappingNotebookTabs.menuIconActive,
    overlappingMenuActive:
      state.workbench.overlappingNotebookTabs &&
      state.workbench.overlappingNotebookTabs.menuActive,
  };
}

export const mapDispatchToProps = {
  handleSelect: selectNotebook,
  closeNotebook,
  measuredParentWidth,
  showOverlappingMenu: showMenu,
  hideOverlappingMenu: hideMenu,
  moveNotebookToAnotherPane,
};

export default connect(mapStateToProps, mapDispatchToProps)(TabLine);
