import classNames from 'classnames';
import { ColSpec } from 'common/dist/types/dataManagement/cassandra';
import React, { FC, useState } from 'react';
import { FiArrowUp, FiFile, FiFolder } from 'react-icons/fi';
import { useIntl } from 'react-intl';
import Skeleton from 'react-loading-skeleton';
import { useParams } from 'react-router-dom';

import { S3FileSelectInputField } from './S3FileSelectInputField';
import styles from './styles.module.scss';
import {
  S3FileSelectAugurSettings,
  S3FileSelectConfig,
  S3FileSelectValidationError,
} from './type';
import {
  useS3FilesForHabitat,
  useS3TableSamplesForHabitat,
} from '../../../../../../core/api/data';
import { S3Object } from '../../../../../../store/dataManagement/state.types';
import { FormattedDateTime } from '../../../../../atoms/formatted-date-time/FormattedDateTime';
import { styledFileSize } from '../../../../../dataManagement/util';
import { AugurSettingsProps } from '../../types/meta';

import 'react-loading-skeleton/dist/skeleton.css';
import CassandraTablePreview from '../../../../../organisms/cassandra-table-preview/CassandraTablePreview';

import messages from 'common/dist/messages/error';

import InputError from '../../../../../atoms/input-error/InputError';
import { HabitatRouteParams } from '../../../../../index/routes';
import ErrorTile from '../../../../../atoms/error-tile/ErrorTile';

export type Props = AugurSettingsProps<
  S3FileSelectAugurSettings,
  S3FileSelectConfig,
  S3FileSelectValidationError
>;

/**
 * Bucketpath is always absolute (leading slash) and is a dir (trailing slash). Examples: /, /foo/, /foo/bar/
 * @param dirs
 */
function dirsToBucketPath(dirs: string[]): string {
  // Add a leading slash and if there are dirs, also a trailing slash
  return '/' + dirs.join('/') + (dirs.length > 0 ? '/' : '');
}

const S3FileSelect: FC<Props> = (props) => {
  const {
    config: { s3Bucket, fileSuffix, showTable, selectable },
    onChange,
    onBlur,
    isTouched,
    name,
    value,
    invalid,
    disabled,
    readOnly,
    inputRef,
    error,
  } = props;

  const intl = useIntl();

  const dirClickable = !readOnly;
  const fileClickable =
    !readOnly &&
    (selectable === undefined || selectable === 'file' || selectable === 'any');
  // Remove leading slash and either an empty "" for values that are dirs or the file name as the last array entry
  const initialDirs = value?.split('/').slice(1, -1) ?? [];
  const [dirs, setDirs] = useState<string[]>(initialDirs);
  // If set so in the config, changing a dir also automatically uses the new dir as a value
  const setDirsPlus =
    selectable === 'dir' || selectable === 'any'
      ? (val: string[]) => {
          setDirs(val);
          if (!readOnly) {
            onChange?.(dirsToBucketPath(val));
          }
        }
      : setDirs;
  const { habitatCode } = useParams<HabitatRouteParams>();
  const {
    data: s3Files,
    error: s3FilesError,
    isError: isS3FilesError,
    isLoading: isS3FilesLoading,
  } = useS3FilesForHabitat(
    habitatCode,
    s3Bucket?.dataSourceCode,
    s3Bucket?.bucketName,
    dirsToBucketPath(dirs),
    fileSuffix
  );

  const files = s3Files || [];
  const sortedData = files.sort((a, b) => {
    let score = 0;
    if (a.objectType === 'directory') score -= 10;
    if (b.objectType === 'directory') score += 10;
    if (a.name > b.name) score += 1;
    return score;
  });

  const {
    data: tableSamples,
    error: tableSampleError,
    isError: isTableSampleError,
    isLoading: isTableSampleLoading,
  } = useS3TableSamplesForHabitat(
    s3Bucket?.dataSourceCode,
    s3Bucket?.bucketName,
    value,
    habitatCode,
    showTable
  );

  /*
   * Renders a single element row
   * */
  const renderElement = (element: S3Object) => {
    const commonRowProps = {
      className: classNames(
        styles.row,
        element.objectType === 'directory'
          ? styles.rowDirectory
          : styles.rowFile,
        { [styles.rowDisabled]: readOnly }
      ),
      style: {
        cursor:
          (element.objectType === 'directory' && dirClickable) || fileClickable
            ? 'pointer'
            : 'default',
        opacity: readOnly ? 0.6 : 1,
      },
    };
    if (element.objectType === 'directory') {
      return (
        <tr
          {...commonRowProps}
          onClick={() => {
            // Strip empty entries out like those caused by a leading slash
            setDirsPlus(element.path.split('/').filter((v) => v !== ''));
          }}
          className={classNames(styles.row, styles.rowDirectory)}
        >
          <td className={styles.colIcon}>
            <FiFolder size={'16px'} />
          </td>
          <td className={styles.colName}>{element.name}</td>
          <td className={styles.colCreatedAt} />
          <td className={styles.colSize} />
        </tr>
      );
    } else {
      return (
        <tr
          {...commonRowProps}
          data-testid={element.name}
          className={classNames(styles.row, styles.rowFile)}
          onClick={
            fileClickable
              ? () => {
                  onChange?.(element.path);
                }
              : undefined
          }
        >
          <td className={styles.colIcon}>
            <FiFile size={'16px'} />
          </td>
          <td className={styles.colName}>{element.name}</td>
          <td className={styles.colCreatedAt}>
            <FormattedDateTime date={new Date(element.lastModified * 1000)} />
          </td>
          <td className={styles.colSize}>{styledFileSize(element.size)}</td>
        </tr>
      );
    }
  };

  /*
   * Renders a row to navigate up in the current filebrowser
   * */
  function folderUp() {
    return (
      <tr
        onClick={() => {
          if (dirs.length > 0) {
            setDirsPlus(dirs.slice(0, -1));
          }
        }}
        className={classNames(styles.row, styles.rowDirectoryUp)}
      >
        <td className={styles.colIcon}>
          <FiArrowUp size={'16px'} />
        </td>
        <td className={styles.colName}>..</td>
        <td className={styles.colCreatedAt}></td>
        <td className={styles.colSize}></td>
      </tr>
    );
  }

  /*
   * Renders a container to show the current select directory in the tree
   * */
  function renderHeadline() {
    return (
      <div className={styles.bucketContentHeadlineContainer}>
        <div className={styles.bucketPathContainer}>
          <div
            className={styles.bpBucket}
            onClick={() => {
              setDirsPlus([]);
            }}
            // TODO This small zIndex of 2 for example overlaps the dropdowns
            style={{ textDecoration: 'none', zIndex: dirs.length + 1 }}
          >
            {s3Bucket?.bucketName ?? (
              <Skeleton width={50} enableAnimation={false} />
            )}
          </div>

          {dirs.map((dir, i) => (
            <div
              className={styles.bpDir}
              key={i}
              onClick={() => {
                setDirsPlus(dirs.slice(0, i + 1));
              }}
              style={{ textDecoration: 'none', zIndex: dirs.length - i }}
            >
              {dir}
            </div>
          ))}
        </div>
      </div>
    );
  }

  function renderPlaceholder() {
    return [...Array<never>(5)].map((_e, i) => (
      <tr className={classNames(styles.row, styles.rowFile)} key={i}>
        <td className={styles.colIcon}>
          <Skeleton circle={true} enableAnimation={!readOnly} />
        </td>
        <td className={styles.colName}>
          <Skeleton enableAnimation={!readOnly} />
        </td>
        <td className={styles.colCreatedAt}>
          <Skeleton enableAnimation={!readOnly} />
        </td>
        <td className={styles.colSize}>
          <Skeleton enableAnimation={!readOnly} />
        </td>
      </tr>
    ));
  }

  function renderTable() {
    const isTableDisabled = readOnly || isS3FilesLoading;
    return (
      <div
        className={styles.tableContainer}
        style={{
          pointerEvents: isTableDisabled ? 'none' : 'auto',
          opacity: isTableDisabled ? 0.6 : 1,
        }}
      >
        <table className={styles.table}>
          <thead>
            <tr className={styles.rowHeader}>
              <th className={styles.colIcon} />
              <th className={styles.colName}>Name</th>
              <th className={styles.colCreatedAt}>Last Modified</th>
              <th className={styles.colSize}>Size</th>
            </tr>
          </thead>
          <tbody>
            {dirs.length > 0 && folderUp()}
            {files && sortedData.map(renderElement)}
            {isTableDisabled && renderPlaceholder()}
          </tbody>
        </table>
      </div>
    );
  }

  const renderS3Files = () => {
    if (isS3FilesError) {
      const errorMessage = s3FilesError;
      const { values, ...message } = errorMessage.formattedMessage;

      return (
        <ErrorTile
          title={intl.formatMessage(messages.s3Bucket, {
            bucketName: s3Bucket?.bucketName,
          })}
          description={intl.formatMessage(message, values)}
        />
      );
    }

    return renderTable();
  };

  const renderTablePreview = () => {
    if (isTableSampleLoading) return;
    if (isTableSampleError) {
      const errorMessage = tableSampleError;
      const { values, ...message } = errorMessage.formattedMessage;

      return (
        <ErrorTile
          title={intl.formatMessage(messages.s3FilePreview, {
            fileName: value,
          })}
          description={intl.formatMessage(message, values)}
        />
      );
    }

    return (
      <div className={styles.tablePreview}>
        <CassandraTablePreview
          colSpecs={tableSamples?.colSpecs || []}
          data={tableSamples?.data || []}
          editable={false}
          sortBy={(colSpecA: ColSpec, colSpecB: ColSpec) =>
            colSpecA.colName.toLowerCase() > colSpecB.colName.toLowerCase()
              ? 1
              : -1
          }
        />
      </div>
    );
  };

  return (
    <div className={styles.container}>
      <div>
        <div>
          {error?.global && (
            <InputError touched={isTouched} error={error.global} />
          )}
        </div>
        <S3FileSelectInputField
          isTouched={isTouched}
          onChange={onChange}
          onBlur={onBlur}
          disabled={disabled}
          name={name}
          value={value}
          invalid={invalid}
          readOnly={readOnly}
          inputRef={inputRef}
        />
      </div>
      <div className={styles.innerContainer}>
        <div className={styles.fileBrowser}>
          {renderHeadline()}
          {renderS3Files()}
        </div>
        {showTable && value?.endsWith('.parquet') && renderTablePreview()}
      </div>
    </div>
  );
};

export default S3FileSelect;
