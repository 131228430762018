import { apiRequest, postApiRequest } from './_tools';

export function fetchObservations(habitatId) {
  return apiRequest(`/api/habitats/${habitatId}/observations`);
}

export function fetchIds(habitatId, observationId) {
  return apiRequest(
    `/api/habitats/${habitatId}/observations/${observationId}/ids`
  );
}

export function validateIds(habitatId, observationId, ids) {
  return postApiRequest(
    `/api/habitats/${habitatId}/observations/${observationId}/validateIds`,
    { observationIds: ids }
  );
}
