import { connect } from 'react-redux';

import EditorParent from './EditorParent';
import { resizeSplitPane } from '../../../../../redux/workbench/modules/notebook.module';
import { DeprecatedRootState } from '../../../../../store/state.type';

export function mapStateToProps(state: DeprecatedRootState) {
  return {
    paneHierarchy: state.workbench.paneHierarchy,
    panes: state.workbench.panes,
  };
}

export const mapDispatchToProps = {
  resizeSplitPane,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditorParent);
