import augurElementsMessages from 'common/dist/messages/augurs.elements';
import React, { FC } from 'react';
import { useForm, Controller } from 'react-hook-form';

import { S3FileSelectConfig, ConfigS3FileSelectConfig } from './type';
import { IntlCheckbox } from '../../../../../atoms/react-hook-form-input-elements/checkbox/Checkbox';
import { DropdownSelectInput } from '../../../../../atoms/react-hook-form-input-elements/dropdown-select-input/DropdownSelectInput';
import InputElementSelect from '../../../../../atoms/react-hook-form-input-elements/input-element-select/InputElementSelect';
import styles from '../../../../../organisms/element-configuration/styles.module.scss';
import { IntlTextInputLine } from '../../../../../atoms/react-hook-form-input-elements/text-input-line/TextInputLine';
import { ConfigPageElementForm } from '../../../../../pages/augur/utils/config.form';
import { ConfigFormComponentProps } from '../../../common/utils';
import { ElementOptionType } from '../../common/ElementOptionComponent';
import { SETTINGS_ELEMENT_TYPES } from '../../types/type';

const S3FileSelectConfigFormComponent: FC<
  ConfigFormComponentProps<S3FileSelectConfig>
> = ({ configValue, onConfigChange, elements }) => {
  const { control, getValues } = useForm<ConfigS3FileSelectConfig>({
    defaultValues: configValue,
  });

  const updateConfig = () => {
    const config = getValues();
    onConfigChange(config);
  };

  const bucketOptions: ElementOptionType[] = elements
    .filter(
      (element) => element.type === SETTINGS_ELEMENT_TYPES.S3_BUCKET_SELECT
    )
    .map((element: ConfigPageElementForm) => ({
      ...element,
      label: element.title,
      value: element.uuid,
    }));

  return (
    <div className={styles.verticalConfigLayout}>
      <Controller
        name='s3Bucket'
        control={control}
        render={({ field }) => {
          return (
            <InputElementSelect
              value={
                field.value.source === 'input-element'
                  ? field.value.elementUuid
                  : ''
              }
              isInputElement={field.value.source === 'input-element'}
              onChange={(newValue) => {
                field.onChange(newValue);
                updateConfig();
              }}
              options={bucketOptions}
              label={augurElementsMessages.s3BucketLabel}
              placeholder={augurElementsMessages.selectS3Bucket}
            />
          );
        }}
      />
      <Controller
        name='showTable.value'
        control={control}
        render={({ field }) => (
          <IntlCheckbox
            label={augurElementsMessages.showTable}
            {...field}
            checked={field.value}
            onChange={() => {
              field.onChange(!field.value);
              updateConfig();
            }}
          />
        )}
      />
      <Controller
        name='fileSuffix.value'
        control={control}
        render={({ field }) => (
          <IntlTextInputLine
            label={augurElementsMessages.fileSuffixLabel}
            placeholder={augurElementsMessages.fileSuffixPlaceholder}
            {...field}
            onChange={(e) => {
              field.onChange(e);
              updateConfig();
            }}
          />
        )}
      />
      <Controller
        name='selectable'
        control={control}
        render={({ field }) => {
          const options = [
            { value: 'dir', label: 'Directories' },
            { value: 'file', label: 'Files' },
            { value: 'any', label: 'Any' },
          ];
          return (
            <DropdownSelectInput
              label={augurElementsMessages.selectable.defaultMessage}
              {...field}
              options={options}
              // @ts-ignore
              value={options.find((o) => o.value === field.value?.value)}
              onChange={(newValue) => {
                field.onChange({
                  source: 'hard-coded',
                  // @ts-ignore
                  value: newValue.value,
                });
                updateConfig();
              }}
            />
          );
        }}
      />
    </div>
  );
};

export default S3FileSelectConfigFormComponent;
