// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fullscreenStyles_fullscreenContainer__wXMxx.MainContainer.Workbench {
  max-width: unset;
  width: 100%;
  height: 100vh !important;
  margin: 0;
  padding: 0;
  border: 0;
  border-radius: 0;
}
.fullscreenStyles_fullscreenContainer__wXMxx.MainContainer.Workbench .MainContainer--inner {
  width: 100%;
  border: none;
}
.fullscreenStyles_fullscreenContainer__wXMxx.MainContainer.Workbench .browser {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  height: 100vh !important;
}
.fullscreenStyles_fullscreenContainer__wXMxx.MainContainer.Workbench .browser .browser-tab-link-container #tab-content {
  border-top-left-radius: 0;
}
.fullscreenStyles_fullscreenContainer__wXMxx.MainContainer.Workbench .browser .content-list {
  height: calc(100vh - 150px);
}
.fullscreenStyles_fullscreenContainer__wXMxx.MainContainer.Workbench .container-notebook {
  width: 100%;
  height: 100vh !important;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.fullscreenStyles_fullscreenContainer__wXMxx.MainContainer.Workbench .container-notebook .fullscreen-button {
  border-top-right-radius: 0;
}
.fullscreenStyles_fullscreenContainer__wXMxx.MainContainer.Workbench .container-notebook .cells {
  height: calc(100vh - 100px);
}
.fullscreenStyles_fullscreenContainer__wXMxx.MainContainer.Workbench .container-notebook .code-content .editor-parent-container {
  height: calc(100vh - 100px);
}`, "",{"version":3,"sources":["webpack://./src/js/components/workbench/fullscreenStyles.module.scss"],"names":[],"mappings":"AAEE;EACE,gBAAA;EACA,WAAA;EACA,wBAAA;EACA,SAAA;EACA,UAAA;EACA,SAAA;EACA,gBAAA;AADJ;AAGI;EACE,WAAA;EACA,YAAA;AADN;AAII;EACE,yBAAA;EACA,4BAAA;EACA,wBAAA;AAFN;AAKQ;EACE,yBAAA;AAHV;AAOM;EACE,2BAAA;AALR;AASI;EACE,WAAA;EACA,wBAAA;EACA,0BAAA;EACA,6BAAA;AAPN;AAUM;EACE,0BAAA;AARR;AAWM;EACE,2BAAA;AATR;AAaQ;EACE,2BAAA;AAXV","sourcesContent":[".fullscreenContainer {\n\n  &:global(.MainContainer.Workbench) {\n    max-width: unset;\n    width: 100%;\n    height: 100vh !important;\n    margin: 0;\n    padding: 0;\n    border: 0;\n    border-radius: 0;\n\n    :global(.MainContainer--inner) {\n      width: 100%;\n      border: none;\n    }\n\n    :global(.browser) {\n      border-top-left-radius: 0;\n      border-bottom-left-radius: 0;\n      height: 100vh !important;\n\n      :global(.browser-tab-link-container) {\n        :global(#tab-content) {\n          border-top-left-radius: 0;\n        }\n      }\n\n      :global(.content-list) {\n        height: calc(100vh - (50px + 50px + 50px));\n      }\n    }\n\n    :global(.container-notebook) {\n      width: 100%;\n      height: 100vh !important;\n      border-top-right-radius: 0;\n      border-bottom-right-radius: 0;\n\n\n      :global(.fullscreen-button) {\n        border-top-right-radius: 0;\n      }\n\n      :global(.cells) {\n        height: calc(100vh - (20px + 30px + 50px))\n      }\n\n      :global(.code-content) {\n        :global(.editor-parent-container) {\n          height: calc(100vh - (20px + 30px + 50px))\n        }\n      }\n    }\n  }\n\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"fullscreenContainer": `fullscreenStyles_fullscreenContainer__wXMxx`
};
export default ___CSS_LOADER_EXPORT___;
