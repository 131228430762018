// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Language {
  margin: 0 0 40px 0;
}
.Language .Language--title {
  font-size: 15px;
  font-weight: 400;
  color: #a6aeb5;
  margin: 20px 0;
}
.Language .buttons {
  margin-top: 20px;
  display: flex;
  gap: 8px;
  flex-direction: row;
  justify-content: flex-end;
}`, "",{"version":3,"sources":["webpack://./src/js/components/molecules/user-profile/language/styles.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;AACF;AACE;EACE,eAAA;EACA,gBAAA;EACA,cAAA;EACA,cAAA;AACJ;AAEE;EACE,gBAAA;EACA,aAAA;EACA,QAAA;EACA,mBAAA;EACA,yBAAA;AAAJ","sourcesContent":[".Language {\n  margin: 0 0 40px 0;\n\n  .Language--title {\n    font-size: 15px;\n    font-weight: 400;\n    color: #a6aeb5;\n    margin: 20px 0;\n  }\n\n  .buttons {\n    margin-top: 20px;\n    display: flex;\n    gap: 8px;\n    flex-direction: row;\n    justify-content: flex-end;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
