// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_pageParent__B5H0x {
  height: 100%;
}

.styles_contentParent__52H\\+E {
  padding: 10px;
  height: 100%;
  overflow-y: auto;
}

.styles_header__j1sau {
  padding: 0 0 10px 0;
  height: 36px;
  display: flex;
  align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/js/components/admin/auditLog/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;AACF;;AAEA;EACE,aAAA;EACA,YAAA;EACA,gBAAA;AACF;;AACA;EACE,mBAAA;EACA,YAAA;EACA,aAAA;EACA,mBAAA;AAEF","sourcesContent":[".pageParent {\n  height: 100%;\n}\n\n.contentParent {\n  padding: 10px;\n  height: 100%;\n  overflow-y: auto;\n}\n.header {\n  padding: 0 0 10px 0;\n  height: 36px;\n  display: flex;\n  align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pageParent": `styles_pageParent__B5H0x`,
	"contentParent": `styles_contentParent__52H+E`,
	"header": `styles_header__j1sau`
};
export default ___CSS_LOADER_EXPORT___;
