// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_Headline__p5pQ3 {
  flex-grow: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.styles_Headline__p5pQ3 .styles_Filters__1u9\\+d {
  display: flex;
  align-items: center;
}
.styles_Headline__p5pQ3 .styles_Filters__1u9\\+d > div {
  margin-right: 10px;
}
.styles_Headline__p5pQ3 .styles_Filters__1u9\\+d .styles_Dropdown__SMeas {
  min-width: 20ch;
}

.styles_elementCardGrid__9ZCgU {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 10px;
}`, "",{"version":3,"sources":["webpack://./src/js/components/collaborationSpace/browser/modules/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EAEA,aAAA;EACA,8BAAA;EACA,mBAAA;AAAF;AAEE;EACE,aAAA;EACA,mBAAA;AAAJ;AACI;EACE,kBAAA;AACN;AAEI;EACE,eAAA;AAAN;;AAKA;EACE,aAAA;EACA,4DAAA;EACA,cAAA;AAFF","sourcesContent":[".Headline {\n  flex-grow: 1; // To override the margin-left: auto from the search box elements to the right\n\n  display: flex;\n  justify-content: space-between; // To move the create button to the left and the Filters to the right\n  align-items: center;\n\n  .Filters {\n    display: flex;\n    align-items: center;\n    > div {\n      margin-right: 10px;\n    }\n\n    .Dropdown {\n      min-width: 20ch;\n    }\n  }\n}\n\n.elementCardGrid {\n  display: grid;\n  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));\n  grid-gap: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Headline": `styles_Headline__p5pQ3`,
	"Filters": `styles_Filters__1u9+d`,
	"Dropdown": `styles_Dropdown__SMeas`,
	"elementCardGrid": `styles_elementCardGrid__9ZCgU`
};
export default ___CSS_LOADER_EXPORT___;
