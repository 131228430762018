// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_resourceManagement__5y0Jz {
  height: 100%;
  display: flex;
}
.styles_resourceManagement__5y0Jz .styles_leftCol__2c8ab {
  min-width: 500px;
}
.styles_resourceManagement__5y0Jz .styles_rightCol__w9KSS {
  margin-left: 5px;
  min-width: 440px;
}

.styles_category__rLNaU {
  padding: 20px 15px;
  background-color: white;
  border-radius: 4px;
}
.styles_category__rLNaU.styles_categorySessions__Q1qj\\+ {
  height: 100%;
  overflow-y: auto;
}
.styles_category__rLNaU.styles_categoryMetrics__YRaSf {
  margin-bottom: 5px;
}
.styles_category__rLNaU.styles_categoryDiskUsage__HXOOG {
  margin-bottom: 5px;
}`, "",{"version":3,"sources":["webpack://./src/js/components/workbench/_entrypoint/resource-management/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,aAAA;AACF;AACE;EACE,gBAAA;AACJ;AAEE;EACE,gBAAA;EAEA,gBAAA;AADJ;;AAKA;EACE,kBAAA;EACA,uBAAA;EACA,kBAAA;AAFF;AAKE;EACE,YAAA;EACA,gBAAA;AAHJ;AAME;EACE,kBAAA;AAJJ;AAOE;EACE,kBAAA;AALJ","sourcesContent":[".resourceManagement {\n  height: 100%;\n  display: flex;\n\n  .leftCol {\n    min-width: 500px;\n  }\n\n  .rightCol {\n    margin-left: 5px;\n    // border: 1px solid deepskyblue;\n    min-width: 440px;\n  }\n}\n\n.category {\n  padding: 20px 15px;\n  background-color: white;\n  border-radius: 4px;\n  // overflow: hidden;\n\n  &.categorySessions {\n    height: 100%;\n    overflow-y: auto;\n  }\n\n  &.categoryMetrics {\n    margin-bottom: 5px;\n  }\n\n  &.categoryDiskUsage {\n    margin-bottom: 5px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"resourceManagement": `styles_resourceManagement__5y0Jz`,
	"leftCol": `styles_leftCol__2c8ab`,
	"rightCol": `styles_rightCol__w9KSS`,
	"category": `styles_category__rLNaU`,
	"categorySessions": `styles_categorySessions__Q1qj+`,
	"categoryMetrics": `styles_categoryMetrics__YRaSf`,
	"categoryDiskUsage": `styles_categoryDiskUsage__HXOOG`
};
export default ___CSS_LOADER_EXPORT___;
