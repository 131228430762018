import { configureStore } from '@reduxjs/toolkit';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { createBrowserHistory, createMemoryHistory } from 'history';
import { intlReducer } from 'react-intl-redux';

// Workbench

import { reducer as workbenchNotifications } from 'react-notification-system-redux';
import { useDispatch } from 'react-redux';
import { applyMiddleware } from 'redux';
import { reducer as form } from 'redux-form';
import createSagaMiddleware from 'redux-saga';

import reducer, { typedReducer } from './reducer';
import sagaMonitor from './sagaMonitor';
import * as states from './state';
import { flowDesignerSlice } from './workbench/flowDesigner.slice';
import {
  activeProjectReducer,
  getActiveProjectPath,
} from './workbench/activeProject.slice';
import { devAugurSlice } from './workbench/devAugur.slice';
import formPlugins from '../core/forms/plugins';
import { saveActiveProjectPath } from '../localStorage';
import rootSaga from '../redux/saga/index';
import workspaceReducer from '../redux/workbench/modules/_rootReducer';

export const history =
  typeof document !== 'undefined' && document.location
    ? createBrowserHistory()
    : createMemoryHistory();

const sagaMiddleware = createSagaMiddleware({ sagaMonitor });

const middlewareList = [routerMiddleware(history), sagaMiddleware];

export let middleware = applyMiddleware(...middlewareList);
if (process.env.NODE_ENV === 'development') {
  middleware = require('redux-devtools-extension') // eslint-disable-line global-require
    .composeWithDevTools(middleware);
}

export const combinedReducer = (state, action) => ({
  ...reducer(state, action),
  ...typedReducer(state, action),
  router: connectRouter(history)(state.router, action),
  form: form.plugin(formPlugins)(state.form, action),
  intl: intlReducer(state.intl, action),
  // Workbench
  workbench: {
    ...workspaceReducer(state.workbench, action),
    devAugur: devAugurSlice.reducer(state.workbench.devAugur, action),
    activeProject: activeProjectReducer(state.workbench.activeProject, action),
    flowDesigner: flowDesignerSlice.reducer(
      state.workbench.flowDesigner,
      action
    ),
    notifications: workbenchNotifications(
      state.workbench.notifications,
      action
    ),
  },
});

// This one is supposed to replace the DeprecatedRootState type defined in frontend/src/js/store/state.type.ts
export type RootState = ReturnType<typeof combinedReducer>;

// New redux toolkit store (to automatically include thunk middleware?)
const store = configureStore({
  reducer: combinedReducer,
  preloadedState: {
    ...(process.env.NODE_ENV === 'test' ? states.test : states.initial),
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false, // Disable later when the reducers are clean
      serializableCheck: false, // Disable later when the reducers are clean
    }).concat(middlewareList),
});

store.subscribe(() => {
  const state = store.getState();
  saveActiveProjectPath(getActiveProjectPath(state));
});

/**
 * Run the rootSaga and restart it on error. May lose the current state (execution of other sagas, outside the error?)
 * No chance to catch the error earlier, because outside a base component saga an error turns into a cancellation of
 * generators, which travels up to the rootSaga. I.e. a try/catch would need to happen inside the saga function passed to takeEvery()
 */
function runSagaFaultTolerant(backoff: number) {
  sagaMiddleware
    .run(rootSaga)
    .toPromise()
    .catch((e: Error) => {
      console.error(
        `Restarting rootSaga after ${backoff} second(s) due to the following error "${e.message}"`
      );
      setTimeout(
        runSagaFaultTolerant,
        backoff * 1000,
        Math.min(backoff * 2, 60)
      );
    });
}
runSagaFaultTolerant(1);
sagaMonitor.assignDispatch(store.dispatch);

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();

export default store;
