import { mainTabSubPaths, routes } from 'common/dist/constants/frontendRoutes';
import React, { FC } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { app } from './routes';
import Admin from '../admin/Admin';
import DashboardContainer from '../dashboard/Dashboard.container';
import { Notifications as NotificationsContainer } from '../notification/Notifications';
import ModelManagementNoTopBar from '../pages/model-management/ModelManagementNoTopBar.container';
import NotEvenOnePermission from '../pages/not-even-one-permission/NotEvenOnePermission.container';
import NotFound from '../pages/not-found/NotFound.container';
import PageSpinnerContainer from '../pageSpinner/PageSpinner.container';
import ParentLayout from '../parentLayout/ParentLayout';
import UserProfile from '../user-profile/UserProfile';

type Props = {
  mainTabsDefaultLink: string;
};

const RootRoutes: FC<Props> = (props: Props) => {
  const { mainTabsDefaultLink } = props;

  return (
    <div className='App'>
      <ParentLayout>
        <PageSpinnerContainer>
          <Switch>
            {/* Simply a redirect to the first permitted main tab */}
            <Route path={'/'} exact={true}>
              <Redirect to={mainTabsDefaultLink} />
            </Route>

            {/* Simply a redirect to /app/admin/users */}
            <Route
              path={app.prefix + app.admin.index}
              exact={true}
              component={() => <Redirect to='/app/admin/users' />}
            />

            {/* UserProfile /app/account/... */}
            <Route path={app.prefix + app.account} component={UserProfile} />

            {/* Admin */}
            <Route path={app.prefix + app.admin.index} component={Admin} />

            {/* Some pages from the Model Management that provide their own top bar */}
            <Route
              path={'/app/habitat/:habitatCode'}
              component={ModelManagementNoTopBar}
            />

            {/* Domains (Dashboard, Workbench, Orchestration, ...). */}
            <Route
              path={`${app.prefix}:mainTab(${Object.values(
                mainTabSubPaths
              ).join('|')})`}
              component={DashboardContainer}
            />

            {/* Shown if the user doesn't have permissions on any main tabs */}
            <Route path={routes.noMainTabs}>
              <NotEvenOnePermission fullViewHeight />
            </Route>

            {/* Fallback: 404 */}
            <Route path={'*'} component={() => <NotFound fullViewHeight />} />
          </Switch>
        </PageSpinnerContainer>
      </ParentLayout>
      <NotificationsContainer />
    </div>
  );
};

export default RootRoutes;
