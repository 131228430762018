// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.react-flow__node.selected {
  box-shadow: 4px 4px 8px 0 rgba(0, 0, 0, 0.2), 0 0 15px 1px rgba(100, 150, 255, 0.4);
}

/* This overrides a settings in _global.scss, otherwise connection lines are not shown while dragging */
svg:not(:root) {
  overflow: visible !important;
}
`, "",{"version":3,"sources":["webpack://./src/js/components/workbench/fileTypes/flowDesigner/component/style.css"],"names":[],"mappings":"AAAA;EACE,mFAAmF;AACrF;;AAEA,uGAAuG;AACvG;EACE,4BAA4B;AAC9B","sourcesContent":[".react-flow__node.selected {\n  box-shadow: 4px 4px 8px 0 rgba(0, 0, 0, 0.2), 0 0 15px 1px rgba(100, 150, 255, 0.4);\n}\n\n/* This overrides a settings in _global.scss, otherwise connection lines are not shown while dragging */\nsvg:not(:root) {\n  overflow: visible !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
