// npx ts-json-schema-generator --no-type-check --path 'src/js/components/molecules/augur-layout-elements/settings-elements/s3FileSelect/v1/type.ts' --type 'ConfigS3FileSelectConfig'
export const schemaConfigS3FileSelect = {
  $ref: '#/definitions/ConfigS3FileSelectConfig',
  $schema: 'http://json-schema.org/draft-07/schema#',
  definitions: {
    'Config<S3FileSelectConfig>': {
      additionalProperties: false,
      description: 'Describes the non-resolved config of a layout element.',
      properties: {
        fileSuffix: {
          $ref: '#/definitions/ConfigEntry%3Calias-731470504-73452-73562-731470504-0-213718%3C(string%7Cundefined)%2Cundefined%3E%3E',
        },
        s3Bucket: {
          $ref: '#/definitions/ConfigEntry%3CS3BucketSelectV1AugurSettings%3E',
        },
        selectable: {
          $ref: '#/definitions/ConfigEntry%3Calias-731470504-73452-73562-731470504-0-213718%3C(%22file%22%7C%22dir%22%7C%22any%22%7Cundefined)2026511692%2Cundefined%3E2026511692%3E',
        },
        showTable: {
          $ref: '#/definitions/ConfigEntry%3Calias-731470504-73452-73562-731470504-0-213718%3C(boolean%7Cundefined)%2Cundefined%3E%3E',
        },
      },
      required: ['s3Bucket'],
      type: 'object',
    },
    'ConfigEntry<S3BucketSelectV1AugurSettings>': {
      anyOf: [
        {
          additionalProperties: false,
          properties: {
            source: {
              const: 'hard-coded',
              type: 'string',
            },
            value: {
              $ref: '#/definitions/S3BucketSelectV1AugurSettings',
            },
          },
          required: ['source', 'value'],
          type: 'object',
        },
        {
          additionalProperties: false,
          properties: {
            elementUuid: {
              type: 'string',
            },
            source: {
              const: 'input-element',
              type: 'string',
            },
          },
          required: ['source', 'elementUuid'],
          type: 'object',
        },
      ],
      description:
        'A single config entry. Is given a generic type T that corresponds to a field in a ConfigType of a layout element.',
    },
    'ConfigEntry<alias-731470504-73452-73562-731470504-0-213718<("file"|"dir"|"any"|undefined)2026511692,undefined>2026511692>':
      {
        anyOf: [
          {
            additionalProperties: false,
            properties: {
              source: {
                const: 'hard-coded',
                type: 'string',
              },
              value: {
                description:
                  "Whether only a file, a dir or both are selectable. If missing it's the same as any",
                enum: ['file', 'dir', 'any'],
                type: 'string',
              },
            },
            required: ['source', 'value'],
            type: 'object',
          },
          {
            additionalProperties: false,
            properties: {
              elementUuid: {
                type: 'string',
              },
              source: {
                const: 'input-element',
                type: 'string',
              },
            },
            required: ['source', 'elementUuid'],
            type: 'object',
          },
        ],
        description:
          'A single config entry. Is given a generic type T that corresponds to a field in a ConfigType of a layout element.',
      },
    'ConfigEntry<alias-731470504-73452-73562-731470504-0-213718<(boolean|undefined),undefined>>':
      {
        anyOf: [
          {
            additionalProperties: false,
            properties: {
              source: {
                const: 'hard-coded',
                type: 'string',
              },
              value: {
                type: 'boolean',
              },
            },
            required: ['source', 'value'],
            type: 'object',
          },
          {
            additionalProperties: false,
            properties: {
              elementUuid: {
                type: 'string',
              },
              source: {
                const: 'input-element',
                type: 'string',
              },
            },
            required: ['source', 'elementUuid'],
            type: 'object',
          },
        ],
        description:
          'A single config entry. Is given a generic type T that corresponds to a field in a ConfigType of a layout element.',
      },
    'ConfigEntry<alias-731470504-73452-73562-731470504-0-213718<(string|undefined),undefined>>':
      {
        anyOf: [
          {
            additionalProperties: false,
            properties: {
              source: {
                const: 'hard-coded',
                type: 'string',
              },
              value: {
                type: 'string',
              },
            },
            required: ['source', 'value'],
            type: 'object',
          },
          {
            additionalProperties: false,
            properties: {
              elementUuid: {
                type: 'string',
              },
              source: {
                const: 'input-element',
                type: 'string',
              },
            },
            required: ['source', 'elementUuid'],
            type: 'object',
          },
        ],
        description:
          'A single config entry. Is given a generic type T that corresponds to a field in a ConfigType of a layout element.',
      },
    ConfigS3FileSelectConfig: {
      $ref: '#/definitions/Config%3CS3FileSelectConfig%3E',
    },
    S3BucketSelectV1AugurSettings: {
      additionalProperties: false,
      description:
        'Name must match with the automatic conversion from type and version. Should only be imported directly by the element that produces it. This is only in common to make generating the jsonSchema and delivering it by the backend easier.',
      properties: {
        bucketName: {
          type: 'string',
        },
        dataSourceCode: {
          type: 'string',
        },
      },
      required: ['dataSourceCode', 'bucketName'],
      type: 'object',
    },
  },
};

// npx ts-json-schema-generator --no-type-check --path 'src/js/components/molecules/augur-layout-elements/settings-elements/s3FileSelect/v1/type.ts' --type 'PartialS3FileSelectAugurSettings'
export const schemaAugurSettingsDefaultS3FileSelect = {
  $ref: '#/definitions/PartialS3FileSelectAugurSettings',
  $schema: 'http://json-schema.org/draft-07/schema#',
  definitions: {
    PartialS3FileSelectAugurSettings: {
      $ref: '#/definitions/S3FileSelectAugurSettings',
    },
    S3FileSelectAugurSettings: {
      type: 'string',
    },
  },
};
