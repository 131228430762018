import { cleanErrorObject } from 'common/dist/validation/helpers';
import { DeepPartial } from 'react-hook-form';

import {
  S3FileSelectAugurSettings,
  S3FileSelectConfig,
  S3FileSelectValidationError,
} from './type';

export const validateS3FileSelect = (
  value: S3FileSelectAugurSettings,
  config: S3FileSelectConfig
): DeepPartial<S3FileSelectValidationError> | undefined => {
  const error: DeepPartial<S3FileSelectValidationError> = {};

  const selectable: 'file' | 'dir' | 'any' = config.selectable ?? 'any';

  if (!value) {
    error.global = 'Missing value';
  } else if (selectable === 'file' && value.endsWith('/')) {
    error.global = 'Only files are valid and a file must not end with "/"';
  } else if (selectable === 'dir' && !value.endsWith('/')) {
    error.global =
      'Only directories are valid and a directory must end with "/"';
  }

  return cleanErrorObject(error);
};
