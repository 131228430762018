// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CloseConfirm .CloseConfirm--body {
  margin-bottom: 20px;
}`, "",{"version":3,"sources":["webpack://./src/js/components/workbench/part-right/editor/close-confirm/styles.scss"],"names":[],"mappings":"AACE;EACE,mBAAA;AAAJ","sourcesContent":[".CloseConfirm {\n  .CloseConfirm--body {\n    margin-bottom: 20px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
