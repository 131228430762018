import { useQuery } from '@tanstack/react-query';
import React, { FC } from 'react';

import styles from './styles.module.scss';

type Props = {
  notebookUser: string;
  activeProjectPath: string;
};

export const getVsCodeUrl: (
  notebookUser: string,
  directory?: string
) => string = (notebookUser, directory) => {
  const baseUrl = `/jupyter/user/${notebookUser}/proxy/3000/?folder=%2Fworkbench`;

  return directory ? `${baseUrl}%2F${directory}` : baseUrl;
};

export const useVsCodeAvailable = (notebookUser: string) => {
  return useQuery(['vscode-available'], async () => {
    const res = await fetch(getVsCodeUrl(notebookUser));
    return res.ok;
  });
};

const VsCode: FC<Props> = ({ notebookUser, activeProjectPath }) => {
  return (
    <iframe
      className={styles.vscodeIframe}
      width={'100%'}
      height={'100%'}
      src={getVsCodeUrl(notebookUser, activeProjectPath)}
    />
  );
};

export default VsCode;
