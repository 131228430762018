import {
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from '@tanstack/react-query';
import archiveMsgs from 'common/dist/messages/augurs.modelArchive';
import { Model, ModelHistoryEntry } from 'common/dist/types/mlModel';
import { PostSetActiveModelRequestBody } from 'common/dist/types/requestBodies/mlModels';
import { PostPutDeleteResponseBody } from 'common/dist/types/responseBodies/base';
import qs from 'qs';
import { useDispatch } from 'react-redux';

import {
  apiRequest,
  CompletedApiRequest,
  fetchQueryFn,
  postApiRequest,
} from './_tools';
import { sendNotification } from '../../redux/modules/notifications.module';
import * as NOTIFICATION_TYPES from '../notifications';

export const mlModelKeys = {
  all: () => ['models'] as const,
  some: (augurCode: string) => [...mlModelKeys.all(), augurCode] as const,
  model: (modelCode: string) => [...mlModelKeys.all(), modelCode] as const,
  models: (augurCode?: string, offset?: number, limit?: number) =>
    [...mlModelKeys.all(), augurCode, offset, limit] as const,
  activeModel: (augurCode: string) =>
    [...mlModelKeys.some(augurCode), 'active'] as const,
  setActive: (augurCode: string) =>
    [...mlModelKeys.some(augurCode), 'set_active'] as const,
  modelHistory: (augurCode: string, offset?: number, limit?: number) =>
    [...mlModelKeys.some(augurCode), 'history', offset, limit] as const,
};

export function getModel(modelCode: string): CompletedApiRequest<Model> {
  return apiRequest(`/api/models/${modelCode}`);
}

export function useModel(
  modelCode: string,
  enabled = true
): UseQueryResult<Model> {
  const key = mlModelKeys.model(modelCode);
  return useQuery(key, () => fetchQueryFn(key, () => getModel(modelCode)), {
    enabled,
  });
}

export function getModels(
  augurCode?: string,
  offset?: number,
  limit?: number
): CompletedApiRequest<Model[]> {
  const query = qs.stringify(
    { augurCode, offset, limit },
    { addQueryPrefix: true }
  );

  return apiRequest(`/api/models${query}`);
}

export function useModels(
  augurCode?: string,
  offset?: number,
  limit?: number
): UseQueryResult<Model[]> {
  const key = mlModelKeys.models(augurCode, offset, limit);
  return useQuery(
    key,
    () => fetchQueryFn(key, () => getModels(augurCode, offset, limit)),
    {
      keepPreviousData: true, // Only use this for paging
    }
  );
}

export function getActiveModel(augurCode: string): CompletedApiRequest<Model> {
  return apiRequest(`/api/augurs/${augurCode}/models/active`);
}

export const useActiveModel = (
  augurCode: string,
  enabled = true
): UseQueryResult<Model> => {
  const key = mlModelKeys.activeModel(augurCode);
  return useQuery(
    key,
    () => fetchQueryFn(key, () => getActiveModel(augurCode)),
    { enabled }
  );
};

export function setActiveModel(
  augurCode: string,
  modelCode: string
): CompletedApiRequest<PostPutDeleteResponseBody> {
  const body: PostSetActiveModelRequestBody = { modelCode };
  return postApiRequest(`/api/augurs/${augurCode}/models/active`, body);
}

export function useSetActiveModel(augurCode: string): UseMutationResult {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const key = mlModelKeys.setActive(augurCode);
  return useMutation(
    key,
    (modelCode: string) =>
      fetchQueryFn(key, () => setActiveModel(augurCode, modelCode)),
    {
      onSettled: async () => {
        await queryClient.invalidateQueries({
          queryKey: mlModelKeys.some(augurCode),
        });
      },
      onSuccess: (data, modelCode) => {
        dispatch(
          sendNotification(
            archiveMsgs.msgNotificationSuccessTitle.id,
            // @ts-ignore
            archiveMsgs.msgNotificationSuccessDescription.id,
            NOTIFICATION_TYPES.event,
            { modelCode: modelCode || '' }
          )
        );
      },
      onError: (data, modelCode) => {
        dispatch(
          sendNotification(
            archiveMsgs.msgNotificationErrorTitle.id,
            // @ts-ignore
            archiveMsgs.msgNotificationErrorDescription.id,
            NOTIFICATION_TYPES.error,
            { modelCode: modelCode || '' }
          )
        );
      },
    }
  );
}

export function getModelHistory(
  augurCode: string,
  modelCode: string,
  offset: number,
  limit: number
): CompletedApiRequest<ModelHistoryEntry[]> {
  const query = qs.stringify(
    { modelCode, offset, limit },
    { addQueryPrefix: true }
  );

  return apiRequest(`/api/augurs/${augurCode}/models/history${query}`);
}

export function useModelHistory(
  augurCode: string,
  modelCode?: string,
  offset?: number,
  limit?: number
): UseQueryResult<ModelHistoryEntry[]> {
  const key = mlModelKeys.modelHistory(augurCode, offset, limit);
  return useQuery(
    key,
    () =>
      fetchQueryFn(key, () =>
        getModelHistory(augurCode, modelCode, offset, limit)
      ),
    {
      keepPreviousData: true, // Only use this for paging
    }
  );
}
