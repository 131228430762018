import classNames from 'classnames';
import React, { FC } from 'react';
import { Handle, Position } from 'reactflow';

import Node, { Props } from './Node';

const FlowElementNode: FC<Props> = (props) => {
  const { data } = props;
  return (
    <div
      className={classNames(
        'PipelineTuningChart--flow-element-node',
        {
          'PipelineTuningChart--flow-element-node-selected':
            data.selectedNodeId === data.id,
        },
        {
          'PipelineTuningChart--flow-element-node-type-classifier':
            data.nodeType === 'classifier',
        },
        {
          'PipelineTuningChart--flow-element-node-type-transformer':
            data.nodeType === 'transformer',
        }
      )}
    >
      <Handle type={'target'} position={Position.Left} />
      <Node data={data} />
      <Handle type={'source'} position={Position.Right} />
    </div>
  );
};

export default FlowElementNode;
