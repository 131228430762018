import { ToBeRefined } from 'common/dist/types/todo_type';
import React, { Component } from 'react';
import { FiInfo, FiSave } from 'react-icons/fi';

import { WORKBENCH_FILENAMES } from '../../../part-right/config';

export type Props = {
  path: string;
  content: string;
  unsavedChanges: boolean;
  showCloseConfirm?: ToBeRefined;
  closeNotebook: ToBeRefined;
  saveNotebook: ToBeRefined;
  paneId: string;
  readOnly: boolean;
};

export default class ButtonBar extends Component<Props> {
  render() {
    const {
      path,
      saveNotebook,
      content,
      unsavedChanges,
      readOnly,
    } = this.props;
    return (
      <div className={'workbench-buttons'}>
        {!readOnly && (
          <div
            title={'Save (Cmd+S)'}
            className={
              'workbench-button' + (unsavedChanges ? ' unsaved-changes' : '')
            }
            onClick={() => saveNotebook(path, content, 'file')}
          >
            <FiSave className={'icon save-icon'} size={'20px'} />
          </div>
        )}
        {readOnly && (
          <div className={'info-text-container'}>
            <FiInfo size={14} />
            <span>This file is read only</span>
          </div>
        )}
        {path.endsWith(WORKBENCH_FILENAMES.REPOSITORY_META) && (
          <div className={'info-text-container'}>
            <FiInfo size={14} />
            <span>
              The Repository Details have been moved into the main menu
            </span>
          </div>
        )}
      </div>
    );
  }
}
