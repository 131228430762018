// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_container__\\+cQ\\+T {
  height: 100%;
}

.styles_content__M\\+n33 {
  margin-top: 0;
  display: flex;
  border-radius: 4px;
  flex-direction: column;
  padding: 20px 15px;
  height: 100%;
}

.styles_listPageParent__tg5uf {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.styles_listContainer__qftsJ {
  padding: 0;
}

.styles_pagingWrapper__n1Hgk {
  height: 1px;
  flex-grow: 1;
  overflow-y: auto;
}`, "",{"version":3,"sources":["webpack://./src/js/components/admin/users/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;AACF;;AAEA;EACE,aAAA;EACA,aAAA;EACA,kBAAA;EACA,sBAAA;EACA,kBAAA;EACA,YAAA;AACF;;AAEA;EACE,YAAA;EACA,aAAA;EACA,sBAAA;AACF;;AAEA;EACE,UAAA;AACF;;AAEA;EACE,WAAA;EACA,YAAA;EACA,gBAAA;AACF","sourcesContent":[".container {\n  height: 100%;\n}\n\n.content {\n  margin-top: 0;\n  display: flex;\n  border-radius: 4px;\n  flex-direction: column;\n  padding: 20px 15px;\n  height: 100%;\n}\n\n.listPageParent {\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n}\n\n.listContainer {\n  padding: 0;\n}\n\n.pagingWrapper {\n  height: 1px;\n  flex-grow: 1;\n  overflow-y: auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `styles_container__+cQ+T`,
	"content": `styles_content__M+n33`,
	"listPageParent": `styles_listPageParent__tg5uf`,
	"listContainer": `styles_listContainer__qftsJ`,
	"pagingWrapper": `styles_pagingWrapper__n1Hgk`
};
export default ___CSS_LOADER_EXPORT___;
