import { connect } from 'react-redux';

import TabLinkElement from './TabLinkElement';
import {
  closeNotebook,
  selectNotebook,
  measuredTabWidth,
  setNotebookTabDragging,
} from '../../../../../../redux/workbench/modules/notebook.module';
import {
  hideMenu,
  updateOverlappingTabs,
} from '../../../../../../redux/workbench/modules/notebook.overlapping.module';
import { DeprecatedRootState } from '../../../../../../store/state.type';

export function mapStateToProps(state: DeprecatedRootState, { paneId }) {
  return {
    pane: state.workbench.panes[paneId],
  };
}

export const mapDispatchToProps = {
  selectNotebook,
  closeNotebook,
  measuredTabWidth,
  updateOverlappingTabs,
  hideOverlappingMenu: hideMenu,
  setNotebookTabDragging,
};

export default connect(mapStateToProps, mapDispatchToProps)(TabLinkElement);
