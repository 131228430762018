// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Account .MainContainer--inner {
  height: 100%;
  overflow-y: auto;
}
.Account .UserProfile {
  padding: 40px 20px;
  max-width: 900px;
  margin: 0 auto;
}`, "",{"version":3,"sources":["webpack://./src/js/components/user-profile/style.scss"],"names":[],"mappings":"AAEE;EACE,YAAA;EACA,gBAAA;AADJ;AAIE;EACE,kBAAA;EACA,gBAAA;EACA,cAAA;AAFJ","sourcesContent":[".Account {\n\n  .MainContainer--inner {\n    height: 100%;\n    overflow-y: auto;\n  }\n\n  .UserProfile {\n    padding: 40px 20px;\n    max-width: 900px;\n    margin: 0 auto;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
