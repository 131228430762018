import { S3FileSelectV1AugurSettings as S3FileSelectAugurSettings } from 'common/dist/types/augurSettings/s3FileSelectV1AugurSettings';
import { DeepPartial } from 'react-hook-form';

import { Config } from '../../../../../pages/augur/type';
import { S3BucketSelectAugurSettings } from '../../s3BucketSelect/v1/type';
import { SETTINGS_ELEMENT_TYPES } from '../../types/type';
import { AllowedInputs } from '../../types/util';

export type { S3FileSelectAugurSettings };

export type S3FileSelectConfig = {
  s3Bucket: S3BucketSelectAugurSettings;
  fileSuffix?: string;
  showTable?: boolean;
  /** Whether only a file, a dir or both are selectable. If missing it's the same as any*/
  selectable?: 'file' | 'dir' | 'any';
};
export type ConfigS3FileSelectConfig = Config<S3FileSelectConfig>;

export type PartialS3FileSelectAugurSettings = S3FileSelectAugurSettings; // Partial<S3FileSelectAugurSettings>;

export type S3FileSelectValidationError = DeepPartial<{ global: string }>;

export const configAllowedInputsS3FileSelect: AllowedInputs<S3FileSelectConfig> = {
  s3Bucket: [{ type: SETTINGS_ELEMENT_TYPES.S3_BUCKET_SELECT, version: 'v1' }],
};

export const configTypeS3FileSelect = `{ 
  fileSuffix:  { source: 'hard-coded', value: string },
  s3Bucket: { source: 'input-element', elementUuid: string } |
      { source: 'hard-coded', value: {
              dataSourceCode: string,
              bucketName: string
      }},
  showTable?:  { source: 'hard-coded', value: boolean },
  selectable?:  { source: 'hard-coded', value: string },
}`;

export const augurSettingsTypeS3FileSelect = `string`;

export const defaultConfigS3FileSelect: ConfigS3FileSelectConfig = {
  s3Bucket: { source: 'input-element', elementUuid: '' },
  showTable: { source: 'hard-coded', value: true },
  fileSuffix: { source: 'hard-coded', value: '' },
  selectable: { source: 'hard-coded', value: 'file' },
};

export const defaultAugurSettingsDefaultS3FileSelect: PartialS3FileSelectAugurSettings =
  '/';

// JSONSchemaType<S3FileSelectAugurSettings>
export const defaultValidationSchemaS3FileSelect = {
  type: 'string',
};
