import { useBooleanFlagValue } from '@openfeature/react-sdk';
import React, { FC } from 'react';
import { TabContent } from 'react-tabs-redux';

import DropOverlay from './DropOverlay.container';
import { Notebook } from '../../../../../store/workbench/state.types';
import { PaneContent } from '../../../../../workbench/types';
import { getComponentForFileName } from '../../../fileTypes/FileTypeManager';

export interface Props {
  paneId: string;
  notebook?: Notebook;
  contentElement: PaneContent;
  selectedContent: string;
}

const SingleEditorPaneTabContent: FC<Props> = ({
  contentElement,
  notebook,
  selectedContent,
  paneId,
}) => {
  // FIXME-FD Fix the DropOverlay so that it also works with the flow-designer
  const flowDesignerEnabled = useBooleanFlagValue('flow-designer', false);
  const isVisible = contentElement.path === selectedContent;
  return (
    <div
      className={'single-editor-pane-tab-content'}
      style={{ display: isVisible ? 'block' : 'none' }}
    >
      {!flowDesignerEnabled && <DropOverlay paneId={paneId} />}
      <TabContent
        className={'notebook-tab-content'}
        for={contentElement.path}
        isVisible={isVisible}
        renderActiveTabContentOnly={false}
        key={contentElement.path}
      >
        {getComponentForFileName(
          // The launcher has no name
          contentElement.name || '',
          contentElement.type
          // @ts-ignore TODO these aren't always real Notebooks, but also Files or even the Launcher
        )(notebook, paneId)}
      </TabContent>
    </div>
  );
};

export default SingleEditorPaneTabContent;
