import { connect } from 'react-redux';

import LauncherTabLinkElement from './LauncherTabLinkElement';
import { measuredTabWidth } from '../../../../../../redux/workbench/modules/notebook.module';
import { updateOverlappingTabs } from '../../../../../../redux/workbench/modules/notebook.overlapping.module';

const mapDispatchToProps = {
  measuredTabWidth,
  updateOverlappingTabs,
};

export default connect(undefined, mapDispatchToProps)(LauncherTabLinkElement);
