import { Handle, Position } from '@xyflow/react';
import React from 'react';

import { getNodeTypeStyle } from './index';
import { Parameter } from '../types';

export function generateHandles(
  parameters: Parameter[],
  handleType: 'target' | 'source'
) {
  const getPositioningPercentage = (index: number): string =>
    `${(100 / (parameters.length + 1)) * (index + 1)}%`;
  return (
    <>
      {parameters.map((parameter, i) => (
        <Handle
          key={parameter.id}
          id={parameter.id}
          type={handleType}
          className={getNodeTypeStyle(parameter.type)}
          style={{ left: getPositioningPercentage(i) }}
          position={handleType === 'target' ? Position.Top : Position.Bottom}
        />
      ))}
    </>
  );
}
